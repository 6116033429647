import ScheduleIcon from "@material-ui/icons/Schedule";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const PresenceResource = (permissions) => ({
  name: "employees/presences",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: ScheduleIcon,
  options: {
    label: "Absensi Online",
  },
});

export default PresenceResource;
