import { useCallback } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  useDataProvider,
  useNotify,
} from "react-admin";

const defaultOptionText = (record) => record?.name;

const ApprovalTypeReferenceInput = ({
  optionText = defaultOptionText,
  onChangeCustom,
  ...props
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const handleOnChange = useCallback(
    (id) => {
      if (id) {
        dataProvider
          .getOne("approval/types", { id })
          .then(({ data }) => {
            onChangeCustom(data);
          })
          .catch((error) => notify(error.message, "error"));
      } else {
        onChangeCustom(null);
      }
    },
    [dataProvider, notify, onChangeCustom]
  );

  return (
    <ReferenceInput
      {...props}
      reference="approval/types"
      onChange={typeof onChangeCustom === "function" ? handleOnChange : null}
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInput>
  );
};

export default ApprovalTypeReferenceInput;
