import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import lodashGet from "lodash/get";
import { Fragment } from "react";
import { useRecordContext } from "react-admin";
import NumberFormat from "react-number-format";
import { CustomDateField, StaticText } from "../../../../components";

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

const DetailView = (props) => {
  const classes = useStyles(props);
  const record = useRecordContext(props);

  return (
    <Fragment>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <StaticText
                title="Tanggal"
                content={
                  record?.date !== null ? (
                    <CustomDateField record={record} source="date" />
                  ) : (
                    "-"
                  )
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <StaticText
                title="Tanggal Mulai"
                content={
                  record?.startDate !== null ? (
                    <CustomDateField record={record} source="startDate" />
                  ) : (
                    "-"
                  )
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <StaticText
                title="Tanggal Selesai"
                content={
                  record?.endDate !== null ? (
                    <CustomDateField record={record} source="endDate" />
                  ) : (
                    "-"
                  )
                }
              />
            </Grid>
          </Grid>

          <div className={classes.spacer} />

          <Grid container spacing={2}>
            <Grid item xs={12} md>
              <StaticText
                title="Kuantitas"
                content={
                  record?.quantity !== null ? (
                    <NumberFormat
                      value={lodashGet(record, "quantity")}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    "-"
                  )
                }
              />
            </Grid>

            <Grid item xs={12} md>
              <StaticText
                title="Total Harga"
                content={
                  record?.amount !== null ? (
                    <NumberFormat
                      value={lodashGet(record, "amount")}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    "-"
                  )
                }
              />
            </Grid>
          </Grid>

          <div className={classes.spacer} />

          <StaticText
            title="Alamat"
            content={lodashGet(record, "address") || "-"}
          />
        </CardContent>
      </Card>

      <div className={classes.spacer} />

      <TableContainer component={Paper}>
        <Table aria-label="product table">
          <TableHead>
            <TableRow>
              <TableCell>Barang</TableCell>
              <TableCell>Keterangan</TableCell>
              <TableCell align="right">Jumlah</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(lodashGet(record, "products") || []).map((product) => (
              <TableRow key={`product-${product.id}`}>
                <TableCell component="th" scope="row">
                  {product.name}
                </TableCell>

                <TableCell component="td" scope="row">
                  {product.description}
                </TableCell>

                <TableCell align="right">
                  {lodashGet(product, "quantity") !== null ? (
                    <NumberFormat
                      value={lodashGet(product, "quantity")}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}

            {(lodashGet(record, "products") || []).length === 0 && (
              <TableRow>
                <TableCell component="th" colSpan={3} align="center">
                  Tidak ada data barang
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default DetailView;
