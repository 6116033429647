import { ACCESS_CODES } from "../../consts";
import CompanyRuleResource from "./company-rules";
import JenisSuratResource from "./jenis-surat";
import PerusahaanResource from "./companies";
import CustomerResource from "./customers";
import BankPaymentResource from "./bank-payments";

const MasterResources = (permissions) => [
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? JenisSuratResource(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? PerusahaanResource(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? CompanyRuleResource(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? CustomerResource(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? BankPaymentResource(permissions)
    : null,
];

export default MasterResources;
