import { CustomList } from "../../../components";
import { Datagrid, TextField, DeleteButton, EditButton } from "react-admin";

const ListView = (props) => (
  <CustomList
    {...props}
    title="Site"
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid>
      <TextField source="name" label="Nama Site" />
      <TextField source="location" label="Lokasi" />

      <EditButton />

      <DeleteButton />
    </Datagrid>
  </CustomList>
);

export default ListView;
