import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const CustomerResource = (permissions) => ({
  name: "master/customers",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Pelanggan",
  },
});

export default CustomerResource;
