import { Box, Grid, Typography } from "@material-ui/core";
import { stringify } from "query-string";
import React from "react";
import { Title, useDataProvider, useNotify, useVersion } from "react-admin";
import NumberFormat from "react-number-format";
import CustomDashboardCard from "../components/CustomDashboardCard";
import CustomDashboardCardButton from "../components/CustomDashboardCardButton";
import WithPermission from "../components/WithPermission";
import { ACCESS_CODES } from "../consts";
import { DateHelper } from "../helpers";
import ActivityReportDailyTable from "./ActivityReportDailyTable";
import ActivityReportList from "./ActivityReportList";
import DashboardSkeleton from "./DashboardSkeleton";

const ViewButton = (props) => (
  <CustomDashboardCardButton {...props}>Lihat</CustomDashboardCardButton>
);

const Dashboard = () => {
  const notify = useNotify();
  const version = useVersion();
  const dataProvider = useDataProvider();
  const [state, setState] = React.useState({
    ready: false,
    employee: {
      total_tetap: 0,
      total_pkwt: 0,
      total_mitra: 0,
      total_berhenti: 0,
    },

    activities: {
      daily: {},
      total_wfh: 0,
      total_penugasan: 0,
      total_wfo: 0,
      total_sick: 0,
      total_leave: 0,
      total_holiday: 0,
      total_all: 0,
      today: [],
    },
  });

  const fetchData = React.useCallback(async () => {
    try {
      const { data: employeesDashboardData } = await dataProvider.getOne(
        "employees/employees",
        {
          id: "dashboard",
        }
      );

      const { data: activitiesDashboardData } = await dataProvider.getOne(
        "employees/activities",
        {
          id: "dashboard",
        }
      );

      setState({
        ready: true,
        employee: employeesDashboardData,

        activities: activitiesDashboardData,
      });
    } catch (e) {
      notify(e.message, "error");
    }
  }, [dataProvider, notify]);

  React.useEffect(() => {
    fetchData();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Title title="TelexindoHRMS" />

      {!state.ready && <DashboardSkeleton />}

      {state.ready && (
        <React.Fragment>
          <Box mb={1}>
            <Typography variant="h6">Jumlah Pegawai</Typography>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={6} lg={3}>
              <CustomDashboardCard
                title="Tetap"
                content={
                  <NumberFormat
                    value={state.employee.total_tetap}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                actions={
                  <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
                    <ViewButton
                      to={{
                        pathname: "/employees/employees",
                        search: stringify({
                          page: 1,
                          perPage: 10,
                          sort: "namakaryawan",
                          order: "ASC",
                          filter: JSON.stringify({
                            filter_status: "TETAP",
                          }),
                        }),
                      }}
                    />
                  </WithPermission>
                }
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <CustomDashboardCard
                title="PKWT"
                content={
                  <NumberFormat
                    value={state.employee.total_pkwt}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                actions={
                  <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
                    <ViewButton
                      to={{
                        pathname: "/employees/employees",
                        search: stringify({
                          page: 1,
                          perPage: 10,
                          sort: "namakaryawan",
                          order: "ASC",
                          filter: JSON.stringify({
                            filter_status: "PKWT",
                          }),
                        }),
                      }}
                    />
                  </WithPermission>
                }
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <CustomDashboardCard
                title="Mitra"
                content={
                  <NumberFormat
                    value={state.employee.total_mitra}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                actions={
                  <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
                    <ViewButton
                      to={{
                        pathname: "/employees/employees",
                        search: stringify({
                          page: 1,
                          perPage: 10,
                          sort: "namakaryawan",
                          order: "ASC",
                          filter: JSON.stringify({
                            filter_status: "MITRA",
                          }),
                        }),
                      }}
                    />
                  </WithPermission>
                }
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <CustomDashboardCard
                title="Berhenti"
                content={
                  <NumberFormat
                    value={state.employee.total_berhenti}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
                actions={
                  <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
                    <ViewButton
                      to={{
                        pathname: "/employees/employees",
                        search: stringify({
                          page: 1,
                          perPage: 10,
                          sort: "namakaryawan",
                          order: "ASC",
                          filter: JSON.stringify({
                            filter_status: "BERHENTI",
                          }),
                        }),
                      }}
                    />
                  </WithPermission>
                }
              />
            </Grid>
          </Grid>

          <Box my={1} />

          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Box mb={1}>
                <Typography variant="h6">
                  Jumlah Activity Report Bulanan
                </Typography>
              </Box>

              <Grid container spacing={1}>
                <Grid item xs={6} lg={4}>
                  <CustomDashboardCard
                    title="WFH"
                    content={
                      <NumberFormat
                        value={state.activities.total_wfh}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    actions={
                      <WithPermission
                        codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}
                      >
                        <ViewButton
                          to={{
                            pathname: "/employees/activities",
                            search: stringify({
                              page: 1,
                              perPage: 10,
                              sort: "created_at",
                              order: "DESC",
                              filter: JSON.stringify({
                                filter_start_date: DateHelper.firstDayOfMonth(),
                                filter_end_date: DateHelper.lastDayOfMonth(),
                                filter_type: "wfh",
                              }),
                            }),
                          }}
                        />
                      </WithPermission>
                    }
                  />
                </Grid>

                <Grid item xs={6} lg={4}>
                  <CustomDashboardCard
                    title="Penugasan Khusus"
                    content={
                      <NumberFormat
                        value={state.activities.total_penugasan}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    actions={
                      <WithPermission
                        codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}
                      >
                        <ViewButton
                          to={{
                            pathname: "/employees/activities",
                            search: stringify({
                              page: 1,
                              perPage: 10,
                              sort: "created_at",
                              order: "DESC",
                              filter: JSON.stringify({
                                filter_start_date: DateHelper.firstDayOfMonth(),
                                filter_end_date: DateHelper.lastDayOfMonth(),
                                filter_is_penugasan_khusus: true,
                              }),
                            }),
                          }}
                        />
                      </WithPermission>
                    }
                  />
                </Grid>

                <Grid item xs={6} lg={4}>
                  <CustomDashboardCard
                    title="WFO"
                    content={
                      <NumberFormat
                        value={state.activities.total_wfo}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    actions={
                      <WithPermission
                        codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}
                      >
                        <ViewButton
                          to={{
                            pathname: "/employees/activities",
                            search: stringify({
                              page: 1,
                              perPage: 10,
                              sort: "created_at",
                              order: "DESC",
                              filter: JSON.stringify({
                                filter_start_date: DateHelper.firstDayOfMonth(),
                                filter_end_date: DateHelper.lastDayOfMonth(),
                                filter_type: "wfo",
                              }),
                            }),
                          }}
                        />
                      </WithPermission>
                    }
                  />
                </Grid>

                <Grid item xs={6} lg={4}>
                  <CustomDashboardCard
                    title="Izin"
                    content={
                      <NumberFormat
                        value={state.activities.total_leave}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    actions={
                      <WithPermission
                        codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}
                      >
                        <ViewButton
                          to={{
                            pathname: "/employees/activities",
                            search: stringify({
                              page: 1,
                              perPage: 10,
                              sort: "created_at",
                              order: "DESC",
                              filter: JSON.stringify({
                                filter_start_date: DateHelper.firstDayOfMonth(),
                                filter_end_date: DateHelper.lastDayOfMonth(),
                                filter_type: "leave",
                              }),
                            }),
                          }}
                        />
                      </WithPermission>
                    }
                  />
                </Grid>

                <Grid item xs={6} lg={4}>
                  <CustomDashboardCard
                    title="Cuti"
                    content={
                      <NumberFormat
                        value={state.activities.total_holiday}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    actions={
                      <WithPermission
                        codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}
                      >
                        <ViewButton
                          to={{
                            pathname: "/employees/activities",
                            search: stringify({
                              page: 1,
                              perPage: 10,
                              sort: "created_at",
                              order: "DESC",
                              filter: JSON.stringify({
                                filter_start_date: DateHelper.firstDayOfMonth(),
                                filter_end_date: DateHelper.lastDayOfMonth(),
                                filter_type: "holiday",
                              }),
                            }),
                          }}
                        />
                      </WithPermission>
                    }
                  />
                </Grid>

                <Grid item xs={6} lg={4}>
                  <CustomDashboardCard
                    title="Sakit"
                    content={
                      <NumberFormat
                        value={state.activities.total_sick}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    actions={
                      <WithPermission
                        codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}
                      >
                        <ViewButton
                          to={{
                            pathname: "/employees/activities",
                            search: stringify({
                              page: 1,
                              perPage: 10,
                              sort: "created_at",
                              order: "DESC",
                              filter: JSON.stringify({
                                filter_start_date: DateHelper.firstDayOfMonth(),
                                filter_end_date: DateHelper.lastDayOfMonth(),
                                filter_type: "sick",
                              }),
                            }),
                          }}
                        />
                      </WithPermission>
                    }
                  />
                </Grid>
              </Grid>

              <Box mt={2} mb={1}>
                <Typography variant="h6">
                  Jumlah Activity Report Harian
                </Typography>
              </Box>

              <ActivityReportDailyTable record={state.activities.daily} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box mb={1}>
                <Typography variant="h6">Activity Report Hari Ini</Typography>
              </Box>

              <ActivityReportList data={state.activities.today} />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
};

export default Dashboard;
