import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  FileField,
  FileInput,
  FormWithRedirect,
  required,
  SaveButton,
  useDataProvider,
  useNotify,
} from "react-admin";
import { NumberHelper } from "../helpers";
import LinearProgressWithLabel from "./LinearProgressWithLabel";

const ImportDialog = ({
  resource,
  open = false,
  onClose,
  title = "Import",
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [progress, setProgress] = React.useState({
    token: null,
    index: 0,
    total: 0,
  });

  const handleOnClose = React.useCallback(() => {
    if (progress.token) return;

    if (onClose) onClose();
  }, [onClose, progress.token]);

  const handleSubmit = (values) => {
    dataProvider
      .importUpload(resource, {
        data: {
          file: values.file.rawFile,
        },
      })
      .then(({ data }) => {
        const { token, total } = data;

        processRow(token, 0, total, 0);
      });
  };

  const processRow = (token, index, total, successCount) => {
    setProgress({
      token,
      total,
      index: index,
    });

    dataProvider
      .importProcess(resource, {
        data: {
          token,
          last: index === total - 1,
        },
        id: index,
      })
      .then(() => {
        successCount++;
      })
      .finally(() => {
        if (index + 1 === total) {
          notify(`${successCount} row(s) imported successfully`, "info");

          setProgress({
            token: null,
            total: 0,
            index: 0,
          });
        } else {
          processRow(token, index + 1, total, successCount);
        }
      });
  };

  return (
    <Dialog fullWidth open={open} onClose={handleOnClose} aria-label={title}>
      <DialogTitle>{title}</DialogTitle>

      {progress.token && (
        <DialogContent>
          <LinearProgressWithLabel
            value={NumberHelper.normalise(
              progress.index + 1,
              0,
              progress.total
            )}
            label={`${progress.index + 1}/${progress.total}`}
          />
        </DialogContent>
      )}

      {!progress.token && (
        <FormWithRedirect
          resource={resource}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <FileInput
                  source="file"
                  label="Import file template"
                  accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  validate={[required()]}
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>

              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleOnClose} />

                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  label="Import"
                  saving={saving}
                />
              </DialogActions>
            </>
          )}
        />
      )}
    </Dialog>
  );
};

ImportDialog.propTypes = {
  resource: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default ImportDialog;
