import {
  Chip,
  CircularProgress,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { useGetList } from "react-admin";
import { ACCESS_CODES } from "../../../consts";
import EditView from "./EditView";
import ListView from "./ListView";

export const STATUS_ARRAY = [
  { id: "open", name: "Request" },
  { id: "process", name: "Proses" },
  { id: "close", name: "Selesai" },
  { id: "cancel", name: "Batal" },
];

export const WORK_LOCATION_ARRAY = [
  { id: "ho", name: "Kantor Pusat" },
  { id: "client", name: "Mitra Kerja" },
];

export const REMUNERATION_TYPE_ARRAY = [
  { id: "standard", name: "Standar UMP" },
  { id: "custom", name: "Lainnya" },
];

const Label = ({ resource }) => {
  const { total, loading, error } = useGetList(
    resource.name,
    { page: 1, perPage: -1 },
    { field: "id", order: "DESC" },
    { filter_status: "open" }
  );

  return (
    <>
      <ListItemText primary={resource.options.label} />

      {!error && (loading || total > 0) && (
        <ListItemIcon>
          {loading && <CircularProgress size={24} />}
          {!loading && <Chip label={total} color="primary" size="small" />}
        </ListItemIcon>
      )}
    </>
  );
};

const RequestResource = (permissions) => ({
  name: "employees/requests",
  list: ListView,
  edit: [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? EditView
    : null,
  icon: GroupAddIcon,
  options: {
    label: "Kandidat",
    labelFn: (resource) => <Label resource={resource} />,
  },
});

export default RequestResource;
