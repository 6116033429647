import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

const ReceiverLocationTable = ({ record = {} }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Work Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(record.receiver_locations_array || []).map((line) => (
            <TableRow key={line.id}>
              <TableCell
                component="td"
                scope="row"
                style={{ whiteSpace: "pre-line" }}
              >
                {line.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiverLocationTable;
