import React from "react";
import { Button, useMutation } from "react-admin";

const ProcessButton = ({ record }) => {
  const [execute, { loading }] = useMutation({
    type: "update",
    resource: "support/supports",
    payload: { id: record.id, data: { status: "doing" } },
  });

  if (record?.status !== "open") return null;

  return (
    <Button
      label="Proses"
      variant="outlined"
      onClick={execute}
      disabled={loading}
    />
  );
};

export default ProcessButton;
