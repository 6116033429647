import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import * as React from "react";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";
import {
  ConditionalField,
  CustomDateField,
  CustomFilter,
  CustomList,
  CustomPrintButton,
} from "../../../../components";
import { LEAVE_STATUS_ARRAY } from "../../../../consts";
import EmployeeReferenceField from "../../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../../employees/EmployeeReferenceInput";

const renderLeaveDate = (record = {}) => {
  const startDate = moment(record.start_date);
  const endDate = moment(record.end_date);

  if (startDate.isSame(endDate)) {
    return `${startDate.format("DD/MM/YYYY")} (${record.amount} hari)`;
  }

  return `${startDate.format("DD/MM/YYYY")} - ${endDate.format(
    "DD/MM/YYYY"
  )} (${record.amount} hari)`;
};

const FilterView = (props) => (
  <CustomFilter {...props}>
    <EmployeeReferenceInput source="filter_employee" alwaysOn />
  </CustomFilter>
);

const DocumentDownloadButton = (props) => {
  const record = useRecordContext(props);

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<GetAppIcon />}
      component="a"
      href={record?.document?.url}
      title={record?.document?.name}
      target="_blank"
      fullWidth
    >
      DOCUMENT
    </Button>
  );
};

const ListView = (props) => (
  <CustomList
    {...props}
    title="Riwayat Cuti"
    filters={<FilterView />}
    sort={{ field: "created_time", order: "DESC" }}
  >
    <Datagrid>
      <TextField source="order" label="Nomor Urut" />

      <EmployeeReferenceField source="employee_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="employee_id"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <FunctionField
        render={renderLeaveDate}
        label="Tanggal Cuti"
        sortBy="start_date"
      />

      <TextField source="description" label="Keterangan" />

      <CustomDateField
        source="created_time"
        sourceFormat="YYYY-MM-DD HH:mm:ss"
        label="Tanggal Permintaan"
      />

      <SelectField
        source="status"
        label="Status"
        choices={LEAVE_STATUS_ARRAY}
      />

      <ConditionalField when={(record) => record?.document?.url}>
        <DocumentDownloadButton />
      </ConditionalField>

      <CustomPrintButton />
      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
