import { blue } from "@material-ui/core/colors";
import moment from "moment";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://api.hrms.telexindo.local/admin"
    : `https://api.hrms.telexindo.com/admin`;

export const ACCESS_CODES = {
  ADMIN: "0",
  PMO_BRO: "1",
  GA: "3",
  HR: "4",
  FINANCE: "5",
};

export const ACCESS_CODE_ARRAY = [
  { id: ACCESS_CODES.ADMIN, name: "Administrator" },
  { id: ACCESS_CODES.PMO_BRO, name: "PMO/BRO" },
  { id: ACCESS_CODES.GA, name: "General Affair" },
  { id: ACCESS_CODES.HR, name: "Human Resource" },
  { id: ACCESS_CODES.FINANCE, name: "Finance" },
];

export const TIMEZONE_ARRAY = [
  { id: "Asia/Jakarta", name: "Asia/Jakarta (WIB)" },
  { id: "Asia/Makassar", name: "Asia/Makassar (WITA)" },
  { id: "Asia/Jayapura", name: "Asia/Jayapura (WIT)" },
];

export const GENDER_ARRAY = [
  { id: "L", name: "LAKI-LAKI" },
  { id: "P", name: "PEREMPUAN" },
];

export const MARITAL_ARRAY = [
  { id: "TK", name: "TK" },
  { id: "TK1", name: "TK 1" },
  { id: "TK2", name: "TK 2" },
  { id: "TK3", name: "TK 3" },
  { id: "K", name: "K" },
  { id: "K1", name: "K 1" },
  { id: "K2", name: "K 2" },
  { id: "K3", name: "K 3" },
];

export const CONTRACT_ARRAY = [
  { id: "telexindo", name: "TELEXINDO" },
  { id: "aruwa", name: "ARUWA" },
];

export const ACTIVITY_TYPE_ARRAY = [
  { id: "wfh", name: "WFH" },
  { id: "wfo", name: "WFO" },
  { id: "holiday", name: "Cuti" },
  { id: "sick", name: "Sakit" },
  { id: "leave", name: "Izin" },
];

export const ACTIVITY_DAY_ARRAY = [
  { id: "mon", name: "Senin" },
  { id: "tue", name: "Selasa" },
  { id: "wed", name: "Rabu" },
  { id: "thu", name: "Kamis" },
  { id: "fri", name: "Jumat" },
  { id: "sat", name: "Sabtu" },
  { id: "sun", name: "Minggu" },
];

export const DOCUMENT_STATUS_ARRAY = [
  { id: "DAFTAR", name: "DAFTAR" },
  { id: "TIDAK AKTIF", name: "TIDAK AKTIF" },
  { id: "PENGGABUNGAN SALDO", name: "PENGGABUNGAN SALDO" },
  { id: "GAGAL DAFTAR", name: "GAGAL DAFTAR" },
  { id: "PINDAH FASKES", name: "PINDAH FASKES" },
  { id: "KARTU HILANG", name: "KARTU HILANG" },
  { id: "TIDAK TERDAFTAR", name: "TIDAK TERDAFTAR" },
  { id: "BELUM DAFTAR", name: "BELUM DAFTAR" },
];

export const DOCUMENT_RECEIVER_ARRAY = [
  { id: "user", name: "Karyawan" },
  { id: "hrd", name: "HRD" },
];

export const LEAVE_TYPE_ARRAY = [
  { id: 1, name: "Hamil" },
  { id: 2, name: "Nikah" },
  { id: 3, name: "Tahunan" },
  { id: 0, name: "Lainnya" },
];

export const LEAVE_DURATION_ARRAY = [
  { id: 1, name: "Half Day" },
  { id: 2, name: "Full Day" },
];

export const LEAVE_STATUS_ARRAY = [
  { id: "draft", name: "Menunggu Persetujuan" },
  { id: "approved", name: "Disetujui" },
  { id: "rejected", name: "Ditolak" },
];

export const SUPPORT_STATUS_ARRAY = [
  { id: "open", name: "Open" },
  { id: "doing", name: "Proses" },
  { id: "closed", name: "Selesai" },
];

export const NOTIFICATION_SENDER_TYPE_ARRAY = [
  { id: "user", name: "Selected employee" },
  { id: "system", name: "System" },
];

export const NOTIFICATION_RECEIVER_TYPE_ARRAY = [
  { id: "all", name: "All employees" },
  { id: "location", name: "Based on selected employee work location" },
  { id: "user", name: "Based on selected employee" },
];

export const NOTIFICATION_LINK_TYPE = [
  { id: "none", name: "None" },
  { id: "navigation", name: "Navigation" },
  { id: "external", name: "External" },
];

export const NOTIFICATION_STATUS_ARRAY = [
  { id: "draft", name: "Draft" },
  { id: "public", name: "Public" },
];

export const NOTIFICATION_VISIBLE_TYPE_ARRAY = [
  { id: "immediate", name: "Immediately" },
  { id: "after", name: "After" },
];

export const PLATFORM_ARRAY = [
  { id: "legacy", name: "Legacy" },
  { id: "web", name: "Web" },
  { id: "mobile", name: "Mobile" },
];

export const EVENT_VISIBILITY_ARRAY = [
  { id: "all", name: "Semua Karyawan" },
  { id: "in_list", name: "Karyawan Tertentu" },
  { id: "in_list_location", name: "Lokasi Kerja Tertentu" },
];

export const EVENT_STATUS_ARRAY = [
  { id: "draft", name: "Draft" },
  { id: "published", name: "Published" },
  { id: "closed", name: "Done" },
];

export const OVERTIME_STATUS_ARRAY = [
  { id: "draft", name: "Menunggu Persetujuan" },
  { id: "approved", name: "Disetujui" },
  { id: "rejected", name: "Ditolak" },
];

export const CONTRACT_REQUEST_STATUS_ARRAY = [
  { id: "request", name: "Permintaan" },
  { id: "approval1", name: "Persetujuan Pihak Pertama" },
  { id: "approval2", name: "Persetujuan Pihak Kedua" },
  { id: "done", name: "Selesai" },
];

export const MONTH_ARRAY = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

export const YEAR_ARRAY = (() => {
  const years = [];
  const dateStart = moment("1970");
  const dateEnd = moment().add(5, "y");

  while (dateEnd.diff(dateStart, "years") >= 0) {
    years.push({
      id: dateStart.format("YYYY"),
      name: dateStart.format("YYYY"),
    });

    dateStart.add(1, "year");
  }

  return years.reverse();
})();

function getWeekNumber(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return [d.getFullYear(), weekNo];
}

export const createWeekChoices = (year) => {
  const d = new Date(year, 11, 31);
  const week = getWeekNumber(d)[1];

  const count = week === 1 ? 52 : week;

  return Array.from({ length: count }, (_, i) => i + 1).map((i) => ({
    id: i,
    name: `#${i}`,
  }));
};

export const MAIN_THEME = {
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    RaSidebar: {
      root: {
        maxHeight: "calc(100vh - 56px)",
        overflowY: "auto",
      },
    },
    RaMenuItemLink: {
      root: {
        borderLeft: "3px solid #fff",
      },
      active: {
        borderLeft: "3px solid #4f3cc9",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: 16,
        },
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: "#4f3cc9",
        boxShadow: "none",
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#808080",
        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsContainer: {
        zIndex: 1400,
      },
    },
  },
};
