import ListView from "./ListView";

const StatusReportResource = (permissions) => ({
  name: "reports/employee/status",
  list: ListView,
  options: {
    label: "Rekap Status Karyawan",
  },
});

export default StatusReportResource;
