import { ACCESS_CODES } from "../../consts";
import SuratResource from "./surat";

const PenomoranResources = (permissions) => [
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? SuratResource(permissions)
    : null,
];

export default PenomoranResources;
