import React from "react";
import { CustomCreate } from "../../../components";
import FormView from "./FormView";

const CreateView = (props) => {
  return (
    <CustomCreate {...props} title="Buat Template Kontrak">
      <FormView />
    </CustomCreate>
  );
};

export default CreateView;
