import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import {
  FileField,
  FileInput,
  FormDataConsumer,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useForm } from "react-final-form";
import { ConditionalFormDataConsumer } from "../../../components";
import { OVERTIME_STATUS_ARRAY } from "../../../consts";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const VALIDATION = {
  code: [required()],
  emp_id: [required()],
  started_at: [required()],
  ended_at: [required()],
  duration_in_hour: [required(), number(), minValue(0)],
  description: [required()],
  status: [required()],
  approved_leader_id: [required()],
  approved_leader_at: [required()],
};

const DurationForm = ({ formData, ...rest }) => {
  const form = useForm();

  const calculateDuration = () => {
    const start = form.getFieldState("started_at").value;
    const end = form.getFieldState("ended_at").value;

    if (start && end) {
      const startTime = moment(start, "YYYY-MM-DD HH:mm:ss", true);
      const endTime = moment(end, "YYYY-MM-DD HH:mm:ss", true);

      if (startTime.isValid() && endTime.isValid()) {
        const result = endTime.diff(startTime, "hours");

        form.change("duration_in_hour", result);
      }
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <TextInput
            source="started_at"
            label="Mulai"
            validate={VALIDATION.started_at}
            fullWidth
            helperText="YYYY-MM-DD HH:mm:ss"
            onChange={calculateDuration}
            {...rest}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <TextInput
            source="ended_at"
            label="Selesai"
            validate={VALIDATION.ended_at}
            fullWidth
            helperText="YYYY-MM-DD HH:mm:ss"
            onChange={calculateDuration}
            {...rest}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <NumberInput
            source="duration_in_hour"
            label="Durasi"
            validate={VALIDATION.duration_in_hour}
            fullWidth
            initialValue={0.0}
            {...rest}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextInput
              source="code"
              label="No. Urut"
              validate={VALIDATION.code}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={9}>
            <EmployeeReferenceInput
              source="emp_id"
              validate={VALIDATION.emp_id}
              fullWidth
            />
          </Grid>
        </Grid>

        <FormDataConsumer>
          {(FormDataProps) => <DurationForm {...FormDataProps} />}
        </FormDataConsumer>

        <TextInput
          source="description"
          label="Keterangan"
          validate={VALIDATION.description}
          fullWidth
        />

        <FileInput
          source="documentFile"
          label="Dokumen"
          accept=".pdf,.doc,.docx,.xls,.xlsx"
        >
          <FileField source="src" title="title" />
        </FileInput>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <SelectInput
              source="status"
              label="Status"
              choices={OVERTIME_STATUS_ARRAY}
              validate={VALIDATION.status}
              fullWidth
            />
          </Grid>

          <ConditionalFormDataConsumer
            when={(formData) => formData?.status === "approved"}
          >
            {(FormDataProps) => (
              <React.Fragment>
                <Grid item xs={12} lg={6}>
                  <EmployeeReferenceInput
                    source="approved_leader_by"
                    label="Approval Leader"
                    validate={VALIDATION.approved_leader_by}
                    fullWidth
                    allowEmpty
                    {...FormDataProps}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <TextInput
                    source="approved_leader_at"
                    label="Waktu Approval Leader"
                    validate={VALIDATION.approved_leader_at}
                    helperText="YYYY-MM-DD HH:mm:ss"
                    {...FormDataProps}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )}
          </ConditionalFormDataConsumer>
        </Grid>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
