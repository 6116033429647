import moment from "moment";
import * as React from "react";
import {
  BooleanField,
  Datagrid,
  DateInput,
  DeleteButton,
  EditButton,
  SelectField,
  SelectInput,
} from "react-admin";
import {
  CustomDateField,
  CustomFilter,
  CustomList,
  QuickFilter,
} from "../../../components";
import { ACTIVITY_DAY_ARRAY, ACTIVITY_TYPE_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";
import LinesTable from "./LinesTable";

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <DateInput source="filter_start_date" label="Tanggal Awal" alwaysOn />

    <DateInput source="filter_end_date" label="Tanggal Akhir" alwaysOn />

    <EmployeeReferenceInput
      source="filter_employee"
      label="Karyawan"
      alwaysOn
    />

    <SelectInput
      source="filter_type"
      label="Kondisi"
      choices={ACTIVITY_TYPE_ARRAY}
      alwaysOn
    />

    <QuickFilter
      source="filter_is_penugasan_khusus"
      label="Penugasan Khusus"
      defaultValue={true}
    />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Activity Report"
    filters={<FilterView />}
    sort={{ field: "created_at", order: "DESC" }}
    filterDefaultValues={{
      filter_start_date: moment().startOf("month").format("YYYY-MM-DD"),
      filter_end_date: moment().endOf("month").format("YYYY-MM-DD"),
    }}
    useExport
  >
    <Datagrid expand={<LinesTable />}>
      <EmployeeReferenceField source="emp_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="emp_id"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <SelectField source="day" label="Hari" choices={ACTIVITY_DAY_ARRAY} />

      <SelectField
        source="type"
        label="Kondisi"
        choices={ACTIVITY_TYPE_ARRAY}
      />

      <BooleanField source="flag_penugasan_khusus" label="WFH+" />

      <CustomDateField
        source="created_at"
        sourceFormat="YYYY-MM-DD HH:mm:ss"
        destFormat="DD/MM/YYYY HH:mm:ss"
        label="Waktu"
      />

      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
