import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(0.5),
    whiteSpace: "pre-line",
  },
}));

export const StaticText = ({
  title,
  titleProps = {},
  content,
  contentProps = {},
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography
        variant="caption"
        display="block"
        color="textSecondary"
        {...titleProps}
      >
        {title}
      </Typography>

      <Typography
        className={classes.content}
        variant="body2"
        display="block"
        {...contentProps}
      >
        {content}
      </Typography>
    </Box>
  );
};
