import { Grid } from "@material-ui/core";
import { FormDataConsumer, number, required, useRedirect } from "ra-core";
import {
  ArrayInput,
  DateInput,
  FileField,
  FileInput,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { useForm } from "react-final-form";
import { ConditionalFormDataConsumer } from "../../../components";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";

const VALIDATION = {
  categoryId: [required()],
  typeId: [required()],
  templateId: [required()],
  code: [required()],
  startDate: [required()],
  endDate: [required()],
  position: [required()],
  companyName: [required()],
  companyAddress: [required()],
  pmoId: [required()],
  companyRuleId: [],
  approverId: [required()],
  approverName: [required()],
  approverPosition: [required()],
  empId: [required()],
  empName: [required()],
  empBirthPlace: [required()],
  empBirthDate: [required()],
  empAddress: [required()],
  empPhone: [required()],
  empCode: [required()],
  empKtpCode: [required()],
  fileId: [],
  components: {
    name: [required()],
    amount: [required(), number()],
  },
};

const initialValues = {
  status: "request",
};

const TabPanelInformasiKontrak = () => (
  <React.Fragment>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <ReferenceInput
          source="categoryId"
          reference="contract/categories"
          label="Jenis Kontrak"
          validate={VALIDATION.categoryId}
          fullWidth
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12} md={4}>
        <ReferenceInput
          source="typeId"
          reference="contract/types"
          label="Status Kontrak"
          validate={VALIDATION.typeId}
          fullWidth
        >
          <SelectInput
            optionText={(record) => `${record?.code} - ${record?.name}`}
          />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12} md={4}>
        <ReferenceInput
          source="templateId"
          reference="contract/templates"
          label="Template Kontrak"
          validate={VALIDATION.templateId}
          fullWidth
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput
          source="code"
          label="Nomor Kontrak"
          validate={VALIDATION.code}
          fullWidth
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <DateInput
          source="startDate"
          label="Awal Kontrak"
          validate={VALIDATION.startDate}
          fullWidth
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <DateInput
          source="endDate"
          label="Akhir Kontrak"
          validate={VALIDATION.endDate}
          fullWidth
        />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput
          source="position"
          label="Posisi Pekerjaan"
          validate={VALIDATION.position}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextInput
          source="companyName"
          label="Lokasi Kerja"
          validate={VALIDATION.companyName}
          fullWidth
        />
      </Grid>
    </Grid>

    <TextInput
      source="companyAddress"
      label="Alamat Lokasi Kerja"
      validate={VALIDATION.companyAddress}
      fullWidth
      multiline
    />

    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <EmployeeReferenceInput
          source="pmoId"
          label="Petugas PMO"
          validate={VALIDATION.pmoId}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <ReferenceInput
          source="companyRuleId"
          reference="master/company-rules"
          label="Peraturan Perusahaan"
          validate={VALIDATION.companyRuleId}
          sort={{ field: "name", order: "ASC" }}
          fullWidth
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Grid>
    </Grid>

    <ConditionalFormDataConsumer when={(formData) => Boolean(formData?.id)}>
      {() => (
        <FileInput source="overrideFile" label="PDF Kontrak" accept=".pdf">
          <FileField source="src" title="title" />
        </FileInput>
      )}
    </ConditionalFormDataConsumer>
  </React.Fragment>
);

const TabPanelPihakPertama = () => {
  const form = useForm();
  const handleOnChange = React.useCallback(
    (data) => {
      form.change("approverName", data?.namakaryawan);
      form.change("approverPosition", data?.jabatan);
    },
    [form]
  );

  return (
    <React.Fragment>
      <EmployeeReferenceInput
        source="approverId"
        label="Karyawan"
        validate={VALIDATION.approverId}
        onChangeCustom={handleOnChange}
        fullWidth
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextInput
            source="approverName"
            label="Nama Karyawan"
            validate={VALIDATION.approverName}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextInput
            source="approverPosition"
            label="Jabatan Karyawan"
            validate={VALIDATION.approverPosition}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const TabPanelPihakKedua = () => {
  const form = useForm();
  const handleOnChange = React.useCallback(
    (data) => {
      form.change("empCode", data?.nomorkaryawan);
      form.change("empName", data?.namakaryawan);
      form.change("empBirthPlace", data?.tempatlahir);
      form.change("empBirthDate", data?.tgllahir);
      form.change("empAddress", data?.alamatktp);
      form.change("empPhone", data?.telpkaryawan);
      form.change("empKtpCode", data?.nomorktp);
    },
    [form]
  );

  return (
    <React.Fragment>
      <EmployeeReferenceInput
        source="empId"
        label="Karyawan"
        validate={VALIDATION.empId}
        onChangeCustom={handleOnChange}
        fullWidth
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput
            source="empCode"
            label="NIK"
            validate={VALIDATION.empCode}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextInput
            source="empName"
            label="Nama Karyawan"
            validate={VALIDATION.empName}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <DateInput
            source="empBirthDate"
            label="Tanggal Lahir Karyawan"
            validate={VALIDATION.empBirthDate}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <TextInput
            source="empBirthPlace"
            label="Tempat Lahir Karyawan"
            validate={VALIDATION.empBirthPlace}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextInput
            source="empPhone"
            label="Nomor Telpon Karyawan"
            validate={VALIDATION.empPhone}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextInput
            source="empKtpCode"
            label="Nomor KTP Karyawan"
            validate={VALIDATION.empKtpCode}
            fullWidth
          />
        </Grid>
      </Grid>

      <TextInput
        source="empAddress"
        label="Alamat Karyawan"
        validate={VALIDATION.empAddress}
        fullWidth
        multiline
      />
    </React.Fragment>
  );
};

const TabPanelKompensasi = () => {
  return (
    <React.Fragment>
      <ArrayInput source="components_array" label="Komponen">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource }) => (
              <React.Fragment>
                <TextInput
                  source={getSource("name")}
                  label="Komponen"
                  validate={VALIDATION.components.name}
                  multiline
                  fullWidth
                />

                <NumberInput
                  source={getSource("amount")}
                  label="Jumlah"
                  validate={VALIDATION.components.amount}
                />
              </React.Fragment>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </React.Fragment>
  );
};

const FormView = (props) => {
  const redirect = useRedirect();

  React.useEffect(() => {
    if ((props?.record?.status || "request") === "done") {
      redirect("show", "/contract/requests", props?.record?.id);
    }
  }, [props.record, redirect]);

  return (
    <TabbedForm {...props} initialValues={initialValues}>
      <FormTab label="Informasi Kontrak">
        <TabPanelInformasiKontrak />
      </FormTab>

      <FormTab label="Pihak Pertama">
        <TabPanelPihakPertama />
      </FormTab>

      <FormTab label="Pihak Kedua">
        <TabPanelPihakKedua />
      </FormTab>

      <FormTab label="Kompensasi">
        <TabPanelKompensasi></TabPanelKompensasi>
      </FormTab>
    </TabbedForm>
  );
};

export default FormView;
