import React from "react";
import {
  Datagrid,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
} from "react-admin";
import { CustomDateField, CustomFilter, CustomList } from "../../../components";
import { SUPPORT_STATUS_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../../employees/employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";
import CloseButton from "./CloseButton";
import ExpandView from "./ExpandView";
import ProcessButton from "./ProcessButton";

const FilterView = (props) => {
  return (
    <CustomFilter {...props} query={false}>
      <EmployeeReferenceInput source="filter_employee" alwaysOn />

      <ReferenceInput
        source="filter_category"
        label="Kategori"
        reference="support/categories"
        alwaysOn
      >
        <SelectInput />
      </ReferenceInput>

      <SelectInput
        source="filter_status"
        label="Status"
        choices={SUPPORT_STATUS_ARRAY}
        alwaysOn
      />
    </CustomFilter>
  );
};

const ListView = (props) => {
  return (
    <CustomList
      {...props}
      title="Support"
      filters={<FilterView />}
      sort={{ field: "created_time", order: "DESC" }}
      filterDefaultValues={{
        filter_status: "open",
      }}
    >
      <Datagrid expand={<ExpandView />}>
        <EmployeeReferenceField source="created_by" avatar />

        <ReferenceField
          source="category_id"
          reference="support/categories"
          label="Kategori"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <CustomDateField
          source="created_time"
          sourceFormat="YYYY-MM-DD HH:mm:ss"
          destFormat="DD/MM/YYYY HH:mm:ss"
          label="Waktu"
        />

        <SelectField
          source="status"
          choices={SUPPORT_STATUS_ARRAY}
          label="Status"
        />

        <ProcessButton />
        <CloseButton />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
