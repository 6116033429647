import { Button, Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useMutation, useNotify, useRecordContext } from "react-admin";

const NotificationButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const record = useRecordContext(props);
  const notify = useNotify();
  const [mutate, { loading }] = useMutation();

  const send = (action) =>
    mutate(
      {
        type: "update",
        resource: "contract/requests",
        payload: {
          id: record?.id,
          data: { mode: action, _ref: "notify" },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify("Notifikasi telah dikirimkan", "info");
        },
        onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
      }
    );

  const handleOnOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnItemClick = (event, action) => {
    send(action);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        variant="text"
        onClick={handleOnOpen}
        title="Create Other"
        aria-controls="notifications-menu"
        aria-haspopup="true"
        size="small"
        color="primary"
        startIcon={<NotificationsIcon />}
        disabled={loading}
      >
        NOTIFIKASI
      </Button>

      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
      >
        <MenuItem onClick={(event) => handleOnItemClick(event, "pihak1")}>
          <span>Pihak Pertama</span>
        </MenuItem>

        <MenuItem onClick={(event) => handleOnItemClick(event, "pihak2")}>
          <span>Pihak Kedua</span>
        </MenuItem>

        <MenuItem onClick={(event) => handleOnItemClick(event, "all")}>
          <span>Semua</span>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default NotificationButton;
