import { stringify } from "query-string";
import { fetchUtils } from "ra-core";
import { serialize } from "object-to-formdata";

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => POST http://my.api.url/posts/123
 * updateMany       => POST http://my.api.url/posts/123, POST http://my.api.url/posts/456, POST http://my.api.url/posts/789
 * delete           => POST http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const DataProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query, {
      arrayFormat: "comma",
    })}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
        );
      }
      return {
        data: json,
        total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
      };
    });
  },
  export: (resource, params) => {
    const query = {
      ...fetchUtils.flattenObject(params.filter),
    };
    const url = `${apiUrl}/${resource}/export?${stringify(query, {
      arrayFormat: "comma",
    })}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
      };
    });
  },
  getOne: (resource, params) => {
    const { id, ...restParams } = params;
    const query = fetchUtils.flattenObject(restParams);

    return httpClient(
      `${apiUrl}/${resource}/${id}?${stringify(query, {
        arrayFormat: "comma",
      })}`
    ).then(({ json }) => ({
      data: json,
    }));
  },
  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query, {
      arrayFormat: "comma",
    })}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query, {
      arrayFormat: "comma",
    })}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
        );
      }
      return {
        data: json,
        total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
      };
    });
  },
  update: (resource, params) => {
    var formData = serialize(params.data, {
      indices: true,
      booleansAsIntegers: true,
    });

    return httpClient(`${apiUrl}/${resource}/update/${params.id}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  updateMany: (resource, params) => {
    var formData = serialize(params.data, {
      indices: true,
      booleansAsIntegers: true,
    });

    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/update/${id}`, {
          method: "POST",
          body: formData,
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },
  create: (resource, params) => {
    var formData = serialize(params.data, {
      indices: true,
      booleansAsIntegers: true,
    });

    return httpClient(`${apiUrl}/${resource}/create`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
  importUpload: (resource, params) => {
    var formData = serialize(params.data, {
      indices: true,
      booleansAsIntegers: true,
    });

    return httpClient(`${apiUrl}/${resource}/import`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({
      data: json,
    }));
  },
  importProcess: (resource, params) => {
    var formData = serialize(params.data, {
      indices: true,
      booleansAsIntegers: true,
    });

    return httpClient(`${apiUrl}/${resource}/import/${params.id}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({
      data: json,
    }));
  },
  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/delete/${params.id}`, {
      method: "POST",
    }).then(({ json }) => ({ data: json }));
  },
  deleteMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/delete/${id}`, {
          method: "POST",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },
});

export default DataProvider;
