import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const BalanceResource = (permissions) => ({
  name: "employees/leaves/balances",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: InsertInvitationIcon,
  options: {
    label: "Saldo Cuti",
  },
});

export default BalanceResource;
