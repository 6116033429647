import PeopleIcon from "@material-ui/icons/People";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const UserResource = (permissions) => ({
  name: "configurations/users",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: PeopleIcon,
  options: {
    label: "Users",
  },
});

export default UserResource;
