import {
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { SimpleShowLayout } from "react-admin";
import { DateHelper } from "../../../helpers";
import EmployeeReferenceField from "../../employees/employees/EmployeeReferenceField";

const ReadHistoryDialog = ({ open, onClose, record }) => {
  if (!record?.id) {
    return <div></div>;
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      aria-label={`Read History #${record.id}`}
    >
      <DialogTitle>{`Read History #${record.id}`}</DialogTitle>

      <SimpleShowLayout
        record={record}
        basePath="/notification/histories"
        resource="notification/histories"
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(record.read_histories_array || []).map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="td" scope="row">
                    <EmployeeReferenceField
                      basePath="/employees/employees"
                      record={row}
                      source="created_by"
                      link={false}
                      avatar
                    />
                  </TableCell>

                  <TableCell component="td" scope="row">
                    {DateHelper.format(
                      row.created_at,
                      "YYYY-MM-DD HH:mm:ss",
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleShowLayout>
    </Dialog>
  );
};

ReadHistoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default ReadHistoryDialog;
