import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { FunctionField } from "react-admin";

const DetailEmployeeTable = ({ record = {} }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Karyawan</TableCell>
            <TableCell>Unit Kerja</TableCell>
            <TableCell>Lokasi Kerja</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(record.detail_employees_array || []).map((line) => (
            <TableRow key={line.id}>
              <TableCell component="td" scope="row">
                <FunctionField
                  record={line}
                  render={(record) =>
                    record?.employee?.nomorkaryawan &&
                    record?.employee?.namakaryawan
                      ? `${record?.employee?.nomorkaryawan} - ${record?.employee?.namakaryawan}`
                      : null
                  }
                />
              </TableCell>

              <TableCell component="td" scope="row">
                <FunctionField
                  record={line}
                  render={(record) => record?.employee?.unitkerja}
                />
              </TableCell>

              <TableCell component="td" scope="row">
                <FunctionField
                  record={line}
                  render={(record) => record?.employee?.lokasikerja}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailEmployeeTable;
