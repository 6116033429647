import { Grid } from "@material-ui/core";
import { stringify } from "query-string";
import { useGetOne } from "ra-core";
import React from "react";
import NumberFormat from "react-number-format";
import {
  CustomDashboardCard,
  CustomDashboardCardButton,
} from "../../../components";

const DashboardView = () => {
  const { data } = useGetOne("contract/requests", "dashboard");

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={3}>
        <CustomDashboardCard
          title="Permintaan"
          content={
            <NumberFormat
              value={data?.total_request || 0}
              displayType="text"
              thousandSeparator={true}
            />
          }
          actions={
            <CustomDashboardCardButton
              to={{
                pathname: "/contract/requests",
                search: stringify({
                  page: 1,
                  perPage: 10,
                  sort: "createdAt",
                  order: "DESC",
                  filter: JSON.stringify({
                    filter_status: "request",
                  }),
                }),
              }}
            >
              Lihat
            </CustomDashboardCardButton>
          }
        />
      </Grid>

      <Grid item xs={6} lg={3}>
        <CustomDashboardCard
          title="Persetujuan Pihak Pertama"
          content={
            <NumberFormat
              value={data?.total_approval1 || 0}
              displayType="text"
              thousandSeparator={true}
            />
          }
          actions={
            <CustomDashboardCardButton
              to={{
                pathname: "/contract/requests",
                search: stringify({
                  page: 1,
                  perPage: 10,
                  sort: "createdAt",
                  order: "DESC",
                  filter: JSON.stringify({
                    filter_status: "approval1",
                  }),
                }),
              }}
            >
              Lihat
            </CustomDashboardCardButton>
          }
        />
      </Grid>

      <Grid item xs={6} lg={3}>
        <CustomDashboardCard
          title="Persetujuan Pihak Kedua"
          content={
            <NumberFormat
              value={data?.total_approval2 || 0}
              displayType="text"
              thousandSeparator={true}
            />
          }
          actions={
            <CustomDashboardCardButton
              to={{
                pathname: "/contract/requests",
                search: stringify({
                  page: 1,
                  perPage: 10,
                  sort: "createdAt",
                  order: "DESC",
                  filter: JSON.stringify({
                    filter_status: "approval2",
                  }),
                }),
              }}
            >
              Lihat
            </CustomDashboardCardButton>
          }
        />
      </Grid>

      <Grid item xs={6} lg={3}>
        <CustomDashboardCard
          title="Selesai"
          content={
            <NumberFormat
              value={data?.total_done || 0}
              displayType="text"
              thousandSeparator={true}
            />
          }
          actions={
            <CustomDashboardCardButton
              to={{
                pathname: "/contract/requests",
                search: stringify({
                  page: 1,
                  perPage: 10,
                  sort: "createdAt",
                  order: "DESC",
                  filter: JSON.stringify({
                    filter_status: "done",
                  }),
                }),
              }}
            >
              Lihat
            </CustomDashboardCardButton>
          }
        />
      </Grid>
    </Grid>
  );
};

export default DashboardView;
