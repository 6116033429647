import ListView from "./ListView";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

const SupportResource = (permissions) => ({
  name: "support/supports",
  list: ListView,
  icon: ContactSupportIcon,
  options: {
    label: "Supports",
  },
});

export default SupportResource;
