import moment from "moment";
import * as React from "react";
import { AutocompleteInput, Datagrid, DateInput, DeleteButton, EditButton, ReferenceInput } from "react-admin";
import { CustomDateField, CustomFilter, CustomList } from "../../../components";
import { ACCESS_CODES } from "../../../consts";
import EmployeeReferenceField from "../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";
import ShowView from "./ShowView";

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <DateInput source="filter_start_date" label="Tanggal Awal" alwaysOn />

    <DateInput source="filter_end_date" label="Tanggal Akhir" alwaysOn />

    <ReferenceInput
      source="filter_location"
      label="Lokasi kerja"
      reference="employees/locations"
      filter={{
        ...(ACCESS_CODES.PMO_BRO === props.permissions
          ? { is_partnership_only: 1 }
          : {}),
      }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <EmployeeReferenceInput source="filter_employee" alwaysOn />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Absensi Online"
    filters={<FilterView />}
    sort={{ field: "datetime_in", order: "DESC" }}
    filterDefaultValues={{
      filter_start_date: moment().startOf("month").format("YYYY-MM-DD"),
      filter_end_date: moment().endOf("month").format("YYYY-MM-DD"),
    }}
    useExport
  >
    <Datagrid expand={<ShowView />}>
      <EmployeeReferenceField source="employee_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="employee_id"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <CustomDateField
        source="datetime_in"
        sourceFormat="YYYY-MM-DD HH:mm:ss"
        destFormat="DD/MM/YYYY HH:mm:ss"
        label="Masuk"
      />

      <CustomDateField
        source="datetime_out"
        sourceFormat="YYYY-MM-DD HH:mm:ss"
        destFormat="DD/MM/YYYY HH:mm:ss"
        label="Keluar"
      />

      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
