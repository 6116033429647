import {
  Divider,
  ListSubheader,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import DefaultIcon from "@material-ui/icons/ViewList";
import clsx from "clsx";
import * as React from "react";
import {
  DashboardMenuItem,
  MenuItemLink,
  getResources,
  useGetIdentity,
} from "react-admin";
import { useSelector } from "react-redux";
import { ACCESS_CODES } from "../consts";
import WithPermission from "./WithPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  menuItemLink: {
    whiteSpace: "pre-line",
  },
}));

const WithResource = ({
  resource,
  onMenuClick = () => null,
  showIcon = true,
}) => {
  const classes = useStyles();
  const resources = useSelector(getResources);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const foundResource = resources.filter((res) => res.name === resource);

  if (foundResource && foundResource.length > 0) {
    return (
      <MenuItemLink
        className={classes.menuItemLink}
        to={`/${foundResource[0].name}`}
        primaryText={
          (foundResource[0].options &&
            typeof foundResource[0].options.labelFn === "function" &&
            foundResource[0].options.labelFn(foundResource[0])) ||
          (foundResource[0].options && foundResource[0].options.label) ||
          foundResource[0].name
        }
        leftIcon={
          showIcon ? (
            foundResource[0].icon ? (
              React.createElement(foundResource[0].icon)
            ) : (
              <DefaultIcon />
            )
          ) : null
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    );
  }

  return <></>;
};

export const CustomMenu = ({ onMenuClick, logout }) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const { identity } = useGetIdentity();

  return (
    <div className={clsx(!isXSmall && classes.root)}>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />

      <WithPermission codes={[ACCESS_CODES.PMO_BRO]}>
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Data Pegawai
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/employees"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/leaves/balances"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="contract/requests"
            showIcon={false}
          />
        </React.Fragment>
      </WithPermission>

      <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Data Pegawai
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/requests"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/employees"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/documents"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/performances"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/leaves/balances"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/leaves/histories"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/presences"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/activities"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/overtimes"
          />

          {identity?.user?.username !== "APRIL" && (
            <WithResource
              onMenuClick={onMenuClick}
              resource="employees/payslips"
            />
          )}
        </React.Fragment>
      </WithPermission>

      <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Kontrak Digital
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="contract/requests"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="contract/categories"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="contract/types"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="contract/templates"
            showIcon={false}
          />
        </React.Fragment>
      </WithPermission>

      <WithPermission
        codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO]}
      >
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Laporan
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/rekap"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/weekly-rekap"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/masuk"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/keluar"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/status"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/aktif"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/penggajian"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/employee/summary-activity-report"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="reports/partnership-presences"
            showIcon={false}
          />
        </React.Fragment>
      </WithPermission>

      <WithPermission codes={[ACCESS_CODES.ADMIN]}>
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Persetujuan
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="approval/requests"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="approval/types"
            showIcon={false}
          />
        </React.Fragment>
      </WithPermission>

      <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Penomoran
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="penomoran/surat"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="penomoran/nik"
            showIcon={false}
          />

          <ListSubheader component="div" disableSticky>
            Master Data
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="master/company-rules"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="master/jenis-surat"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="master/companies"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="master/customers"
            showIcon={false}
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="master/bank-payments"
            showIcon={false}
          />
        </React.Fragment>
      </WithPermission>

      <WithPermission codes={[ACCESS_CODES.ADMIN]}>
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Collection
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="collections/sites"
          />
        </React.Fragment>
      </WithPermission>

      <WithPermission
        codes={[
          ACCESS_CODES.ADMIN,
          ACCESS_CODES.HR,
          ACCESS_CODES.FINANCE,
          ACCESS_CODES.PMO_BRO,
        ]}
      >
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Lainnya
          </ListSubheader>

          <WithPermission
            codes={[
              ACCESS_CODES.ADMIN,
              ACCESS_CODES.HR,
              ACCESS_CODES.FINANCE,
              ACCESS_CODES.PMO_BRO,
            ]}
          >
            <WithResource
              onMenuClick={onMenuClick}
              resource="invoice/invoices"
            />
          </WithPermission>

          <WithPermission
            codes={[
              ACCESS_CODES.ADMIN,
              ACCESS_CODES.HR,
              ACCESS_CODES.FINANCE,
              ACCESS_CODES.PMO_BRO,
            ]}
          >
            <WithResource onMenuClick={onMenuClick} resource="payments" />
          </WithPermission>

          <WithPermission
            codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO]}
          >
            <WithResource onMenuClick={onMenuClick} resource="events" />
          </WithPermission>

          <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
            <WithResource
              onMenuClick={onMenuClick}
              resource="support/supports"
            />
          </WithPermission>

          <WithPermission codes={[ACCESS_CODES.ADMIN]}>
            <WithResource
              onMenuClick={onMenuClick}
              resource="notification/histories"
            />
          </WithPermission>
        </React.Fragment>
      </WithPermission>

      <WithPermission codes={[ACCESS_CODES.ADMIN]}>
        <React.Fragment>
          <ListSubheader component="div" disableSticky>
            Configurations
          </ListSubheader>

          <WithResource
            onMenuClick={onMenuClick}
            resource="configurations/users"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="configurations/features"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="configurations/versions"
          />

          <WithResource
            onMenuClick={onMenuClick}
            resource="employees/devices"
          />
        </React.Fragment>
      </WithPermission>

      {isXSmall && (
        <>
          <Divider />
          {logout}
        </>
      )}
    </div>
  );
};
