import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const DocumentResource = (permissions) => ({
  name: "employees/documents",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: LocalHospitalIcon,
  options: {
    label: "Jaminan",
  },
});

export default DocumentResource;
