import UpdateIcon from "@material-ui/icons/Update";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const VersionResource = (permissions) => ({
  name: "configurations/versions",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: UpdateIcon,
  options: {
    label: "Versions",
  },
});

export default VersionResource;
