import DownloadIcon from "@material-ui/icons/GetApp";
import React from "react";
import { Button } from "react-admin";

const DownloadButton = ({ record, ...props }) => {
  return (
    <Button
      component="a"
      href={record?.file?.url}
      label="Unduh File"
      {...props}
    >
      <DownloadIcon />
    </Button>
  );
};

export default DownloadButton;
