import moment from "moment";
import * as React from "react";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  NumberField,
  SelectInput,
  TextField,
} from "react-admin";
import {
  CustomDateField,
  CustomFilter,
  CustomList,
  CustomPrintButton,
} from "../../../components";
import { MONTH_ARRAY, YEAR_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";
import ShowView from "./ShowView";

const FilterView = (props) => (
  <CustomFilter {...props}>
    <EmployeeReferenceInput source="filter_employee" alwaysOn />

    <SelectInput
      source="filter_month"
      label="Bulan"
      optionText="name"
      choices={MONTH_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_year"
      label="Tahun"
      optionText="name"
      choices={YEAR_ARRAY}
      alwaysOn
    />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Slip Gaji"
    filters={<FilterView />}
    sort={{ field: "order", order: "DESC" }}
    filterDefaultValues={{
      filter_month: moment().month() + 1,
      filter_year: moment().year(),
    }}
    useImport
    useExport
  >
    <Datagrid expand={<ShowView />}>
      <TextField source="order" label="Nomor Urut" />

      <EmployeeReferenceField source="employee_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="employee_id"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <TextField source="company_name" label="Perusahaan" />

      <CustomDateField source="period" destFormat="MMMM YYYY" label="Periode" />

      <NumberField
        source="thp"
        label="THP"
        options={{ style: "currency", currency: "IDR" }}
        sortable={false}
      />

      <CustomPrintButton />
      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
