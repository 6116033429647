import { Datagrid, SelectInput, TextField } from "react-admin";
import { STATUS_ARRAY } from ".";
import { CustomDateField, CustomFilter, CustomList } from "../../../components";
import EmployeeReferenceField from "../../employees/employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";
import {
  ApprovalTypeReferenceField,
  ApprovalTypeReferenceInput,
} from "../Type/components";
import { RequestStatusField } from "./components";

const FilterView = (props) => (
  <CustomFilter {...props}>
    <ApprovalTypeReferenceInput
      source="filter_type"
      label="Jenis Permohonan"
      alwaysOn
    />

    <EmployeeReferenceInput source="filter_employee" alwaysOn />

    <SelectInput
      source="filter_status"
      label="Status"
      choices={STATUS_ARRAY}
      alwaysOn
    />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    filters={<FilterView />}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <EmployeeReferenceField
        source="employeeId"
        label="Pemohon"
        link={false}
        avatar
      />

      <TextField source="name" label="Keperluan" />

      <ApprovalTypeReferenceField
        source="typeId"
        label="Jenis Permohonan"
        link={false}
      />

      <CustomDateField
        source="createdAt.date"
        label="Waktu Dibuat"
        sourceFormat="YYYY-MM-DD HH:mm:ss"
        destFormat="DD/MM/YYYY HH:mm:ss"
      />

      <RequestStatusField label="Status" />
    </Datagrid>
  </CustomList>
);

export default ListView;
