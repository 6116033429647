import ListView from "./ListView";

export const TYPE_ARRAY = [
  { id: "telexindo", name: "Telexindo" },
  { id: "icca", name: "ICCA" },
  { id: "aruwa", name: "Aruwa" },
];

export const STATUS_ARRAY = [
  { id: "proses", name: "Proses" },
  { id: "selesai", name: "Selesai" },
  { id: "batal", name: "Batal" },
];

export const METHOD_ARRAY = [
  { id: "bank", name: "Transfer/Bank" },
  { id: "tunai", name: "Tunai/Kas kecil" },
];

const PaymentResource = (permissions) => ({
  name: "payments",
  list: ListView,
  options: {
    label: "Pembayaran",
  },
});

export default PaymentResource;
