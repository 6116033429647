import React from "react";
import { Layout } from "react-admin";
import { MAIN_THEME } from "../consts";
import { CustomAppBar } from "./CustomAppBar";
import { CustomMenu } from "./CustomMenu";

export const CustomLayout = (props) => {
  return (
    <Layout
      {...props}
      appBar={CustomAppBar}
      menu={CustomMenu}
      theme={MAIN_THEME}
    />
  );
};
