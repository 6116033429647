import { Grid, Card } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { useMutation, useNotify, useRedirect, Button } from "react-admin";
import React from "react";
import {
  ConditionalField,
  CustomActionsView,
  CustomEdit,
  CustomExportButton,
} from "../../../components";
import AsideView from "./AsideView";
import DownloadButton from "./DownloadButton";
import FormView from "./FormView";
import NotificationButton from "./NotificationButton";

const TitleView = ({ record }) => (
  <span>Ubah Request Kontrak #{record?.id}</span>
);

const Approval1Button = ({ record, className }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate, { loading }] = useMutation(
    {
      type: "update",
      resource: "contract/requests",
      payload: {
        id: record?.id,
        data: { status: "approval1", _ref: "update-status" },
      },
    },
    {
      onSuccess: ({ data }) => {
        redirect("show", "/contract/requests", data?.id);
        notify("Telah diajukan kepada pihak pertama", "info");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  return (
    <Button
      className={className}
      onClick={mutate}
      disabled={loading}
      label="Ajukan Approval Pihak Pertama"
    >
      <CheckIcon />
    </Button>
  );
};

const ActionsView = (props) => {
  return (
    <CustomActionsView {...props}>
      {({ listButton, buttonClass }) => {
        return (
          <React.Fragment>
            <ConditionalField
              record={props.data}
              when={(record) => record?.status !== "done"}
            >
              <NotificationButton />
            </ConditionalField>

            <ConditionalField
              record={props.data}
              when={(rec) => rec?.status !== "done"}
            >
              <CustomExportButton
                resource="contract/requests"
                filterValues={{
                  _ref: "download-template",
                  id: props?.data?.id,
                }}
                label="Unduh Template"
                className={buttonClass}
              />
            </ConditionalField>

            <ConditionalField
              record={props.data}
              when={(rec) => Boolean(rec?.file?.url)}
            >
              <DownloadButton className={buttonClass} />
            </ConditionalField>

            <ConditionalField
              record={props.data}
              when={(rec) => Boolean(rec?.file?.url)}
            >
              <CustomExportButton
                resource="contract/requests"
                filterValues={{
                  _ref: "merge-with-background",
                  id: props?.data?.id,
                }}
                label="Unduh PDF + Background"
                className={buttonClass}
              />
            </ConditionalField>

            <ConditionalField
              record={props.data}
              when={(rec) =>
                rec?.status === "request" && Boolean(rec?.file?.url)
              }
            >
              <Approval1Button className={buttonClass} record={props.data} />
            </ConditionalField>

            {listButton}
          </React.Fragment>
        );
      }}
    </CustomActionsView>
  );
};

const EditContainer = ({ className, children }) => {
  return (
    <div className={className}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>{children}</Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <AsideView />
        </Grid>
      </Grid>
    </div>
  );
};

const EditView = (props) => {
  return (
    <CustomEdit
      {...props}
      title={<TitleView />}
      actions={<ActionsView />}
      component={EditContainer}
      mutationMode="pessimistic"
    >
      <FormView redirect="edit" />
    </CustomEdit>
  );
};

export default EditView;
