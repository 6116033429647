import { makeStyles, Stepper } from "@material-ui/core";
import { HistoryItem } from ".";

const useStyles = makeStyles({
  root: {
    background: "none",
    border: "none",
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const HistoryIterator = ({ data, ...props }) => {
  const classes = useStyles(props);

  if ((data || []).length === 0) {
    return null;
  }

  return (
    <Stepper orientation="vertical" classes={{ root: classes.root }}>
      {data.map((record) => (
        <HistoryItem key={`history-${record.id}`} record={record} />
      ))}
    </Stepper>
  );
};

export default HistoryIterator;
