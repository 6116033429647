import { makeStyles, Typography } from "@material-ui/core";
import Inbox from "@material-ui/icons/Inbox";
import PropTypes from "prop-types";
import React from "react";
import {
  CreateButton,
  useListContext,
  useResourceContext,
  useTranslate,
} from "react-admin";
import ImportButton from "./ImportButton";

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: "center",
      opacity: theme.palette.type === "light" ? 0.5 : 0.8,
      margin: "0 1em",
      color:
        theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
    },
    icon: {
      width: "9em",
      height: "9em",
    },
    toolbar: {
      textAlign: "center",
      marginTop: "2em",
    },
    button: {
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: 0,
      },
    },
  }),
  {
    name: "CustomEmptyStyles",
  }
);

const CustomEmpty = ({ useImport = false, hasCreate, ...props }) => {
  const { basePath } = useListContext(props);
  const resource = useResourceContext(props);
  const classes = useStyles(props);
  const translate = useTranslate();

  const inviteMessage = translate("ra.page.invite");

  return (
    <>
      <div className={classes.message}>
        {resource.icon ? (
          <resource.icon className={classes.icon} />
        ) : (
          <Inbox className={classes.icon} />
        )}

        <Typography variant="h4" paragraph>
          No data available
        </Typography>

        {hasCreate && <Typography variant="body1">{inviteMessage}</Typography>}
      </div>

      <div className={classes.toolbar}>
        {hasCreate && (
          <CreateButton
            variant="contained"
            basePath={basePath}
            className={classes.button}
          />
        )}

        {useImport && (
          <ImportButton
            variant="contained"
            resource={resource}
            className={classes.button}
          />
        )}
      </div>
    </>
  );
};

CustomEmpty.propTypes = {
  useImport: PropTypes.bool,
};

export default CustomEmpty;
