import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const PerusahaanResource = (permissions) => ({
  name: "master/companies",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Perusahaan",
  },
});

export default PerusahaanResource;
