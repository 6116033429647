import React from "react";
import PropTypes from "prop-types";
import { Button, useDataProvider, useNotify } from "react-admin";
import ExportIcon from "@material-ui/icons/GetApp";
import { CircularProgress } from "@material-ui/core";

const CustomExportButton = ({ resource, filterValues, ...props }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { q, ...filter } = filterValues;
  const [isLoading, setLoading] = React.useState(false);

  const handleClick = () => {
    if (isLoading) return;

    setLoading(true);

    dataProvider
      .export(resource, {
        filter,
      })
      .then(({ data }) => {
        notify("Downloading...", "info");

        window.open(data.url);
      })
      .catch((error) => {
        notify(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      label="Export"
      {...props}
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? <CircularProgress size={16} /> : <ExportIcon />}
    </Button>
  );
};

CustomExportButton.propTypes = {
  resource: PropTypes.string.isRequired,
  filterValues: PropTypes.object,
};

export default CustomExportButton;
