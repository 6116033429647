import { Datagrid, DeleteButton, EditButton, TextField } from "react-admin";
import React from "react";
import { CustomList } from "../../../components";
import DownloadButton from "./DownloadButton";

const ListView = (props) => {
  return (
    <CustomList {...props} title="Template Kontrak">
      <Datagrid>
        <TextField source="name" label="Nama" />

        <DownloadButton />

        <EditButton />
        <DeleteButton mutationMode="optimistic" undoable={false} />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
