import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { AppBar } from "react-admin";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

export const CustomAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar {...props} elevation={1}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />

      <span className={classes.spacer} />
    </AppBar>
  );
};
