import { Button } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useRecordContext } from "react-admin";
import { Link as RouterLink } from "react-router-dom";

const CreateEmployeeButton = (props) => {
  const record = useRecordContext(props);

  if (!record) return null;

  const to = {
    pathname: "/employees/employees/create",
    state: {
      record: {
        namakaryawan: record.name,
        kodekaryawan: record.alias,
        emailkaryawan: record.email,
        telpkaryawan: record.phone,
        tempatlahir: record.birthPlace,
        tgllahir: record.birthDate,
        jeniskelamin: record.gender === "m" ? "L" : "P",
        statusnikah:
          record.marital === "TK0"
            ? "TK"
            : record.marital === "K0"
            ? "K"
            : record.marital,
        pendidikan: record.education,
        edu_school: record.education_school,
        edu_major: record.education_major,
        edu_year: record.education_year,
        edu_score: record.education_score,
        alamatktp: record.ktpAddress,
        alamatkaryawan: record.domAddress,
        nomorktp: record.ktpCode,
        nomorkk: record.kkCode,
        bpjstenaga: record.bpjsTkCode,
        bpjssehat: record.bpjsKsCode,
        npwpkaryawan: record.npwpCode,
        jabatan: record.workPosition,
        lokasikerja:
          record.workLocation === "ho"
            ? "PT Telexindo Bizmart"
            : record.workLocationOther,
        tglawalkontrak: record.workStartDate,
        tglakhirkontrak: record.workEndDate,
        join_type: record.join_type,
        _meta: {
          resource: "employee_requests",
          payload: {
            id: record.id,
          },
        },
      },
    },
  };

  return (
    <Button
      color="primary"
      component={RouterLink}
      to={to}
      startIcon={<PersonAddIcon />}
    >
      BUAT DATA PEGAWAI
    </Button>
  );
};

export default CreateEmployeeButton;
