import * as React from "react";
import { CustomEdit } from "../../../components";
import FormView from "./FormView";

const TitleView = ({ record }) => <span>Edit User #{record.id}</span>;

const EditView = (props) => (
  <CustomEdit {...props} title={<TitleView />}>
    <FormView />
  </CustomEdit>
);

export default EditView;
