import { Paper } from "@material-ui/core";
import { useGetOne } from "ra-core";
import { Loading } from "ra-ui-materialui";
import React from "react";
import { Line } from "react-chartjs-2";

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const DashboardView = () => {
  const { data, loading, error } = useGetOne("employees/devices", "datasets");

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Paper>
      <Line data={data} options={options} />
    </Paper>
  );
};

export default DashboardView;
