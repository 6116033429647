import { Typography } from "@material-ui/core";
import * as React from "react";
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  TextField,
} from "react-admin";
import { CustomList } from "../../../components";

const VersionField = ({ record = {} }) => (
  <>
    <Typography variant="body1">{record.code}</Typography>
    <Typography variant="caption" color="textSecondary">
      {record.build_number}
    </Typography>
  </>
);

const ListView = (props) => (
  <CustomList {...props} title="Application Versions">
    <Datagrid>
      <VersionField label="Version" sortBy="build_number" />

      <TextField source="description" label="Changelog" />

      <BooleanField source="allow_skip" label="Skippable" />

      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
