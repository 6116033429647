import { Grid } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { ACTIVITY_DAY_ARRAY, ACTIVITY_TYPE_ARRAY } from "../../../consts";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const VALIDATION = {
  emp_id: [required()],
  day: [required()],
  type: [required()],
  lines: {
    description: [required()],
  },
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <EmployeeReferenceInput
          source="emp_id"
          label="Karyawan"
          validate={VALIDATION.emp_id}
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={4} lg={3}>
            <SelectInput
              source="day"
              label="Hari"
              choices={ACTIVITY_DAY_ARRAY}
              validate={VALIDATION.day}
              fullWidth
            />
          </Grid>

          <Grid item xs={4} lg={3}>
            <SelectInput
              source="type"
              label="Kondisi"
              choices={ACTIVITY_TYPE_ARRAY}
              validate={VALIDATION.type}
              fullWidth
            />
          </Grid>

          <Grid item xs={4} lg={3}>
            <BooleanInput
              source="flag_penugasan_khusus"
              label="WFH+"
              validate={VALIDATION.flag_penugasan_khusus}
            />
          </Grid>
        </Grid>

        <ArrayInput source="lines_array" label="Kegiatan">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource }) => (
                <React.Fragment>
                  <TextInput
                    source={getSource("description")}
                    label="Kegiatan"
                    validate={VALIDATION.lines.description}
                    multiline
                    fullWidth
                  />

                  <NumberInput
                    source={getSource("dur_in_hour")}
                    label="Durasi (Jam)"
                    validate={VALIDATION.lines.dur_in_hour}
                  />
                </React.Fragment>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
