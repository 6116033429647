import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const JenisSuratResource = (permissions) => ({
  name: "master/jenis-surat",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Jenis Surat",
  },
});

export default JenisSuratResource;
