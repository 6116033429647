import { Button } from "@material-ui/core";
import {
  useNotify,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";

const ProcessButton = (props) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const notify = useNotify();
  const newRecord = { status: "process" };

  const [update, { loading }] = useUpdate(
    resource,
    record.id,
    newRecord,
    record,
    {
      onFailure: () => {
        notify("Gagal merubah status kandidat", "error");
      },
    }
  );

  if (!record) return null;

  return (
    <Button color="primary" onClick={update} disabled={loading}>
      PROSES
    </Button>
  );
};

export default ProcessButton;
