import { makeStyles } from "@material-ui/core";
import React from "react";
import { FunctionField, ReferenceField } from "react-admin";
import AvatarField from "./AvatarField";

const defaultOptionText = (record) => {
  if (record?.nomorkaryawan && record?.namakaryawan) {
    return `${record.nomorkaryawan} - ${record.namakaryawan}`;
  }

  return null;
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    avatar: {
      marginTop: -theme.spacing(0.5),
      marginBottom: -theme.spacing(0.5),
      marginRight: theme.spacing(1),
      fontSize: theme.typography.subtitle1.fontSize,
    },
  }),
  { name: "EmployeeReferenceFieldStyles" }
);

const EmployeeReferenceField = ({
  filter = {},
  optionText = defaultOptionText,
  avatar = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <ReferenceField
      filter={{ simple: 1, ...filter }}
      label="Karyawan"
      {...props}
      reference="employees/employees"
    >
      <FunctionField
        render={(record) => (
          <div className={classes.root}>
            {avatar && (
              <AvatarField
                record={record}
                size={32}
                className={classes.avatar}
              />
            )}

            <div>{optionText(record)}</div>
          </div>
        )}
      />
    </ReferenceField>
  );
};

export default EmployeeReferenceField;
