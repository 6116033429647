import { Card } from "@material-ui/core";
import React from "react";
import { Show } from "react-admin";
import CustomActionsView from "./CustomActionsView";

const Component = ({ className, children }) => (
  <div className={className}>
    <Card>{children}</Card>
  </div>
);

export const CustomShow = ({ children, ...props }) => {
  return (
    <Show actions={<CustomActionsView />} component={Component} {...props}>
      {children}
    </Show>
  );
};
