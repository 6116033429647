import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  useDataProvider,
  useNotify,
} from "react-admin";

const defaultOptionText = (record) => {
  if (record.name) {
    return `${record.name}`;
  }
};

const CompanyReferenceInput = ({
  filter = {},
  optionText = defaultOptionText,
  onChangeCustom,
  ...props
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const handleOnChange = React.useCallback(
    (id) => {
      if (id) {
        dataProvider
          .getOne("master/companies", { id, simple: 1 })
          .then(({ data }) => {
            onChangeCustom(data);
          })
          .catch((error) => notify(error.message, "error"));
      } else {
        onChangeCustom(null);
      }
    },
    [dataProvider, notify, onChangeCustom]
  );

  return (
    <ReferenceInput
      filter={{ simple: 1, ...filter }}
      label="Perusahaan"
      {...props}
      reference="master/companies"
      onChange={typeof onChangeCustom === "function" ? handleOnChange : null}
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInput>
  );
};

export default CompanyReferenceInput;
