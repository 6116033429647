import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const SuratResource = (permissions) => ({
  name: "penomoran/surat",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Surat",
  },
});

export default SuratResource;
