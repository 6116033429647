import React from "react";
import PropTypes from "prop-types";
import { Box, LinearProgress, Typography } from "@material-ui/core";

const LinearProgressWithLabel = ({ label, ...props }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {label || `${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
};

export default LinearProgressWithLabel;
