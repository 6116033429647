import React from "react";
import PropTypes from "prop-types";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));

const QuickFilter = ({ label }) => {
  const classes = useStyles();

  return <Chip className={classes.chip} label={label} />;
};

QuickFilter.propTypes = {
  label: PropTypes.string.isRequired,
};

export default QuickFilter;
