import AppsIcon from "@material-ui/icons/Apps";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const FeatureResource = (permissions) => ({
  name: "configurations/features",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: AppsIcon,
  options: {
    label: "Features",
  },
});

export default FeatureResource;
