import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import PropTypes from "prop-types";
import React from "react";
import { useDataProvider, useNotify } from "react-admin";

const useStyles = makeStyles({
  icon: {
    fontSize: "20px",
  },
  label: {
    paddingLeft: "0.5em",
  },
});

const CustomPrintButton = ({ record, resource }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [isLoading, setLoading] = React.useState(false);

  const handleClick = () => {
    if (isLoading) return;

    setLoading(true);

    dataProvider
      .getOne(resource, {
        id: record.id,
        print: "1",
      })
      .then(({ data }) => {
        notify("Downloading...", "info");

        window.open(data.url);
      })
      .catch((error) => {
        notify(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      size="small"
      color="primary"
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <PrintIcon className={classes.icon} />
      )}
      <span className={classes.label}>Print</span>
    </Button>
  );
};

CustomPrintButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
};

export default CustomPrintButton;
