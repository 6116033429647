import { Grid } from "@material-ui/core";
import React from "react";
import {
  DateInput,
  FileField,
  FileInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { DOCUMENT_STATUS_ARRAY } from "../../../consts";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const VALIDATION = {
  idpegawai: [required()],
  idjaminan: [required()],
  nomorjaminan: [],
  tglpendaftaran: [],
  tglselesai: [],
  keterangan: [],
  statusjaminan: [required()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <EmployeeReferenceInput
          source="idpegawai"
          validate={VALIDATION.idpegawai}
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <ReferenceInput
              source="idjaminan"
              label="Kategori"
              reference="documents"
              validate={VALIDATION.idjaminan}
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <TextInput
              source="nomorjaminan"
              label="Nomor"
              validate={VALIDATION.nomorjaminan}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput
              source="tgldaftar"
              label="Tanggal Pendaftaran"
              validate={VALIDATION.tgldaftar}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              source="tglselesai"
              label="Tanggal Berakhir"
              validate={VALIDATION.tglselesai}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} lg={3}>
            <SelectInput
              source="statusjaminan"
              label="Status"
              choices={DOCUMENT_STATUS_ARRAY}
              validate={VALIDATION.statusjaminan}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={9}>
            <TextInput
              source="keterangan"
              label="Keterangan"
              validate={VALIDATION.keterangan}
              fullWidth
              multiline
            />
          </Grid>
        </Grid>

        <FileInput
          source="documentFile"
          label="Upload File"
          accept=".pdf,.doc,.docx,.xls,.xlsx"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
