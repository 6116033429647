import React from "react";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Button } from "ra-ui-materialui";

const DownloadButton = ({ record, ...props }) => {
  return (
    <Button component="a" href={record?.file?.url} label="Unduh PDF" {...props}>
      <DownloadIcon />
    </Button>
  );
};

export default DownloadButton;
