import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Step,
  StepContent,
  StepLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import CommentIcon from "@material-ui/icons/Comment";
import GetAppIcon from "@material-ui/icons/GetApp";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RoomIcon from "@material-ui/icons/Room";
import lodashFind from "lodash/find";
import lodashGet from "lodash/get";
import { FunctionField, TextField } from "react-admin";
import { DateHelper } from "../../../../helpers";
import EmployeeReferenceField from "../../../employees/employees/EmployeeReferenceField";
import { HISTORY_STATUS_ARRAY } from "../index";

const HistoryItem = ({ record, ...props }) => {
  return (
    <Step {...props} expanded active completed>
      <StepLabel
        StepIconComponent={() => {
          const status = lodashFind(HISTORY_STATUS_ARRAY, {
            id: record?.status,
          });
          const Component = status?.icon || RadioButtonUncheckedIcon;
          const color = status?.color || "#2196F3";
          const title = status?.name || "Menunggu Persetujuan";

          return (
            <Tooltip title={title}>
              <Component fontSize="small" style={{ paddingLeft: 3, color }} />
            </Tooltip>
          );
        }}
      >
        {DateHelper.format(
          lodashGet(record, "processedAt") ||
            lodashGet(record, "createdAt.date"),
          "YYYY-MM-DD HH:mm:ss",
          "LLLL"
        )}
      </StepLabel>

      <StepContent>
        <Card>
          <CardContent>
            <Typography variant="body2" component="div">
              <EmployeeReferenceField
                record={record}
                source="employeeId"
                link={false}
              />
            </Typography>

            {record?.description && (
              <Box display="flex" mt="1em">
                <Box mr="1em">
                  <CommentIcon fontSize="small" color="disabled" />
                </Box>
                <Box flexGrow={1}>
                  <TextField
                    record={record}
                    source="description"
                    color="textSecondary"
                  />
                </Box>
              </Box>
            )}

            {record?.latitude && record?.longitude && (
              <Box display="flex" mt="1em">
                <Box mr="1em">
                  <RoomIcon fontSize="small" color="disabled" />
                </Box>
                <Box flexGrow={1}>
                  <FunctionField
                    record={record}
                    render={(rec) => `${rec.latitude}, ${rec.longitude}`}
                    color="textSecondary"
                  />
                </Box>
              </Box>
            )}

            {record?.address && (
              <Box display="flex" mt="1em">
                <Box mr="1em">
                  <BusinessIcon fontSize="small" color="disabled" />
                </Box>
                <Box flexGrow={1}>
                  <TextField
                    record={record}
                    source="address"
                    color="textSecondary"
                  />
                </Box>
              </Box>
            )}
          </CardContent>

          {record?.document && (
            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                component="a"
                href={record?.document?.url}
                title={record?.document?.name}
                target="_blank"
                fullWidth
              >
                UNDUH DOKUMEN
              </Button>
            </CardActions>
          )}
        </Card>
      </StepContent>
    </Step>
  );
};

export default HistoryItem;
