import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const TemplateResource = (permissions) => ({
  name: "contract/templates",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Template Kontrak",
  },
});

export default TemplateResource;
