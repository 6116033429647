import {
  Avatar,
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import lodashRange from "lodash/range";
import React from "react";

const DashboardSkeleton = () => {
  return (
    <div>
      <Typography variant="h3">
        <Skeleton animation="wave" />
      </Typography>

      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={3}>
            <Skeleton
              component={Paper}
              variant="rect"
              animation="wave"
              height={128}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Skeleton
              component={Paper}
              variant="rect"
              animation="wave"
              height={128}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Skeleton
              component={Paper}
              variant="rect"
              animation="wave"
              height={128}
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <Skeleton
              component={Paper}
              variant="rect"
              animation="wave"
              height={128}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Box my={1}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <Skeleton
                  component={Paper}
                  variant="rect"
                  animation="wave"
                  height={128}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <Skeleton
                  component={Paper}
                  variant="rect"
                  animation="wave"
                  height={128}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <Skeleton
                  component={Paper}
                  variant="rect"
                  animation="wave"
                  height={128}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <Skeleton
                  component={Paper}
                  variant="rect"
                  animation="wave"
                  height={128}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <Skeleton
                  component={Paper}
                  variant="rect"
                  animation="wave"
                  height={128}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <Skeleton
                  component={Paper}
                  variant="rect"
                  animation="wave"
                  height={128}
                />
              </Grid>
            </Grid>
          </Box>

          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Box mt={1}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>

                    <TableCell align="right">
                      <Skeleton animation="wave" />
                    </TableCell>

                    <TableCell align="right">
                      <Skeleton animation="wave" />
                    </TableCell>

                    <TableCell align="right">
                      <Skeleton animation="wave" />
                    </TableCell>

                    <TableCell align="right">
                      <Skeleton animation="wave" />
                    </TableCell>

                    <TableCell align="right">
                      <Skeleton animation="wave" />
                    </TableCell>

                    <TableCell align="right">
                      <Skeleton animation="wave" />
                    </TableCell>

                    <TableCell align="right">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {lodashRange(5).map((index) => (
                    <TableRow key={`skeleton-activity-report-daily-${index}`}>
                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>

                      <TableCell component="td" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Box mt={1}>
            <Card>
              <List dense>
                {lodashRange(10).map((index) => (
                  <ListItem
                    key={`skeleton-activity-report-today-${index}`}
                    dense
                    divider={index < 9}
                  >
                    <ListItemIcon>
                      <Skeleton variant="circle" animation="wave">
                        <Avatar />
                      </Skeleton>
                    </ListItemIcon>

                    <ListItemText
                      primary={<Skeleton animation="wave" />}
                      secondary={<Skeleton animation="wave" />}
                    />
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardSkeleton;
