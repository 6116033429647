import { Datagrid, DeleteButton, EditButton, TextField } from "react-admin";
import { CustomList } from "../../../components";

const ListView = (props) => (
  <CustomList {...props}>
    <Datagrid>
      <TextField source="name" label="Jenis Approval" />

      <EditButton />

      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
