import React from "react";
import PropTypes from "prop-types";
import { FormDataConsumer } from "react-admin";

const ConditionalFormDataConsumer = ({
  when = (formData) => true,
  children,
  ...props
}) => {
  return (
    <FormDataConsumer {...props}>
      {(FormDataProps) =>
        when(FormDataProps.formData) && children(FormDataProps)
      }
    </FormDataConsumer>
  );
};

ConditionalFormDataConsumer.propTypes = {
  when: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export default ConditionalFormDataConsumer;
