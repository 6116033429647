import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { StaticText } from "../../../components";

const ShowView = ({ record }) => (
  <Container>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">MASUK</Typography>
            </Box>

            <Box mb={2}>
              <StaticText title="Lokasi" content={record.location_in || "-"} />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Kordinat"
                content={record.coordinate_in || "-"}
              />
            </Box>

            <Box mb={0}>
              <StaticText
                title="Keterangan"
                content={record.description_in || "-"}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">KELUAR</Typography>
            </Box>

            <Box mb={2}>
              <StaticText title="Lokasi" content={record.location_out || "-"} />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Kordinat"
                content={record.coordinate_out || "-"}
              />
            </Box>

            <Box mb={0}>
              <StaticText
                title="Keterangan"
                content={record.description_out || "-"}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Container>
);

export default ShowView;
