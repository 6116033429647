import React from "react";
import {
    AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import AdminArrayInput from "./AdminArrayInput";
import EmployeeArrayInput from "./EmployeeArrayInput";

const VALIDATION = {
    name: [required()],
    location: [required()]
}
const FormView = ({enableAutoGeneratePin = false , ...props}) => {
  const enablePin = enableAutoGeneratePin
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <TextInput validate={VALIDATION.name} source="name" label="Nama Site" fullWidth />
        <ReferenceInput
          fullWidth
          label="Lokasi"
          source="location"
          reference="employees/locations"
          validate={VALIDATION.location}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <AdminArrayInput enableAutoGeneratePin={enablePin} source="admins" label="Admin Site" />

        <EmployeeArrayInput source="officers" label="Officer Site" />
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
