import { Box } from "@material-ui/core";
import lodashFind from "lodash/find";
import { SelectField, useRecordContext } from "react-admin";
import { STATUS_ARRAY } from "..";

const RequestStatusField = ({ color, ...props }) => {
  const record = useRecordContext(props);
  const status = lodashFind(STATUS_ARRAY, { id: record?.status || "draft" });
  const Icon = status.icon;

  return (
    <Box display="flex" style={{ color: color || status.color }}>
      <Box mr="1em">
        <Icon fontSize="small" />
      </Box>

      <Box flexGrow={1}>
        <SelectField record={record} source="status" choices={STATUS_ARRAY} />
      </Box>
    </Box>
  );
};

export default RequestStatusField;
