import { Box, Container } from "@material-ui/core";
import { ImageField, RichTextField } from "ra-ui-materialui";
import React from "react";
import DetailEmployeeTable from "./DetailEmployeeTable";
import DetailLocationTable from "./DetailLocationTable";

const ExpandView = ({ record = {} }) => {
  return (
    <Container>
      {record?.image && (
        <ImageField record={record} source="image.view_url" title="name" />
      )}

      {record?.description && (
        <RichTextField record={record} source="description" />
      )}

      {record.visibility === "in_list" && (
        <Box mt={2}>
          <DetailEmployeeTable record={record} />
        </Box>
      )}

      {record.visibility === "in_list_location" && (
        <Box mt={2}>
          <DetailLocationTable record={record} />
        </Box>
      )}
    </Container>
  );
};

export default ExpandView;
