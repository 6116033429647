import { Grid } from "@material-ui/core";
import * as React from "react";
import {
  BooleanInput,
  DateInput,
  FormTab,
  NumberInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from "react-admin";
import { REMUNERATION_TYPE_ARRAY, WORK_LOCATION_ARRAY } from ".";
import { ConditionalFormDataConsumer } from "../../../components";
import { GENDER_ARRAY, MARITAL_ARRAY } from "../../../consts";
import { EMPLOYEE_JOIN_TYPE_ARRAY } from "../employees";

const FormView = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="DATA PRIBADI">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <TextInput source="name" label="Nama Lengkap" fullWidth />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput source="alias" label="Nama Panggilan" fullWidth />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput
                type="email"
                source="email"
                label="ALamat Email"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput source="phone" label="Nomor Telpon" fullWidth />
            </Grid>

            <Grid item xs={6} lg={4}>
              <TextInput source="birthPlace" label="Tempat Lahir" fullWidth />
            </Grid>

            <Grid item xs={6} lg={4}>
              <DateInput source="birthDate" label="Tanggal Lahir" fullWidth />
            </Grid>

            <Grid item xs={12} lg={4}>
              <SelectInput
                source="gender"
                label="Jenis Kelamin"
                choices={GENDER_ARRAY}
                format={(value) => {
                  return value === "m" ? "L" : value === "f" ? "P" : null;
                }}
                parse={(value) => {
                  return value === "L" ? "m" : value === "P" ? "f" : null;
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <SelectInput
                source="marital"
                label="Status Pernikahan"
                choices={MARITAL_ARRAY}
                format={(value) => {
                  return value === "TK0" ? "TK" : value === "K0" ? "K" : value;
                }}
                parse={(value) => {
                  return value === "TK" ? "TK0" : value === "K" ? "K0" : null;
                }}
                fullWidth
              />
            </Grid>

            <Grid item lg={3} xs={12}>
              <TextInput source="education" label="Pendidikan" fullWidth />
            </Grid>

            <Grid item lg={3} xs={12}>
            <SelectInput
                source="join_type"
                label="Status Join"
                choices={EMPLOYEE_JOIN_TYPE_ARRAY}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                source="domAddress"
                label="Alamat Domisili"
                fullWidth
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                source="ktpAddress"
                label="Alamat KTP"
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </FormTab>

      <FormTab label="DOKUMEN">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput source="ktpCode" label="Nomor KTP" fullWidth />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextInput
                source="kkCode"
                label="Nomor Kartu Keluarga"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput source="npwpCode" label="Nomor NPWP" fullWidth />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput
                source="bpjsKsCode"
                label="Nomor BPJS Kesehatan"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput
                source="bpjsTkCode"
                label="Nomor BPJS Ketenagakerjaan"
                fullWidth
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </FormTab>

      <FormTab label="FPTK">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <TextInput
                source="fptkCode"
                label="Nomor FPTK"
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextInput source="workPosition" label="Posisi" fullWidth />
            </Grid>

            <Grid item xs={12} lg={3}>
              <SelectInput
                source="workLocation"
                label="Penempatan"
                choices={WORK_LOCATION_ARRAY}
                fullWidth
              />
            </Grid>

            <ConditionalFormDataConsumer
              when={(formData) => formData.workLocation === "client"}
            >
              {() => (
                <Grid item xs={12} lg={3}>
                  <TextInput
                    source="workLocationOther"
                    label="Lokasi Kerja"
                    fullWidth
                  />
                </Grid>
              )}
            </ConditionalFormDataConsumer>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={6} lg={3}>
                <DateInput
                  source="workStartDate"
                  label="Awal Kontrak"
                  fullWidth
                />
              </Grid>

              <Grid item xs={6} lg={3}>
                <DateInput
                  source="workEndDate"
                  label="Akhir Kontrak"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <SelectInput
                  source="remunerationType"
                  label="Remunerasi"
                  choices={REMUNERATION_TYPE_ARRAY}
                  fullWidth
                />
              </Grid>

              <ConditionalFormDataConsumer
                when={(formData) => formData.remunerationType === "custom"}
              >
                {() => (
                  <Grid item xs={12} lg={3}>
                    <NumberInput
                      source="remunerationOther"
                      label="Remunerasi"
                      fullWidth
                    />
                  </Grid>
                )}
              </ConditionalFormDataConsumer>
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12} lg={4}>
                <BooleanInput
                  source="benefitBpjsKs"
                  label="Benefit BPJS Kesehatan"
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <BooleanInput
                  source="benefitBpjsTk"
                  label="Benefit BPJS Ketenagakerjaan"
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <BooleanInput
                  source="benefitBpjsPensiun"
                  label="Benefit BPJS Pensiun"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} lg={3}>
              <NumberInput
                source="tunjanganJabatan"
                label="Tunjangan Jabatan"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <NumberInput
                source="tunjanganKehadiran"
                label="Tunjangan Kehadiran"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <NumberInput
                source="tunjanganTransport"
                label="Tunjangan Transport"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <NumberInput
                source="tunjanganMakan"
                label="Tunjangan Makan"
                fullWidth
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </FormTab>
    </TabbedForm>
  );
};

export default FormView;
