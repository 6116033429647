import {
  Datagrid,
  DeleteButton,
  EditButton,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { CustomList } from "../../../components";

const ListView = (props) => {
  return (
    <CustomList {...props} title="Status Kontrak">
      <Datagrid>
        <TextField source="code" label="Kode" />

        <TextField source="name" label="Nama" />

        <EditButton />
        <DeleteButton mutationMode="optimistic" undoable={false} />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
