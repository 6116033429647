import { required } from "react-admin";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const fileValidation = (value, allValues) => {
  if (!allValues?.id) {
    return required()(value, allValues);
  }

  return [];
};

export const VALIDATION = {
  name: [required()],
  fileInput: [fileValidation],
};

const CompanyRuleResource = (permissions) => ({
  name: "master/company-rules",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Buku PP",
  },
});

export default CompanyRuleResource;
