import { Grid } from "@material-ui/core";
import { Fragment } from "react";
import {
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { FIELD_ARRAY, VALIDATION } from ".";
import ApproverArrayInput from "./components/ApproverArrayInput";

const FormView = (props) => (
  <SimpleForm {...props}>
    <Fragment>
      <TextInput
        source="name"
        label="Jenis Approval"
        validate={VALIDATION.name}
        fullWidth
      />

      <TextInput
        source="description"
        label="Keterangan"
        validate={VALIDATION.description}
        fullWidth
        multiline
      />

      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldDocument"
            label="Dokumen"
            choices={FIELD_ARRAY.filter((e) => e.id !== "none")}
            validate={VALIDATION.fieldDocument}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldDate"
            label="Tanggal"
            choices={FIELD_ARRAY}
            validate={VALIDATION.fieldDate}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldPeriod"
            label="Periode"
            choices={FIELD_ARRAY}
            validate={VALIDATION.fieldPeriod}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldProduct"
            label="Produk"
            choices={FIELD_ARRAY}
            validate={VALIDATION.fieldProduct}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldQuantity"
            label="Kuantitas"
            choices={FIELD_ARRAY}
            validate={VALIDATION.fieldQuantity}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldAmount"
            label="Jumlah Harga"
            choices={FIELD_ARRAY}
            validate={VALIDATION.fieldAmount}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldCoordinate"
            label="Kordinat"
            choices={FIELD_ARRAY}
            validate={VALIDATION.fieldCoordinate}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <SelectInput
            source="fieldAddress"
            label="Alamat"
            choices={FIELD_ARRAY}
            validate={VALIDATION.fieldAddress}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <NumberInput
            source="approverMin"
            label="Min. Approval"
            validate={VALIDATION.approverMin}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <BooleanInput
            source="approverLeader"
            label="Approval Leader"
            validate={VALIDATION.approverLeader}
          />
        </Grid>
      </Grid>

      <ApproverArrayInput source="approvers" label="Approver" />
    </Fragment>
  </SimpleForm>
);

export default FormView;
