import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { StaticText } from "../../../components";

const CurrencyField = ({ value = 0, prefix = "IDR " }) => (
  <NumberFormat
    value={value}
    displayType="text"
    thousandSeparator={true}
    prefix={prefix}
    decimalScale={2}
    fixedDecimalScale
  />
);

const ShowView = ({ record }) => (
  <Container>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">PENDAPATAN</Typography>
            </Box>

            <Box mb={2}>
              <StaticText
                title="Gaji Pokok"
                content={<CurrencyField value={record.gaji_pokok} />}
              />
            </Box>

            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <StaticText
                    title="Hari"
                    content={
                      <CurrencyField
                        value={record.uang_makan_transport_hari}
                        prefix=""
                      />
                    }
                  />
                </Grid>

                <Grid item xs={8}>
                  <StaticText
                    title="Uang Makan / Transport"
                    content={
                      <CurrencyField value={record.uang_makan_transport} />
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={2}>
              <StaticText
                title="Tunjangan Tidak Tetap"
                content={<CurrencyField value={record.tunjangan_tidak_tetap} />}
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Reward"
                content={<CurrencyField value={record.reward} />}
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Tunjangan BPJS Kesehatan"
                content={
                  <CurrencyField value={record.tunjangan_bpjs_kesehatan} />
                }
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Tunjangan BPJS Ketenagakerjaan"
                content={
                  <CurrencyField
                    value={record.tunjangan_bpjs_ketenagakerjaan}
                  />
                }
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Tunjangan Dana Pensiun BPJS TK"
                content={
                  <CurrencyField value={record.tunjangan_dana_pensiun} />
                }
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Tunjangan Lembur / Shift"
                content={
                  <CurrencyField value={record.tunjangan_lembur_shift} />
                }
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Tunjangan Hari Raya (THR)"
                content={<CurrencyField value={record.tunjangan_thr} />}
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Kompensasi"
                content={<CurrencyField value={record.kompensasi} />}
              />
            </Box>

            <Box mb={0}>
              <StaticText
                title="Bonus Penjualan / Kekurangan"
                content={<CurrencyField value={record.reward} />}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">KOPERASI</Typography>
            </Box>

            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <StaticText
                    title="Simpanan Koperasi"
                    content={<CurrencyField value={record.simpanan_koperasi} />}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <StaticText
                    title="Pinjaman Karyawan"
                    content={<CurrencyField value={record.pinjaman_karyawan} />}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  {" "}
                  <StaticText
                    title="Sisa Pinjaman"
                    content={<CurrencyField value={record.sisa_pinjaman} />}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={2}>
              <Typography variant="subtitle2">POTONGAN</Typography>
            </Box>

            <Box mb={2}>
              <StaticText
                title="Potongan Pajak"
                content={<CurrencyField value={record.potongan_pajak} />}
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Potongan Pinjaman"
                content={<CurrencyField value={record.potongan_pinjaman} />}
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Potongan BPJS Kesehatan"
                content={
                  <CurrencyField value={record.potongan_bpjs_kesehatan} />
                }
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Potongan BPJS Ketenagakerjaan"
                content={
                  <CurrencyField value={record.potongan_bpjs_ketenagakerjaan} />
                }
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Potongan Dana Pensiun BPJS TK"
                content={<CurrencyField value={record.potongan_dana_pensiun} />}
              />
            </Box>

            <Box mb={2}>
              <StaticText
                title="Potongan Koperasi"
                content={<CurrencyField value={record.potongan_koperasi} />}
              />
            </Box>

            <Box mb={0}>
              <StaticText
                title="Potongan Lainnya"
                content={<CurrencyField value={record.potongan_lainnya} />}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Container>
);

export default ShowView;
