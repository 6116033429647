import ListView from "./ListView";

const RekapReportResource = (permissions) => ({
  name: "reports/employee/rekap",
  list: ListView,
  options: {
    label: "Rekap Karyawan",
  },
});

export default RekapReportResource;
