import PropTypes from "prop-types";
import React from "react";
import { List } from "react-admin";
import CustomEmpty from "./CustomEmpty";
import CustomFilter from "./CustomFilter";
import CustomListActions from "./CustomListActions";

const CustomList = ({
  useImport = false,
  useExport = false,
  children,
  ...props
}) => {
  return (
    <List
      filters={<CustomFilter />}
      empty={<CustomEmpty useImport={useImport} />}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      actions={
        <CustomListActions useImport={useImport} useExport={useExport} />
      }
    >
      {children}
    </List>
  );
};

CustomList.propTypes = {
  useImport: PropTypes.bool,
  useExport: PropTypes.bool,
};

export default CustomList;
