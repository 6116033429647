import { Button, Menu, MenuItem, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Fragment } from "react";
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import { COMPANIES } from ".";
import {
  ConditionalField,
  CustomDateField,
  CustomEmpty,
  CustomExportButton,
  CustomFilter,
  ImportButton,
} from "../../../components";
import { ACCESS_CODES, CONTRACT_REQUEST_STATUS_ARRAY } from "../../../consts";
import DashboardView from "./DashboardView";
import DownloadButton from "./DownloadButton";

const FilterView = (props) => {
  return (
    <CustomFilter {...props}>
      <ReferenceInput
        source="filter_category"
        label="Jenis Kontrak"
        reference="contract/categories"
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="filter_type"
        label="Status Kontrak"
        reference="contract/types"
        alwaysOn
      >
        <SelectInput
          optionText={(record) => `${record?.code} - ${record?.name}`}
        />
      </ReferenceInput>

      <ReferenceInput
        source="filter_location"
        label="Perusahaan"
        reference="contract/locations"
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <SelectInput
        source="filter_status"
        label="Status Request"
        choices={CONTRACT_REQUEST_STATUS_ARRAY}
        alwaysOn
      />
    </CustomFilter>
  );
};

const CreateMoreButton = ({ className }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOnOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <Button
        variant="text"
        onClick={handleOnOpen}
        title="Create Other"
        aria-controls="actions-menu"
        aria-haspopup="true"
        size="small"
        color="primary"
        startIcon={<AddIcon />}
      >
        QUICK CREATE
      </Button>

      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
      >
        {COMPANIES.map((e) => (
          <MenuItem
            key={e.id}
            component={RouterLink}
            to={{
              pathname: "/contract/requests/create",
              state: {
                record: {
                  companyName: e.name,
                  companyAddress: e.address,
                },
              },
            }}
          >
            <span>
              Create <i>{e.label}</i>
            </span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginLeft: theme.spacing(1),
      "&:first-child": {
        marginLeft: 0,
      },
    },
  }),
  {
    name: "ListActionsStyles",
  }
);

const ListActions = ({
  hasCreate = false,
  hasImport = true,
  hasExport = true,
  ...props
}) => {
  const classes = useStyles();
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      {hasCreate && (
        <Fragment>
          <CreateButton className={classes.button} basePath={basePath} />

          <CreateMoreButton className={classes.button} />
        </Fragment>
      )}

      {hasImport && (
        <ImportButton className={classes.button} resource={resource} />
      )}

      {hasExport && (
        <CustomExportButton
          className={classes.button}
          resource={resource}
          filterValues={filterValues}
        />
      )}
    </TopToolbar>
  );
};

const ListView = (props) => {
  const { permissions } = props;

  return (
    <React.Fragment>
      <DashboardView />

      <List
        {...props}
        title="Request Kontrak"
        filters={<FilterView />}
        sort={{ field: "createdAt", order: "desc" }}
        empty={<CustomEmpty />}
        bulkActionButtons={false}
        exporter={false}
        actions={
          <ListActions
            hasImport={![ACCESS_CODES.PMO_BRO].includes(permissions)}
            hasExport
          />
        }
      >
        <Datagrid>
          <ReferenceField
            source="categoryId"
            reference="contract/categories"
            label="Jenis Kontrak"
            link={false}
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>

          <TextField source="code" label="Nomor Kontrak" />

          <FunctionField
            sortBy="empName"
            render={(record) => `${record?.empCode} - ${record?.empName}`}
            label="Nama Karyawan"
          />

          <FunctionField
            sortBy="position"
            render={(record) => `${record?.position} @ ${record?.companyName}`}
            label="Posisi &amp; Perusahaan"
          />

          <CustomDateField source="startDate" label="Awal Kontrak" />

          <CustomDateField source="endDate" label="Akhir Kontrak" />

          <ReferenceField
            source="typeId"
            reference="contract/types"
            label="Status Kontrak"
            link={false}
            sortable={false}
          >
            <TextField source="code" />
          </ReferenceField>

          <SelectField
            source="status"
            label="Status Permintaan"
            choices={CONTRACT_REQUEST_STATUS_ARRAY}
          />

          {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
            <ConditionalField when={(record) => Boolean(record?.file?.url)}>
              <DownloadButton label=" " />
            </ConditionalField>
          )}

          <ConditionalField when={(record) => record.status !== "request"}>
            <ShowButton />
          </ConditionalField>

          {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
            <ConditionalField when={(record) => record.status !== "done"}>
              <EditButton />
            </ConditionalField>
          )}

          {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
            <ConditionalField when={(record) => record.status !== "done"}>
              <DeleteButton mutationMode="optimistic" undoable={false} />
            </ConditionalField>
          )}
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

export default ListView;
