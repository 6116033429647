import ListView from "./ListView";

const PartnershipPresenceResource = (permissions) => ({
  name: "reports/partnership-presences",
  list: ListView,
  options: {
    label: "Absensi Karyawan Partnership",
  },
});

export default PartnershipPresenceResource;
