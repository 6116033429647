import ListView from "./ListView";

const MasukReportResource = (permissions) => ({
  name: "reports/employee/masuk",
  list: ListView,
  options: {
    label: "Rekap Karyawan Masuk",
  },
});

export default MasukReportResource;
