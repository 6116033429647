import { Grid } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import { Fragment } from "react";
import {
  ArrayInput,
  Button,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import { useForm } from "react-final-form";

const VALIDATION = {
  name: [required()],
};
const AdminArrayInput = ({ enableAutoGeneratePin, ...props }) => {
  const form = useForm();
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ scopedFormData, getSource, ...rest }) => {
            return (
              <Fragment>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} lg={8}>
                    <TextInput
                      validate={VALIDATION.name}
                      source={getSource("name")}
                      label="Nama Admin"
                      fullWidth
                    />
                  </Grid>
                  {enableAutoGeneratePin ? (
                    <Fragment>
                      <Grid item xs={10} sm={8} lg={2}>
                        <TextInput
                          label="PIN"
                          source={getSource("pin")}
                          record={scopedFormData}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={2} sm={4} lg={2}>
                        <Button
                          fullWidth
                          size="small"
                          label="Auto Generate PIN"
                          onClick={() =>
                            form.change(
                              getSource("pin"),
                              Math.floor(Math.random() * 1_000_000)
                                .toString()
                                .padStart(6, "0")
                            )
                          }
                        >
                          <Autorenew />
                        </Button>
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default AdminArrayInput;
