import { Grid } from "@material-ui/core";
import React from "react";
import {
  BooleanInput,
  PasswordInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { ACCESS_CODE_ARRAY } from "../../../consts";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";

const VALIDATION = {
  username: [required()],
  namakaryawan: [required()],
  kodekaryawan: [required()],
  unitkerja: [required()],
  emailkaryawan: [required()],
  new_password: [],
  kodeakses: [required()],
  statuskaryawan: [],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <TextInput
              source="username"
              label="Username"
              validate={VALIDATION.username}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={9} lg={10}>
            <TextInput
              source="namakaryawan"
              label="Nama Karyawan"
              validate={VALIDATION.namakaryawan}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextInput
              source="kodekaryawan"
              label="Kode"
              validate={VALIDATION.kodekaryawan}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <TextInput
              source="unitkerja"
              label="Unit Kerja"
              validate={VALIDATION.unitkerja}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <TextInput
              type="email"
              source="emailkaryawan"
              label="Email Karyawan"
              validate={VALIDATION.emailkaryawan}
              fullWidth
            />
          </Grid>
        </Grid>

        <PasswordInput
          source="new_password"
          label="New Password"
          validate={VALIDATION.new_password}
          fullWidth
        />

        <SelectInput
          source="kodeakses"
          label="Level"
          choices={ACCESS_CODE_ARRAY}
          validate={VALIDATION.kodeakses}
          fullWidth
        />

        <EmployeeReferenceInput
          source="emp_id"
          label="Referenced Employee"
          validate={VALIDATION.emp_id}
          fullWidth
        />

        <BooleanInput
          source="statuskaryawan"
          label="Is Active?"
          validate={VALIDATION.statuskaryawan}
        />
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
