import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import lodashFind from "lodash/find";
import PropTypes from "prop-types";
import React from "react";
import { ACTIVITY_DAY_ARRAY, ACTIVITY_TYPE_ARRAY } from "../consts";
import { DateHelper } from "../helpers";
import AvatarField from "../resources/employees/employees/AvatarField";

const useStyles = makeStyles({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  secondaryText: {
    display: "block",
    textAlign: "right",
  },
});

const ActivityReportListItem = ({ record, ...props }) => {
  const classes = useStyles();
  const bullet = <span className={classes.bullet}>•</span>;

  return (
    <ListItem dense {...props}>
      <ListItemIcon>
        <AvatarField record={record.employee} size={40} />
      </ListItemIcon>

      <ListItemText
        primary={record.employee.namakaryawan}
        secondary={record.employee.unitkerja}
      />

      <ListItemSecondaryAction>
        <span className={classes.secondaryText}>
          {lodashFind(ACTIVITY_TYPE_ARRAY, (e) => e.id === record.type).name}
          {record.flag_penugasan_khusus ? "+" : ""}
          {bullet}
          {lodashFind(ACTIVITY_DAY_ARRAY, (e) => e.id === record.day).name}

          <br />
          {DateHelper.format(
            record.created_at,
            "YYYY-MM-DD HH:mm:ss",
            "DD/MM/YYYY HH:mm:ss"
          )}
        </span>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ActivityReportListItem.propTypes = {
  record: PropTypes.object.isRequired,
};

export default ActivityReportListItem;
