import { ACCESS_CODES } from "../../../consts";
import balances from "./balances";
import histories from "./histories";

const LeaveResources = (permissions) => [
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? balances(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? histories(permissions)
    : null,
];

export default LeaveResources;
