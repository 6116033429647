import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { Datagrid, SelectInput } from "react-admin";
import NumberFormat from "react-number-format";
import { CustomFilter, CustomList } from "../../../../components";
import { MONTH_ARRAY, YEAR_ARRAY } from "../../../../consts";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
});

const LocationField = ({ record = {} }) => {
  const classes = useStyles();

  return (
    <Typography
      className={record["id"] === "Total" ? classes.bold : null}
      align={record["id"] === "Total" ? "right" : "left"}
      display="block"
      variant="body2"
    >
      {record["id"]}
    </Typography>
  );
};

const ValueField = ({ source, record = {} }) => {
  const classes = useStyles();

  return (
    <NumberFormat
      value={record[source]}
      displayType="text"
      thousandSeparator={true}
      decimalScale={0}
      fixedDecimalScale
      renderText={(formattedValue) => (
        <Typography
          className={record["id"] === "Total" ? classes.bold : null}
          align="right"
          display="block"
          variant="body2"
        >
          {formattedValue === "0" ? "-" : formattedValue}
        </Typography>
      )}
    />
  );
};

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <SelectInput
      source="filter_month"
      label="Bulan"
      optionText="name"
      choices={MONTH_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_year"
      label="Tahun"
      optionText="name"
      choices={YEAR_ARRAY}
      allowEmpty={false}
      alwaysOn
    />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Rekap Status Karyawan"
    filters={<FilterView />}
    sort={{ field: "id", order: "ASC" }}
    filterDefaultValues={{
      filter_month: moment().month() + 1,
      filter_year: moment().year(),
    }}
    pagination={false}
    useExport
  >
    <Datagrid>
      <LocationField label="Lokasi Kerja" sortable={false} />

      <ValueField
        source="total_berhenti"
        label="Berhenti"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_mitra"
        label="Mitra"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_pkwt"
        label="PKWT"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_tetap"
        label="Tetap"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_telexindo"
        label="Kontrak: Telexindo"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_aruwa"
        label="Kontrak: Aruwa"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_aktif"
        label="Aktif"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_mitra_pkwt"
        label="Mitra/PKWT"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_akan_berakhir"
        label="Akan Berakhir"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_akan_perpanjang"
        label="Akan Diperpanjang"
        sortable={false}
        textAlign="right"
      />
    </Datagrid>
  </CustomList>
);

export default ListView;
