import React from "react";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Button, useRecordContext } from "react-admin";

const DownloadButton = (props) => {
  const record = useRecordContext(props);

  return (
    <Button component="a" href={record.file.url} label="Unduh File" {...props}>
      <DownloadIcon />
    </Button>
  );
};

export default DownloadButton;
