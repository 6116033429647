import { Typography } from "@material-ui/core";
import React from "react";
import { Datagrid, DeleteButton, EditButton } from "react-admin";
import { CustomList } from "../../../components";

const NameField = ({ record = {} }) => (
  <>
    <Typography variant="body1">{record.name}</Typography>
    <Typography variant="caption" color="textSecondary">
      {record.code}
    </Typography>
  </>
);

const ListView = (props) => {
  return (
    <CustomList {...props} title="Application Features" useExport useImport>
      <Datagrid>
        <NameField label="Feature" sortBy="name" />

        <EditButton />
        <DeleteButton mutationMode="optimistic" undoable={false} />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
