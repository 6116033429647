import { Grid } from "@material-ui/core";
import { required } from "ra-core";
import { SimpleForm, TextInput } from "ra-ui-materialui";
import React from "react";

const VALIDATION = {
  code: [required()],
  name: [required()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={3} lg={2}>
            <TextInput
              source="code"
              label="Inisial"
              validate={VALIDATION.code}
              fullWidth
            />
          </Grid>

          <Grid item xs={9} lg={10}>
            <TextInput
              source="name"
              label="Keterangan"
              validate={VALIDATION.name}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
