import { Datagrid, DeleteButton, EditButton, TextField } from "react-admin";
import React from "react";
import { CustomList } from "../../../components";

const ListView = (props) => {
  return (
    <CustomList {...props} title="Pelanggan" useImport useExport>
      <Datagrid>
        <TextField source="fullName" label="Nama Pelanggan" />

        <TextField source="companyName" label="Perusahaan" />
        <EditButton />
        <DeleteButton mutationMode="optimistic" undoable={false} />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
