import * as React from "react";
import { Datagrid, FunctionField, SelectField, SelectInput } from "react-admin";
import {
  CustomDateField,
  CustomFilter,
  CustomList,
  QuickFilter,
} from "../../../components";
import { PLATFORM_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";
import DashboardView from "./DashboardView";

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <EmployeeReferenceInput
      source="filter_employee"
      label="Employee"
      alwaysOn
    />

    <SelectInput
      source="filter_platform"
      label="Platform"
      choices={PLATFORM_ARRAY}
      alwaysOn
    />

    <QuickFilter
      source="filter_is_new_format"
      label="New Token Format"
      defaultValue={true}
    />
  </CustomFilter>
);

const ListView = (props) => (
  <React.Fragment>
    <DashboardView />

    <CustomList
      {...props}
      title="Devices"
      filters={<FilterView />}
      sort={{ field: "updated_time", order: "DESC" }}
    >
      <Datagrid>
        <EmployeeReferenceField source="parent_id" label="Employee" avatar />

        <SelectField
          source="platform"
          label="Platform"
          choices={PLATFORM_ARRAY}
          emptyText="Legacy"
        />

        <FunctionField
          sortBy="version_number"
          label="Version"
          render={(record) =>
            record?.version_name && record?.version_number
              ? `${record?.version_name} (${record?.version_number})`
              : null
          }
        />

        <CustomDateField
          source="created_time"
          destFormat="DD/MM/YYYY HH:mm:ss"
          sourceFormat="YYYY-MM-DD HH:mm:ss"
          label="Created At"
        />

        <CustomDateField
          source="updated_time"
          destFormat="DD/MM/YYYY HH:mm:ss"
          sourceFormat="YYYY-MM-DD HH:mm:ss"
          label="Updated At"
        />
      </Datagrid>
    </CustomList>
  </React.Fragment>
);

export default ListView;
