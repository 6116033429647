import { ACCESS_CODES } from "../../consts";
import activities from "./activities";
import documents from "./documents";
import employees from "./employees";
import leaves from "./leaves";
import locations from "./locations";
import payslips from "./payslips";
import performances from "./performances";
import presences from "./presences";
import status from "./status";
import units from "./units";
import overtimes from "./overtimes";
import devices from "./devices";
import RequestResource from "./requests";

const EmployeeResources = (permissions) => [
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? employees(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? documents(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? performances(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? payslips(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? presences(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? activities(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? overtimes(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? RequestResource(permissions)
    : null,
  ...leaves(permissions),
  locations(permissions),
  units(permissions),
  status(permissions),
  devices(permissions),
];

export default EmployeeResources;
