import {
  CircularProgress,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import {
  Datagrid,
  SelectInput,
  useDataProvider,
  useListContext,
  useNotify,
} from "react-admin";
import { useFormState } from "react-final-form";
import NumberFormat from "react-number-format";
import { CustomFilter, CustomList } from "../../../../components";
import { createWeekChoices, YEAR_ARRAY } from "../../../../consts";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
});

const LocationField = ({ record = {} }) => {
  const classes = useStyles();

  return (
    <Typography
      className={record["id"] === "Total" ? classes.bold : null}
      align={record["id"] === "Total" ? "right" : "left"}
      display="block"
      variant="body2"
    >
      {record["id"]}
    </Typography>
  );
};

const ValueField = ({ resource, source, record = {} }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { filterValues } = useListContext();
  const { q, ...filters } = filterValues;
  const [isLoading, setLoading] = React.useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();

    if (isLoading) return;

    setLoading(true);

    dataProvider
      .export(resource, {
        filter: {
          ...filters,
          mode: source,
          filter_location: record["id"] === "Total" ? null : record["id"],
        },
      })
      .then(({ data }) => {
        notify("Downloading...", "info");

        window.open(data.url);
      })
      .catch((error) => {
        notify(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const children = (formattedValue) => (
    <Typography
      className={record["id"] === "Total" ? classes.bold : null}
      align="right"
      display="block"
      variant="body2"
    >
      {formattedValue === "0" ? "-" : formattedValue}
    </Typography>
  );

  return (
    <NumberFormat
      value={record[source]}
      displayType="text"
      thousandSeparator={true}
      decimalScale={0}
      fixedDecimalScale
      renderText={(formattedValue) => {
        return [
          "total_awal",
          "total_tetap",
          "total_pkwt",
          "total_mitra",
          "total_masuk_new",
          "total_masuk_replacement",
          "total_keluar",
        ].includes(source) && formattedValue !== "0" ? (
          isLoading ? (
            <CircularProgress size={16} />
          ) : (
            <Link href="#" onClick={handleOnClick}>
              {children(formattedValue)}
            </Link>
          )
        ) : (
          children(formattedValue)
        );
      }}
    />
  );
};

const RetensiField = ({ source, record = {} }) => {
  const classes = useStyles();

  return (
    <NumberFormat
      value={record.retensi}
      displayType="text"
      thousandSeparator={true}
      decimalScale={2}
      suffix="%"
      fixedDecimalScale
      renderText={(formattedValue) => (
        <Typography
          className={record["id"] === "Total" ? classes.bold : null}
          align="right"
          display="block"
          variant="body2"
        >
          {formattedValue}
        </Typography>
      )}
    />
  );
};

const WeekInput = (props) => {
  const { values } = useFormState();

  return (
    <SelectInput
      optionText="name"
      choices={createWeekChoices(values.filter_year)}
      {...props}
    />
  );
};

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <SelectInput
      source="filter_year"
      label="Tahun"
      optionText="name"
      choices={YEAR_ARRAY}
      allowEmpty={false}
      alwaysOn
    />

    <WeekInput source="filter_week" label="Minggu" alwaysOn />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Rekap Karyawan (Weekly)"
    filters={<FilterView />}
    sort={{ field: "id", order: "ASC" }}
    filterDefaultValues={{
      filter_week: moment().week(),
      filter_year: moment().year(),
    }}
    pagination={false}
    useExport
  >
    <Datagrid>
      <LocationField label="Lokasi Kerja" sortable={false} />

      <ValueField
        source="total_awal"
        label="Awal"
        sortable={false}
        textAlign="right"
      />
      <ValueField
        source="total_tetap"
        label="Tetap"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_pkwt"
        label="PKWT"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_mitra"
        label="Mitra"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_masuk_new"
        label="New"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_masuk_replacement"
        label="Replacement"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_keluar"
        label="Keluar"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="jumlah"
        label="Total"
        sortable={false}
        textAlign="right"
      />

      <RetensiField label="Retensi (%)" sortable={false} textAlign="right" />
    </Datagrid>
  </CustomList>
);

export default ListView;
