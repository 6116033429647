import React from "react";
import {
  FileField,
  FileInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const fileValidation = (value, allValues) => {
  if (!allValues?.id) {
    return required()(value, allValues);
  }

  return [];
};

const VALIDATION = {
  name: [required()],
  templateFile: [fileValidation],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <TextInput
          source="name"
          label="Nama"
          validate={VALIDATION.name}
          fullWidth
        />

        <FileInput
          source="templateFile"
          label="File Template"
          accept=".docx"
          validate={VALIDATION.templateFile}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
