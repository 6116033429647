import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import React from "react";
import {
  DateInput,
  FormWithRedirect,
  required,
  SaveButton,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
} from "react-admin";
import { CustomDateField } from "../../../components";

const FormResignDialog = ({ open, onClose, record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleSubmit = (values) => {
    const { tgl_berhenti, alasanberhenti } = values;

    dataProvider
      .update("employees/employees", {
        id: record.id,
        data: {
          statuskerja: "BERHENTI",
          tgl_berhenti,
          alasanberhenti,
          _ref: "form-resign",
        },
      })
      .then(() => {
        notify("Form efektif keluar berhasil disimpan", "info");

        onClose();
      })
      .catch((error) => {
        notify(error.message, "error");
      });
  };

  if (!record?.id) {
    return <div></div>;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label={`Form Efektif Keluar #${record.id}`}
    >
      <DialogTitle>{`Form Efektif Keluar #${record.id}`}</DialogTitle>

      <FormWithRedirect
        resource="employees/employees"
        record={record}
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <>
            <GridShowLayout
              record={record}
              basePath="/employees/employees"
              resource="employees/employees"
            >
              <RaGrid container spacing={2}>
                <RaGrid item xs={4}>
                  <TextField source="nomorkaryawan" label="NIK" />
                </RaGrid>

                <RaGrid item xs={8}>
                  <TextField source="namakaryawan" label="Nama Karyawan" />
                </RaGrid>
              </RaGrid>

              <RaGrid container spacing={2}>
                <RaGrid item xs={4}>
                  <TextField source="statuskerja" label="Status Kerja" />
                </RaGrid>

                <RaGrid item xs={4}>
                  <CustomDateField
                    source="tglawalkontrak"
                    label="Awal Kontrak"
                    addLabel
                  />
                </RaGrid>

                <RaGrid item xs={4}>
                  <CustomDateField
                    source="tglakhirkontrak"
                    label="Akhir Kontrak"
                    addLabel
                  />
                </RaGrid>
              </RaGrid>

              <DateInput
                source="tgl_berhenti"
                label="Tanggal Berhenti"
                validate={[required()]}
              />

              <TextInput
                source="alasanberhenti"
                label="Alasan Berhenti"
                validate={[required()]}
                fullWidth
              />
            </GridShowLayout>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={onClose} />

              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                label="Update"
                saving={saving}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

FormResignDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default FormResignDialog;
