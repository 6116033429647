import { ACCESS_CODES } from "../../../consts";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";
import ShowView from "./ShowView";

export const COMPANIES = [
  {
    id: "company-0",
    name: "PT. BFI FINANCE INDONESIA TBK",
    label: "PT. BFI FINANCE INDONESIA TBK",
    address:
      "BFI TOWER SUNBURST CBD LOT 1.2 JL. KAPTEN SOEBIJANTO DJOJOHADIKUSUMO, BSD CITY, KOTA TANGERANG SELATAN 15322",
  },
];

const RequestResource = (permissions) => ({
  name: "contract/requests",
  list: ListView,
  show: ShowView,
  create: ![ACCESS_CODES.PMO_BRO].includes(permissions) ? CreateView : null,
  edit: ![ACCESS_CODES.PMO_BRO].includes(permissions) ? EditView : null,
  options: {
    label: "Request Kontrak",
  },
});

export default RequestResource;
