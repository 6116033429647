import { Grid } from "@material-ui/core";
import { required } from "ra-core";
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { ConditionalFormDataConsumer } from "../../../components";

const VALIDATION = {
  code: [required()],
  date: [required()],
  jenisSuratId: [required()],
  description: [required()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <DateInput
              source="date"
              label="Tanggal"
              validate={VALIDATION.date}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <ReferenceInput
              source="jenisSuratId"
              reference="master/jenis-surat"
              label="Jenis Surat"
              validate={VALIDATION.jenisSuratId}
              fullWidth
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </Grid>

          <ConditionalFormDataConsumer
            when={(formData) => Boolean(formData?.id)}
          >
            {() => (
              <Grid item xs={12} lg={5}>
                <TextInput
                  source="code"
                  label="Nomor Surat"
                  validate={VALIDATION.code}
                  fullWidth
                />
              </Grid>
            )}
          </ConditionalFormDataConsumer>
        </Grid>

        <TextInput
          source="description"
          label="Keterangan"
          validate={VALIDATION.description}
          fullWidth
        />
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
