import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useGetList } from "ra-core";
import React from "react";
import NumberFormat from "react-number-format";
import { StaticText } from "../../../components";

const CurrencyField = ({ value = 0, prefix = "IDR ", bold = false }) => (
  <NumberFormat
    value={value}
    displayType="text"
    thousandSeparator={true}
    prefix={prefix}
    decimalScale={2}
    fixedDecimalScale
    renderText={bold ? (value) => <b>{value}</b> : null}
  />
);

const ExpandView = ({ record = {} }) => {
  const { data: details, ids: detailIds } = useGetList(
    "invoice/details",
    { page: 1, perPage: -1 },
    { field: "id", order: "ASC" },
    { filter_detail: record && record?.id }
  );

  return (
    <Container>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography variant="subtitle2">Rincian</Typography>
          </Box>

          <Box mb={2}>
            <StaticText title="PIC Perusahaan" content={record?.customerName} />
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <StaticText
                  title="Alamat Pengiriman"
                  content={record?.address}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticText
                  title="Nama Penerima"
                  content={record?.receiverName}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticText
                  title="No Tep. Penerima"
                  content={record?.receiverPhone}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell scope="row">Deskripsi</TableCell>
                    <TableCell scope="row">Qty</TableCell>
                    <TableCell scope="row">Harga</TableCell>
                    <TableCell scope="row">PPN</TableCell>
                    <TableCell scope="row">Diskon</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailIds.map((detailId) => (
                    <TableRow key={detailId}>
                      <TableCell component="td" scope="row">
                        {details[detailId]?.description}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {details[detailId]?.qty}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <CurrencyField value={details[detailId]?.amount} />
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <CurrencyField value={details[detailId]?.ppn} />
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <CurrencyField value={details[detailId]?.discounts} />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }} colSpan={3} />
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="body2">
                        <b>Total</b>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <CurrencyField value={record?.total_amount} bold />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }} colSpan={3} />
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="body2">
                        <b>Total PPN</b>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <CurrencyField value={record?.total_ppn} bold />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }} colSpan={3} />
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="body2">
                        <b>Total Diskon</b>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <CurrencyField value={record?.total_discounts} bold />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }} colSpan={3} />
                    <TableCell style={{ borderBottom: "none" }}>
                      <Typography variant="body2">
                        <b>Total Harga</b>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <CurrencyField value={record?.final_amount} bold />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ExpandView;
