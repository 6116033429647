import EventIcon from "@material-ui/icons/Event";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const EventResource = (permissions) => ({
  name: "events",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: EventIcon,
  options: {
    label: "Events",
  },
});

export default EventResource;
