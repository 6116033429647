import moment from "moment";
import {
  Datagrid,
  DateInput,
  DeleteButton,
  EditButton,
  SelectField,
  SelectInput,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { CustomDateField, CustomFilter, CustomList } from "../../components";
import { EVENT_STATUS_ARRAY, EVENT_VISIBILITY_ARRAY } from "../../consts";
import ExpandView from "./ExpandView";

const FilterView = (props) => {
  return (
    <CustomFilter {...props}>
      <DateInput source="filter_start_date" label="Tanggal Awal" alwaysOn />

      <DateInput source="filter_end_date" label="Tanggal Akhir" alwaysOn />

      <SelectInput
        source="filter_status"
        label="Status"
        choices={EVENT_STATUS_ARRAY}
        alwaysOn
      />
    </CustomFilter>
  );
};

const ListView = (props) => {
  return (
    <CustomList
      {...props}
      title="Events"
      filters={<FilterView />}
      sort={{ field: "start_time", order: "DESC" }}
      filterDefaultValues={{
        filter_start_date: moment().startOf("month").format("YYYY-MM-DD"),
        filter_end_date: moment().endOf("month").format("YYYY-MM-DD"),
      }}
    >
      <Datagrid expand={<ExpandView />}>
        <TextField source="name" label="Judul" />

        <CustomDateField
          source="start_time"
          sourceFormat="YYYY-MM-DD HH:mm:ss"
          destFormat="DD/MM/YYYY HH:mm:ss"
          label="Mulai"
        />

        <CustomDateField
          source="end_time"
          sourceFormat="YYYY-MM-DD HH:mm:ss"
          destFormat="DD/MM/YYYY HH:mm:ss"
          label="Selesai"
        />

        <SelectField
          source="visibility"
          label="Target"
          choices={EVENT_VISIBILITY_ARRAY}
        />

        <SelectField
          source="status"
          label="Status"
          choices={EVENT_STATUS_ARRAY}
        />

        <EditButton />

        <DeleteButton mutationMode="optimistic" undoable={false} />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
