import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useGetList } from "ra-core";
import { ImageField } from "ra-ui-materialui";
import React from "react";
import { StaticText } from "../../../components";
import { DateHelper } from "../../../helpers";

const ExpandView = ({ record = {} }) => {
  const { data: attachments, ids: attachmentIds } = useGetList(
    "support/attachments",
    { page: 1, perPage: 100 },
    { field: "createdAt", order: "ASC" },
    { filter_support: record && record.id }
  );

  return (
    <Container>
      <StaticText title="Pesan" content={record.message} />

      {attachmentIds && attachmentIds.length > 0 && (
        <Box mt={2}>
          <ImageField
            record={{
              attachments: attachmentIds.map(
                (attachmentId) => attachments[attachmentId]
              ),
            }}
            source="attachments"
            src="file.view_url"
            title="file.name"
          />
        </Box>
      )}

      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="2">Timeline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(record.messages_array || []).map((message) => (
                <TableRow key={message.id}>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {message.message}
                  </TableCell>

                  <TableCell align="right">
                    {DateHelper.format(
                      message.created_time,
                      "YYYY-MM-DD HH:mm:ss",
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ExpandView;
