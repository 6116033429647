import { Card, CardContent, List, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ActivityReportDialog from "./ActivityReportDialog";
import ActivityReportListItem from "./ActivityReportListItem";
import lodashLast from "lodash/last";

const ActivityReportList = ({ data = [] }) => {
  const [record, setRecord] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ActivityReportDialog
        open={isOpen}
        record={record}
        onClose={handleClose}
      />

      <Card>
        {data.length === 0 && (
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Belum ada activity report yang tersubmit hari ini.
            </Typography>
          </CardContent>
        )}

        {data.length > 0 && (
          <List dense>
            {data.map((record) => (
              <ActivityReportListItem
                key={record.id}
                record={record}
                button
                divider={record !== lodashLast(data)}
                onClick={() => {
                  setRecord(record);
                  setOpen(true);
                }}
              />
            ))}
          </List>
        )}
      </Card>
    </React.Fragment>
  );
};

ActivityReportList.propTypes = {
  data: PropTypes.array,
};

export default ActivityReportList;
