import { required } from "ra-core";
import { SimpleForm, TextInput } from "ra-ui-materialui";
import React from "react";

const VALIDATION = {
  accountName: [required()],
  accountNumber: [required()],
  bankName: [required()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <TextInput
          source="accountName"
          label="Nama Akun"
          validate={VALIDATION.accountName}
          fullWidth
        />
        <TextInput
          source="bankName"
          label="Nama Bank"
          validate={VALIDATION.bankName}
          fullWidth
        />
        <TextInput
          source="accountNumber"
          label="No. Rekening"
          validate={VALIDATION.accountNumber}
          fullWidth
        />
        <TextInput source="branchLocation" label="Cabang" fullWidth />
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
