import { Grid } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  choices,
} from "react-admin";
import { ConditionalFormDataConsumer } from "../../../components";
import {
  NOTIFICATION_LINK_TYPE,
  NOTIFICATION_RECEIVER_TYPE_ARRAY,
  NOTIFICATION_SENDER_TYPE_ARRAY,
  NOTIFICATION_STATUS_ARRAY,
  NOTIFICATION_VISIBLE_TYPE_ARRAY,
} from "../../../consts";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";

const VALIDATION = {
  sender_type: [],
  sender_user_id: [required()],
  subject: [required()],
  message: [required()],
  receiver_type: [],
  status: [],
  visible_type: [],
  visible_at: [required()],
  link_type: [required(), choices(NOTIFICATION_LINK_TYPE.map((e) => e.id))],
  link: [required()],
  receiver_locations_array: {
    name: [required()],
  },
  receiver_users_array: {
    employee_id: [required()],
  },
};

const initialValues = {
  sender_type: "system",
  receiver_type: "all",
  status: "draft",
  visible_type: "immediate",
};

const FormView = (props) => {
  return (
    <SimpleForm {...props} initialValues={initialValues}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <SelectInput
              source="sender_type"
              label="Sender Type"
              choices={NOTIFICATION_SENDER_TYPE_ARRAY}
              validate={VALIDATION.sender_type}
              fullWidth
            />
          </Grid>

          <FormDataConsumer>
            {({ formData }) =>
              formData?.sender_type === "user" && (
                <Grid item xs={12} md={8} lg={9}>
                  <EmployeeReferenceInput
                    source="sender_user_id"
                    label="Sender Employee"
                    validate={VALIDATION.sender_user_id}
                    fullWidth
                  />
                </Grid>
              )
            }
          </FormDataConsumer>
        </Grid>

        <TextInput
          source="subject"
          label="Subject"
          validate={VALIDATION.subject}
          fullWidth
        />

        <TextInput
          source="message"
          label="Message"
          validate={VALIDATION.message}
          multiline
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <SelectInput
              source="link_type"
              label="Link Type"
              choices={NOTIFICATION_LINK_TYPE}
              validate={VALIDATION.link_type}
              fullWidth
            />
          </Grid>

          <FormDataConsumer>
            {({ formData }) =>
              NOTIFICATION_LINK_TYPE.filter((e) => e.id !== "none")
                .map((e) => e.id)
                .includes(formData?.link_type) && (
                <Grid item xs={12} lg={9}>
                  <TextInput
                    source="link"
                    label="Link"
                    validate={VALIDATION.link}
                    fullWidth
                  />
                </Grid>
              )
            }
          </FormDataConsumer>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <SelectInput
              source="receiver_type"
              label="Receiver"
              choices={NOTIFICATION_RECEIVER_TYPE_ARRAY}
              validate={VALIDATION.receiver_type}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <SelectInput
              source="status"
              label="Status"
              choices={NOTIFICATION_STATUS_ARRAY}
              validate={VALIDATION.status}
              fullWidth
            />
          </Grid>

          <FormDataConsumer>
            {({ formData }) =>
              formData?.status === "public" && (
                <Grid item xs={12} lg={3}>
                  <SelectInput
                    source="visible_type"
                    label="Visibility"
                    choices={NOTIFICATION_VISIBLE_TYPE_ARRAY}
                    validate={VALIDATION.visible_type}
                    fullWidth
                  />
                </Grid>
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }) =>
              formData?.visible_type === "after" && (
                <Grid item xs={12} lg={3}>
                  <TextInput
                    source="visible_at"
                    label="Visible After"
                    placeholder="YYYY-MM-DD HH:mm:ss"
                    validate={VALIDATION.visible_at}
                    fullWidth
                  />
                </Grid>
              )
            }
          </FormDataConsumer>
        </Grid>

        <ConditionalFormDataConsumer
          when={(formData) => formData?.receiver_type === "location"}
        >
          {(FormDataProps) => (
            <ArrayInput
              source="receiver_locations_array"
              label="Selected Employee Work Location"
              {...FormDataProps}
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ getSource }) => (
                    <React.Fragment>
                      <ReferenceInput
                        source={getSource("name")}
                        label="Work Location"
                        reference="employees/locations"
                        validate={VALIDATION.receiver_locations_array.name}
                        fullWidth
                      >
                        <AutocompleteInput optionText="name" />
                      </ReferenceInput>
                    </React.Fragment>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </ConditionalFormDataConsumer>

        <ConditionalFormDataConsumer
          when={(formData) => formData?.receiver_type === "user"}
        >
          {(FormDataProps) => (
            <ArrayInput
              source="receiver_users_array"
              label="Selected Employees"
              {...FormDataProps}
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ getSource }) => (
                    <React.Fragment>
                      <EmployeeReferenceInput
                        source={getSource("employee_id")}
                        label="Employee"
                        validate={VALIDATION.receiver_users_array.employee_id}
                        fullWidth
                      />
                    </React.Fragment>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </ConditionalFormDataConsumer>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
