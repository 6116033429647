import ListView from "./ListView";

const SummaryActivityReportResource = (permissions) => ({
  name: "reports/employee/summary-activity-report",
  list: ListView,
  options: {
    label: "Summary Activity Report",
  },
});

export default SummaryActivityReportResource;
