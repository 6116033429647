import React from "react";
import { Avatar } from "@material-ui/core";

const AvatarField = ({ record, size = 48, className }) => {
  return (
    <Avatar
      src={record?.avatar?.view_url}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    >
      {record.namakaryawan
        .split(" ")
        .map((part) => part.substr(0, 1))
        .slice(0, 2)
        .join("")}
    </Avatar>
  );
};

export default AvatarField;
