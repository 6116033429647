import { Grid } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import { BooleanInput, required, SimpleForm, TextInput } from "react-admin";

const VALIDATION = {
  code: [required()],
  build_number: [required()],
  description: [required()],
  full_changelog: [],
  allow_skip: [],
};

const initialValues = {
  allow_skip: false,
}

const FormView = (props) => {
  return (
    <SimpleForm {...props} initialValues={initialValues}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3} lg={2}>
            <TextInput
              source="code"
              label="Version Code"
              validate={VALIDATION.code}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} md={3} lg={2}>
            <TextInput
              source="build_number"
              label="Build Number"
              validate={VALIDATION.build_number}
              fullWidth
            />
          </Grid>
        </Grid>

        <TextInput
          source="description"
          label="Changelog"
          validate={VALIDATION.description}
          multiline
          fullWidth
        />

        <RichTextInput
          source="full_changelog"
          label="Full Changelog"
          validate={VALIDATION.full_changelog}
          fullWidth
        />

        <BooleanInput
          source="allow_skip"
          label="Skippable"
          validate={VALIDATION.allow_skip}
        />
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
