import { Datagrid, DeleteButton, EditButton, TextField } from "react-admin";
import React from "react";
import { CustomList } from "../../../components";

const ListView = (props) => {
  return (
    <CustomList {...props} title="Pelanggan" useImport useExport>
      <Datagrid>
        <TextField source="accountName" label="Nama Akun" />
        <TextField source="accountNumber" label="No. Rekening" />
        <TextField source="bankName" label="Bank" />
        <TextField source="branchLocation" label="Cabang" />
        <EditButton />
        <DeleteButton mutationMode="optimistic" undoable={false} />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
