import moment from "moment";

export const DateHelper = {
  firstDayOfMonth: () => moment().startOf("month").format("YYYY-MM-DD"),
  lastDayOfMonth: () => moment().endOf("month").format("YYYY-MM-DD"),
  format: (input, sourceFormat, destFormat) => {
    const date = moment(input, sourceFormat);

    return date.isValid() ? date.format(destFormat) : "";
  },
  sanitize: (input) => {
    const date = moment(input);

    return date.isValid() ? date.toDate() : null;
  },
};

export const NumberHelper = {
  normalise: (value, min = 0, max = 100) => ((value - min) * 100) / (max - min),
};

export const FileHelper = {
  toBase64: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;

      reader.readAsDataURL(file.rawFile);
    });
  },
};
