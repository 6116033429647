import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  BooleanField,
  FunctionField,
  NumberField,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";
import NumberFormat from "react-number-format";
import { REMUNERATION_TYPE_ARRAY, WORK_LOCATION_ARRAY } from "..";
import {
  ConditionalField,
  CustomDateField,
  StaticText,
} from "../../../../components";
import { GENDER_ARRAY, MARITAL_ARRAY } from "../../../../consts";
import { EMPLOYEE_JOIN_TYPE_ARRAY } from "../../employees";

const CurrencyField = ({ value = 0, prefix = "IDR " }) =>
  value === null ? (
    "-"
  ) : (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
      prefix={prefix}
      decimalScale={2}
      fixedDecimalScale
    />
  );

const ExpandPanel = (props) => {
  const record = useRecordContext(props);
  const transformedRecord = {
    gender: record?.gender === "m" ? "L" : record?.gender === "f" ? "P" : null,
    marital:
      record?.marital === "TK0"
        ? "TK"
        : record?.marital === "K0"
        ? "K"
        : record?.marital,
  };

  return (
    <Container>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography variant="subtitle2">Data Pribadi</Typography>
          </Box>

          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <StaticText
                  title="Nama Lengkap"
                  content={<TextField record={record} source="name" />}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nama Panggilan"
                  content={<TextField record={record} source="alias" />}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Alamat Email"
                  content={<TextField record={record} source="email" />}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor Telpon"
                  content={<TextField record={record} source="phone" />}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Tempat Lahir"
                  content={<TextField record={record} source="birthPlace" />}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Tanggal Lahir"
                  content={
                    <CustomDateField record={record} source="birthDate" />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Jenis Kelamin"
                  content={
                    <SelectField
                      record={transformedRecord}
                      source="gender"
                      choices={GENDER_ARRAY}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Status Pernikahan"
                  content={
                    <SelectField
                      record={transformedRecord}
                      source="marital"
                      choices={MARITAL_ARRAY}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <StaticText
                  title="Nama Sekolah/Universitas/Kursus"
                  content={
                    <TextField record={record} source="education_school" />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <StaticText
                  title="Tingkat"
                  content={<TextField record={record} source="education" />}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <StaticText
                  title="Jurusan/Bidang"
                  content={
                    <TextField record={record} source="education_major" />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <StaticText
                  title="Tahun Lulus"
                  content={
                    <NumberField record={record} source="education_year" />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <StaticText
                  title="Nilai Akhir"
                  content={
                    <NumberField record={record} source="education_score" />
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <StaticText
              title="Alamat Domisili"
              content={<TextField record={record} source="domAddress" />}
            />
          </Box>

          <StaticText
            title="Alamat KTP"
            content={<TextField record={record} source="ktpAddress" />}
          />
        </CardContent>
      </Card>

      <Box mt={1}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">Dokumen</Typography>
            </Box>

            <Box mb={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <StaticText
                    title="Nomor KTP"
                    content={<TextField record={record} source="ktpCode" />}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <StaticText
                    title="Nomor Kartu Keluarga"
                    content={
                      <TextField
                        record={record}
                        source="kkCode"
                        emptyText="-"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor NPWP"
                  content={
                    <TextField
                      record={record}
                      source="npwpCode"
                      emptyText="-"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor BPJS Kesehatan"
                  content={
                    <TextField
                      record={record}
                      source="bpjsKsCode"
                      emptyText="-"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor BPJS Ketenagakerjaan"
                  content={
                    <TextField
                      record={record}
                      source="bpjsTkCode"
                      emptyText="-"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box mt={1}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">FPTK</Typography>
            </Box>

            <Box mb={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <StaticText
                    title="Nomor FPTK"
                    content={
                      <TextField
                        record={record}
                        source="fptkCode"
                        emptyText="-"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <StaticText
                    title="Posisi"
                    content={
                      <TextField
                        record={record}
                        source="workPosition"
                        emptyText="-"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <StaticText
                    title="Penempatan"
                    content={
                      <>
                        <SelectField
                          record={record}
                          source="workLocation"
                          choices={WORK_LOCATION_ARRAY}
                          emptyText="-"
                        />

                        <ConditionalField
                          record={record}
                          when={(record) => record.workLocation === "client"}
                        >
                          <FunctionField
                            record={record}
                            render={(record) =>
                              ` (${record.workLocationOther})`
                            }
                          />
                        </ConditionalField>
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mb={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <StaticText
                    title="Masa Kerja"
                    content={
                      !!record.workStartDate && !!record.workStartDate ? (
                        <>
                          <CustomDateField
                            record={record}
                            source="workStartDate"
                          />
                          {" s.d "}
                          <CustomDateField
                            record={record}
                            source="workEndDate"
                          />
                        </>
                      ) : (
                        "-"
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <StaticText
                    title="Remunerasi"
                    content={
                      <>
                        <SelectField
                          record={record}
                          source="remunerationType"
                          choices={REMUNERATION_TYPE_ARRAY}
                          emptyText="-"
                        />

                        <ConditionalField
                          record={record}
                          when={(record) =>
                            record.remunerationType === "custom"
                          }
                        >
                          <FunctionField
                            record={record}
                            render={(record) => (
                              <>
                                {" ("}
                                <CurrencyField
                                  value={record.remunerationOther}
                                />
                                {")"}
                              </>
                            )}
                          />
                        </ConditionalField>
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Box mb={2}>
                  <StaticText
                    title="Tunjangan Jabatan"
                    content={<CurrencyField value={record.tunjanganJabatan} />}
                  />
                </Box>

                <Box mb={2}>
                  <StaticText
                    title="Tunjangan Kehadiran"
                    content={
                      <CurrencyField value={record.tunjanganKehadiran} />
                    }
                  />
                </Box>

                <Box mb={2}>
                  <StaticText
                    title="Tunjangan Transport"
                    content={
                      <CurrencyField value={record.tunjanganTransport} />
                    }
                  />
                </Box>

                <StaticText
                  title="Tunjangan Makan"
                  content={<CurrencyField value={record.tunjanganMakan} />}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Box mb={2}>
                  <StaticText
                    title="Benefit BPJS Kesehatan"
                    content={
                      <BooleanField record={record} source="benefitBpjsKs" />
                    }
                  />
                </Box>

                <Box mb={2}>
                  <StaticText
                    title="Benefit BPJS Ketenagakerjaan"
                    content={
                      <BooleanField record={record} source="benefitBpjsTk" />
                    }
                  />
                </Box>

                <StaticText
                  title="Benefit BPJS Pensiun"
                  content={
                    <BooleanField record={record} source="benefitBpjsPensiun" />
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      {Boolean(record?.employee) && (
        <Box mt={1}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography variant="subtitle2">Kontrak Kerja</Typography>
              </Box>

              <Box mb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <StaticText
                      title="NIK"
                      content={
                        <TextField
                          record={record}
                          source="employee.nomorkaryawan"
                          emptyText="-"
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <StaticText
                      title="Nomor Kontrak"
                      content={
                        <TextField
                          record={record}
                          source="employee.nomorkontrak1"
                          emptyText="-"
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <StaticText
                      title="Awal Kontrak"
                      content={
                        <CustomDateField
                          record={record?.employee}
                          source="tglawalkontrak"
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <StaticText
                      title="Akhir Kontrak"
                      content={
                        <CustomDateField
                          record={record?.employee}
                          source="tglakhirkontrak"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Status Join"
                    content={
                      <FunctionField
                        render={(record) => (
                          <>
                            <SelectField
                              record={record}
                              source="join_type"
                              choices={EMPLOYEE_JOIN_TYPE_ARRAY}
                              emptyText="-"
                            />
                          </>
                        )}
                        sortBy="join_type"
                        label="Status Join"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Status Kerja"
                    content={
                      <TextField
                        record={record}
                        source="employee.statuskerja"
                        emptyText="-"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Jabatan"
                    content={
                      <TextField
                        record={record}
                        source="employee.jabatan"
                        emptyText="-"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Lokasi Kerja"
                    content={
                      <TextField
                        record={record}
                        source="employee.lokasikerja"
                        emptyText="-"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}

      <Box mt={1}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">Lainnya</Typography>
            </Box>

            <Box mb={2}>
              <StaticText
                title="Catatan Tambahan"
                content={
                  <TextField record={record} source="note" emptyText="-" />
                }
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Pemohon"
                  content={<TextField record={record} source="createdBy" />}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Waktu Permintaan"
                  content={
                    <CustomDateField
                      record={record}
                      source="createdAt.date"
                      sourceFormat={false}
                      destFormat="DD/MM/YYYY HH:mm:ss"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Sumber"
                  content={<TextField record={record} source="source" />}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default ExpandPanel;
