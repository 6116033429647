import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { stringify } from "query-string";
import React from "react";
import { usePermissions } from "react-admin";
import NumberFormat from "react-number-format";
import { Link as RouterLink } from "react-router-dom";
import { ACCESS_CODES } from "../consts";
import { DateHelper } from "../helpers";

const CustomLink = ({ children, enabled = false, ...props }) => {
  if (!enabled) {
    return children;
  }

  return (
    <Link component={RouterLink} {...props}>
      {children}
    </Link>
  );
};

const ActivityReportDailyTable = ({ record }) => {
  const { permissions } = usePermissions();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tanggal</TableCell>
            <TableCell align="right">WFH</TableCell>
            <TableCell align="right">Penugasan</TableCell>
            <TableCell align="right">WFO</TableCell>
            <TableCell align="right">Izin</TableCell>
            <TableCell align="right">Cuti</TableCell>
            <TableCell align="right">Sakit</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(record).map((date) => (
            <TableRow key={date}>
              <TableCell
                component="td"
                scope="row"
                style={{ whiteSpace: "pre-line" }}
              >
                {DateHelper.format(date, "YYYY-MM-DD", "DD/MM/YYYY")}
              </TableCell>

              <TableCell align="right">
                <CustomLink
                  enabled={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
                    permissions
                  )}
                  to={{
                    pathname: "/employees/activities",
                    search: stringify({
                      page: 1,
                      perPage: 10,
                      sort: "created_at",
                      order: "DESC",
                      filter: JSON.stringify({
                        filter_start_date: date,
                        filter_end_date: date,
                        filter_type: "wfh",
                      }),
                    }),
                  }}
                >
                  <NumberFormat
                    value={record[date].total_wfh}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </CustomLink>
              </TableCell>

              <TableCell align="right">
                <CustomLink
                  enabled={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
                    permissions
                  )}
                  to={{
                    pathname: "/employees/activities",
                    search: stringify({
                      page: 1,
                      perPage: 10,
                      sort: "created_at",
                      order: "DESC",
                      filter: JSON.stringify({
                        filter_start_date: date,
                        filter_end_date: date,
                        filter_is_penugasan_khusus: true,
                      }),
                    }),
                  }}
                >
                  <NumberFormat
                    value={record[date].total_penugasan}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </CustomLink>
              </TableCell>

              <TableCell align="right">
                <CustomLink
                  enabled={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
                    permissions
                  )}
                  to={{
                    pathname: "/employees/activities",
                    search: stringify({
                      page: 1,
                      perPage: 10,
                      sort: "created_at",
                      order: "DESC",
                      filter: JSON.stringify({
                        filter_start_date: date,
                        filter_end_date: date,
                        filter_type: "wfo",
                      }),
                    }),
                  }}
                >
                  <NumberFormat
                    value={record[date].total_wfo}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </CustomLink>
              </TableCell>

              <TableCell align="right">
                <CustomLink
                  enabled={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
                    permissions
                  )}
                  to={{
                    pathname: "/employees/activities",
                    search: stringify({
                      page: 1,
                      perPage: 10,
                      sort: "created_at",
                      order: "DESC",
                      filter: JSON.stringify({
                        filter_start_date: date,
                        filter_end_date: date,
                        filter_type: "leave",
                      }),
                    }),
                  }}
                >
                  <NumberFormat
                    value={record[date].total_leave}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </CustomLink>
              </TableCell>

              <TableCell align="right">
                <CustomLink
                  enabled={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
                    permissions
                  )}
                  to={{
                    pathname: "/employees/activities",
                    search: stringify({
                      page: 1,
                      perPage: 10,
                      sort: "created_at",
                      order: "DESC",
                      filter: JSON.stringify({
                        filter_start_date: date,
                        filter_end_date: date,
                        filter_type: "holiday",
                      }),
                    }),
                  }}
                >
                  <NumberFormat
                    value={record[date].total_holiday}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </CustomLink>
              </TableCell>

              <TableCell align="right">
                <CustomLink
                  enabled={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
                    permissions
                  )}
                  to={{
                    pathname: "/employees/activities",
                    search: stringify({
                      page: 1,
                      perPage: 10,
                      sort: "created_at",
                      order: "DESC",
                      filter: JSON.stringify({
                        filter_start_date: date,
                        filter_end_date: date,
                        filter_type: "sick",
                      }),
                    }),
                  }}
                >
                  <NumberFormat
                    value={record[date].total_sick}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </CustomLink>
              </TableCell>

              <TableCell align="right">
                <CustomLink
                  enabled={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
                    permissions
                  )}
                  to={{
                    pathname: "/employees/activities",
                    search: stringify({
                      page: 1,
                      perPage: 10,
                      sort: "created_at",
                      order: "DESC",
                      filter: JSON.stringify({
                        filter_start_date: date,
                        filter_end_date: date,
                      }),
                    }),
                  }}
                >
                  <NumberFormat
                    value={record[date].total_all}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </CustomLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ActivityReportDailyTable.propTypes = {
  record: PropTypes.object.isRequired,
};

export default ActivityReportDailyTable;
