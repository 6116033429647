import { CustomCreate } from "../../../components";
import FormView from "./FormView";

const CreateView = (props) => (
  <CustomCreate {...props} title="Buat Buku Peraturan Perusahaan">
    <FormView />
  </CustomCreate>
);

export default CreateView;
