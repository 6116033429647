import React from "react";
import { Button, useMutation } from "react-admin";

const CloseButton = ({ record }) => {
  const [execute, { loading }] = useMutation({
    type: "update",
    resource: "support/supports",
    payload: { id: record.id, data: { status: "closed" } },
  });

  if (record?.status === "closed") return null;

  return (
    <Button
      label="Selesai"
      variant="outlined"
      onClick={execute}
      disabled={loading}
    />
  );
};

export default CloseButton;
