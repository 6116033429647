import moment from "moment";
import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DeleteButton,
  EditButton,
  NumberField,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin";
import { CustomFilter, CustomList } from "../../../../components";
import { ACCESS_CODES, YEAR_ARRAY } from "../../../../consts";
import EmployeeReferenceField from "../../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../../employees/EmployeeReferenceInput";

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <SelectInput
      source="filter_period"
      label="Tahun"
      choices={YEAR_ARRAY}
      alwaysOn
    />

    <ReferenceInput
      source="filter_location"
      label="Lokasi kerja"
      reference="employees/locations"
      filter={{
        ...(ACCESS_CODES.PMO_BRO === props.permissions
          ? { is_partnership_only: 1 }
          : {}),
      }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    {[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(props.permissions) && (
      <EmployeeReferenceInput source="filter_employee" alwaysOn />
    )}
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Saldo Cuti"
    filters={<FilterView permissions={props.permissions} />}
    sort={{ field: "employee_id", order: "ASC" }}
    filterDefaultValues={{
      filter_period: moment().year(),
    }}
    useImport={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(
      props.permissions
    )}
    useExport
  >
    <Datagrid>
      <EmployeeReferenceField source="employee_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="employee_id"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <TextField source="period" label="Tahun" />

      <NumberField source="amount" label="Saldo Awal" />

      <NumberField source="used_amount" label="Terpakai" />

      <NumberField source="remaining_amount" label="Sisa" />

      {[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(props.permissions) && (
        <EditButton />
      )}
      {[ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(props.permissions) && (
        <DeleteButton mutationMode="optimistic" undoable={false} />
      )}
    </Datagrid>
  </CustomList>
);

export default ListView;
