import RequestResource from "./Request";
import TypeResource from "./Type";
import TypeApproverResource from "./TypeApprover";

const ApprovalResources = (permissions) => [
  RequestResource(permissions),
  TypeResource(permissions),
  TypeApproverResource(permissions),
];

export default ApprovalResources;
