import { Card, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 400,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    card: {
      marginLeft: "1em",
    },
    image: {
      width: "100%",
      height: "auto",
    },
  }),
  { name: "AsideViewStyles" }
);

const AsideView = ({ record }) => {
  const classes = useStyles();

  if (!record?.image) return <div />;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <img
          className={classes.image}
          src={record.image.view_url}
          alt={record.name}
          title={record.name}
        />
      </Card>
    </div>
  );
};

export default AsideView;
