import { CustomEdit } from "../../../components";
import FormView from "./FormView";

const TitleView = ({ record }) => <span>Ubah Site #{record?.id}</span>;
const EditView = (props) => (
  <CustomEdit {...props} title={<TitleView />}>
    <FormView enableAutoGeneratePin={true} />
  </CustomEdit>
);

export default EditView;
