import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  NumberField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  useDataProvider,
  useNotify,
} from "react-admin";
import { STATUS_ARRAY, TYPE_ARRAY } from ".";
import { CustomDateField, CustomFilter, CustomList } from "../../../components";
import { MONTH_ARRAY, YEAR_ARRAY } from "../../../consts";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";
import CompanyReferenceInput from "../../master/customers/CompanyReferenceInput";
import ExpandView from "./ExpandView";

const useStyles = makeStyles({
  icon: {
    fontSize: "20px",
  },
  label: {
    paddingLeft: "0.5em",
  },
});

const FilterView = (props) => (
  <CustomFilter {...props}>
    <SelectInput
      source="filter_type"
      label="Tipe"
      optionText="name"
      choices={TYPE_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_month"
      label="Bulan"
      optionText="name"
      choices={MONTH_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_year"
      label="Tahun"
      optionText="name"
      choices={YEAR_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_status"
      label="Status"
      optionText="name"
      choices={STATUS_ARRAY}
      alwaysOn
    />

    <CompanyReferenceInput source="filter_company" label="Klien" />

    <ReferenceInput
      source="filter_customer"
      reference="master/customers"
      label="PIC Perusahaan"
    >
      <AutocompleteInput
        optionText={(record) =>
          record?.company?.name && record?.fullName
            ? `${record?.company?.name} - ${record?.fullName}`
            : ""
        }
      />
    </ReferenceInput>

    <EmployeeReferenceInput source="filter_employee" />
  </CustomFilter>
);

const PdfButton = ({ record, resource, type = "invoice" }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [row, setRow] = React.useState(null);

  const [isLoading, setLoading] = React.useState(false);

  const handleClick = () => {
    if (isLoading) return;

    setLoading(true);

    dataProvider
      .getOne(resource, {
        id: record?.id,
        print: 1,
        type,
      })
      .then(({ data }) => {
        notify("Exporting to pdf....", "info");
        window.open(data?.url);
      })
      .catch((error) => {
        notify(error?.message, "error");
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (record) {
      setRow(record);
    }
  }, [record, row]);

  return (
    <Button
      size="small"
      color="primary"
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading && <CircularProgress size={20} />}
      <span className={classes.label}>
        {type === "kwitansi" ? "Print Kwitansi" : "Print Invoice"}
      </span>
    </Button>
  );
};

const useInvoiceNameFieldStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    avatar: {
      marginTop: -theme.spacing(0.5),
      marginBottom: -theme.spacing(0.5),
      marginRight: theme.spacing(2),
      fontSize: theme.typography.subtitle1.fontSize,
    },
  }),
  { name: "InvoiceNameFieldStyles" }
);

const InvoiceNameField = ({ record }) => {
  const classes = useInvoiceNameFieldStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="body1">{record.invoiceName}</Typography>
        <Typography variant="body2" color="textSecondary">
          {record.code}
        </Typography>
      </div>
    </div>
  );
};

const ListView = (props) => (
  <CustomList
    {...props}
    title="Invoice"
    filters={<FilterView />}
    sort={{ field: "date", order: "DESC" }}
    filterDefaultValues={{
      filter_year: moment().year(),
    }}
  >
    <Datagrid expand={<ExpandView />}>
      <CustomDateField
        source="date"
        sourceFormat="YYYY-MM-DD"
        destFormat="DD/MM/YYYY"
        label="Tanggal"
      />

      <InvoiceNameField source="invoiceName" label="Tagihan" />

      <TextField source="customer.company.name" label="Klien" />

      <CustomDateField
        source="dueDate"
        sourceFormat="YYYY-MM-DD"
        destFormat="DD/MM/YYYY"
        label="Jatuh Tempo"
      />

      <NumberField
        source="final_amount"
        label="Nilai Tagihan"
        options={{ style: "currency", currency: "IDR" }}
        sortable={false}
      />

      <SelectField source="type" choices={TYPE_ARRAY} label="Tipe" />

      <SelectField source="status" choices={STATUS_ARRAY} label="Status" />

      <PdfButton type="invoice" />

      <PdfButton type="kwitansi" />
    </Datagrid>
  </CustomList>
);

export default ListView;
