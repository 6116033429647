import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

const LinesTable = ({ record = {} }) => {
  return (
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kegiatan</TableCell>
              <TableCell align="right">Durasi (Jam)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(record.lines_array || []).map((line) => (
              <TableRow key={line.id}>
                <TableCell
                  component="td"
                  scope="row"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {line.description}
                </TableCell>
                <TableCell align="right">{line.dur_in_hour}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default LinesTable;
