import NotificationsIcon from "@material-ui/icons/Notifications";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const HistoryResource = (permissions) => ({
  name: "notification/histories",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: NotificationsIcon,
  options: {
    label: "Notifications",
  },
});

export default HistoryResource;
