import * as React from "react";
import { CustomCreate } from "../../../components";
import { FileHelper } from "../../../helpers";
import FormView from "./FormView";

const CreateView = (props) => {
  const transform = async (data) => {
    return {
      ...data,
      sk_file: data?.skFile ? await FileHelper.toBase64(data.skFile) : null,
      date_timezone: "Asia/Jakarta",
    };
  };

  return (
    <CustomCreate {...props} title="Buat Data Pegawai" transform={transform}>
      <FormView />
    </CustomCreate>
  );
};

export default CreateView;
