import { Link, Typography } from "@material-ui/core";
import moment from "moment";
import { stringify } from "query-string";
import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  ReferenceInput,
  useListContext,
} from "react-admin";
import NumberFormat from "react-number-format";
import { Link as RouterLink } from "react-router-dom";
import { CustomFilter, CustomList } from "../../../../components";
import EmployeeReferenceField from "../../../employees/employees/EmployeeReferenceField";

const ValueField = ({ source, record = {}, filter }) => {
  const { filterValues } = useListContext();

  const children = (formattedValue) => (
    <Typography align="right" display="block" variant="body2">
      {formattedValue === "0" ? "-" : formattedValue}
    </Typography>
  );

  return (
    <NumberFormat
      value={record[source]}
      displayType="text"
      thousandSeparator={true}
      decimalScale={0}
      fixedDecimalScale
      renderText={(formattedValue) =>
        formattedValue !== "0" ? (
          <Link
            component={RouterLink}
            to={{
              pathname: "/employees/activities",
              search: stringify({
                page: 1,
                perPage: 10,
                sort: "created_at",
                order: "DESC",
                filter: JSON.stringify({
                  filter_employee: record?.employee?.id,
                  filter_start_date: filterValues?.filter_start_date,
                  filter_end_date: filterValues?.filter_end_date,
                  ...filter,
                }),
              }),
            }}
          >
            {children(formattedValue)}
          </Link>
        ) : (
          children(formattedValue)
        )
      }
    />
  );
};

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <DateInput source="filter_start_date" label="Tanggal Awal" alwaysOn />

    <DateInput source="filter_end_date" label="Tanggal Akhir" alwaysOn />

    <ReferenceInput
      source="filter_location"
      label="Lokasi kerja"
      reference="employees/locations"
      filter={{ is_partnership_only: 1 }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Summary Activity Report"
    filters={<FilterView />}
    sort={{ field: "emp_id", order: "ASC" }}
    filterDefaultValues={{
      filter_start_date: moment().startOf("month").format("YYYY-MM-DD"),
      filter_end_date: moment().endOf("month").format("YYYY-MM-DD"),
    }}
    useExport
  >
    <Datagrid>
      <EmployeeReferenceField source="emp_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="emp_id"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <EmployeeReferenceField
        source="emp_id"
        label="Lokasi Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.lokasikerja}
      />

      <ValueField
        source="total_wfh"
        label="WFH"
        sortable={false}
        textAlign="right"
        filter={{
          filter_type: "wfh",
        }}
      />

      <ValueField
        source="total_penugasan"
        label="WFH+"
        sortable={false}
        textAlign="right"
        filter={{
          filter_is_penugasan_khusus: true,
        }}
      />

      <ValueField
        source="total_wfo"
        label="WFO"
        sortable={false}
        textAlign="right"
        filter={{
          filter_type: "wfo",
        }}
      />

      <ValueField
        source="total_holiday"
        label="Cuti"
        sortable={false}
        textAlign="right"
        filter={{
          filter_type: "holiday",
        }}
      />

      <ValueField
        source="total_leave"
        label="Izin"
        sortable={false}
        textAlign="right"
        filter={{
          filter_type: "leave",
        }}
      />

      <ValueField
        source="total_sick"
        label="Sakit"
        sortable={false}
        textAlign="right"
        filter={{
          filter_type: "sick",
        }}
      />
    </Datagrid>
  </CustomList>
);

export default ListView;
