import ImportIcon from "@material-ui/icons/Publish";
import PropTypes from "prop-types";
import React from "react";
import { Button, useRefresh } from "react-admin";
import ImportDialog from "./ImportDialog";

const ImportButton = ({ resource, ...props }) => {
  const refresh = useRefresh();
  const [isOpen, toggleDialog] = React.useState(false);

  const handleClick = () => {
    toggleDialog(true);
  };

  const handleDialogClose = () => {
    toggleDialog(false);

    refresh();
  };

  return (
    <>
      <Button {...props} onClick={handleClick} label="Import">
        <ImportIcon />
      </Button>

      <ImportDialog
        resource={resource}
        open={isOpen}
        onClose={handleDialogClose}
      />
    </>
  );
};

ImportButton.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default ImportButton;
