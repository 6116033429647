import React from "react";
import { CustomActionsView, CustomEdit } from "../../../components";
import DownloadButton from "./DownloadButton";
import FormView from "./FormView";

const TitleView = ({ record }) => (
  <span>Ubah Template Kontrak #{record?.id}</span>
);

const ActionsView = (props) => {
  return (
    <CustomActionsView {...props}>
      {({ listButton, buttonClass }) => {
        return (
          <React.Fragment>
            <DownloadButton className={buttonClass} />

            {listButton}
          </React.Fragment>
        );
      }}
    </CustomActionsView>
  );
};

const EditView = (props) => {
  return (
    <CustomEdit {...props} title={<TitleView />} actions={<ActionsView />}>
      <FormView />
    </CustomEdit>
  );
};

export default EditView;
