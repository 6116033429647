import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ActivityReportListItem from "./ActivityReportListItem";

const ActivityReportDialog = ({ open = false, onClose, record }) => {
  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      {record && (
        <DialogContent>
          <List disablePadding>
            <ActivityReportListItem record={record} disableGutters />
          </List>

          <Box mt={1}>
            <Divider />
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kegiatan</TableCell>
                  <TableCell align="right">Durasi (Jam)</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {record.lines_array.map((line) => (
                  <TableRow key={line.id}>
                    <TableCell
                      component="td"
                      scope="row"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {line.description}
                    </TableCell>
                    <TableCell align="right">{line.dur_in_hour}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ActivityReportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default ActivityReportDialog;
