import { Button, fade, makeStyles } from "@material-ui/core";
import {
  useNotify,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  }),
  { name: "CancelButtonStyles" }
);

const CancelButton = (props) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const notify = useNotify();
  const newRecord = { status: "cancel" };
  const classes = useStyles(props);

  const [update, { loading }] = useUpdate(
    resource,
    record.id,
    newRecord,
    record,
    {
      onFailure: () => {
        notify("Gagal merubah status kandidat", "error");
      },
    }
  );

  if (!record) return null;

  return (
    <Button
      className={classes.deleteButton}
      onClick={update}
      disabled={loading}
    >
      BATAL
    </Button>
  );
};

export default CancelButton;
