import React from "react";
import { CustomCreate } from "../../../components";
import FormView from "./FormView";

const initialValues = {
  date: new Date(),
};

const CreateView = (props) => {
  return (
    <CustomCreate {...props} title="Buat Surat">
      <FormView initialValues={initialValues} />
    </CustomCreate>
  );
};

export default CreateView;
