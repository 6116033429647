import { FunctionField, ReferenceField } from "react-admin";

const defaultOptionText = (record) => record?.name;

const ApprovalTypeReferenceField = ({
  optionText = defaultOptionText,
  ...props
}) => (
  <ReferenceField
    {...props}
    label="Jenis Permohonan"
    reference="approval/types"
  >
    <FunctionField render={optionText} />
  </ReferenceField>
);

export default ApprovalTypeReferenceField;
