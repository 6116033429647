import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DeleteButton,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  useRecordContext,
} from "react-admin";
import {
  ConditionalField,
  CustomDateField,
  CustomFilter,
  CustomList,
} from "../../../components";
import { DOCUMENT_STATUS_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";
import DownloadButton from "./DownloadButton";

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <EmployeeReferenceInput source="filter_employee" alwaysOn />

    <ReferenceInput
      source="filter_document"
      label="Kategori"
      reference="documents"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <SelectInput
      source="filter_status"
      label="Status"
      choices={DOCUMENT_STATUS_ARRAY}
      alwaysOn
    />
  </CustomFilter>
);

const ActionsColumn = (props) => {
  const record = useRecordContext(props);

  return (
    <React.Fragment>
      <ConditionalField
        record={record}
        when={(record) => Boolean(record?.file?.url)}
      >
        <DownloadButton />
      </ConditionalField>

      <EditButton {...props} />
      <DeleteButton mutationMode="optimistic" undoable={false} {...props} />
    </React.Fragment>
  );
};

const ListView = (props) => (
  <CustomList
    {...props}
    title="Jaminan"
    filters={<FilterView />}
    sort={{ field: "idpegawai", order: "ASC" }}
    useImport
    useExport
  >
    <Datagrid>
      <EmployeeReferenceField source="idpegawai" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="idpegawai"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <ReferenceField
        source="idjaminan"
        reference="documents"
        label="Kategori"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>

      <TextField source="nomorjaminan" label="Nomor" />

      <CustomDateField source="tgldaftar" label="Tanggal Pendaftaran" />

      <CustomDateField source="tglselesai" label="Tanggal Berakhir" />

      <SelectField
        source="statusjaminan"
        choices={DOCUMENT_STATUS_ARRAY}
        label="Status"
      />

      <ActionsColumn />
    </Datagrid>
  </CustomList>
);

export default ListView;
