import { Grid } from "@material-ui/core";
import React from "react";
import {
  FormDataConsumer,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { useForm } from "react-final-form";
import { YEAR_ARRAY } from "../../../../consts";
import EmployeeReferenceInput from "../../employees/EmployeeReferenceInput";

const VALIDATION = {
  employee_id: [required()],
  period: [required()],
  amount: [required(), number()],
  used_amount: [required(), number()],
  remaining_amount: [required(), number()],
};

const AmountForm = ({ formData, ...rest }) => {
  const form = useForm();

  const calculateRemainingAmount = () => {
    const amount = form.getFieldState("amount").value || 0;
    const usedAmount = form.getFieldState("used_amount").value || 0;

    form.change("remaining_amount", amount - usedAmount);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4} lg={2}>
          <NumberInput
            source="amount"
            label="Saldo Awal"
            validate={VALIDATION.amount}
            onChange={calculateRemainingAmount}
            fullWidth
            {...rest}
          />
        </Grid>

        <Grid item xs={4} lg={2}>
          <NumberInput
            source="used_amount"
            label="Terpakai"
            validate={VALIDATION.used_amount}
            onChange={calculateRemainingAmount}
            fullWidth
            {...rest}
          />
        </Grid>

        <Grid item xs={4} lg={2}>
          <NumberInput
            source="remaining_amount"
            label="Sisa"
            validate={VALIDATION.remaining_amount}
            fullWidth
            {...rest}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={9} lg={10}>
            <EmployeeReferenceInput
              source="employee_id"
              validate={VALIDATION.employee_id}
              fullWidth
            />
          </Grid>

          <Grid item xs={3} lg={2}>
            <SelectInput
              source="period"
              label="Tahun"
              choices={YEAR_ARRAY}
              validate={VALIDATION.period}
              fullWidth
            />
          </Grid>
        </Grid>

        <FormDataConsumer>
          {(formDataProps) => <AmountForm {...formDataProps} />}
        </FormDataConsumer>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
