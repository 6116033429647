import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const TypeResource = (permissions) => ({
  name: "contract/types",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Status Kontrak",
  },
});

export default TypeResource;
