import ApprovalResources from "./approval";
import configurations from "./configurations";
import contract from "./contract";
import documents from "./documents";
import employees from "./employees";
import events from "./events";
import MasterResources from "./master";
import notification from "./notification";
import PenomoranResources from "./penomoran";
import reports from "./reports";
import support from "./support";
import invoice from "./invoice";
import payments from "./payments";
import collection from "./collections";

const Resources = (permissions) =>
  [
    ...employees(permissions),
    ...configurations(permissions),
    documents(permissions),
    ...reports(permissions),
    ...support(permissions),
    ...notification(permissions),
    events(permissions),
    ...contract(permissions),
    ...MasterResources(permissions),
    ...PenomoranResources(permissions),
    ...ApprovalResources(permissions),
    ...invoice(permissions),
    payments(permissions),
    ...collection(permissions),
  ].filter((resource) => resource != null);

export default Resources;
