import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const BankPaymentResource = (permissions) => ({
  name: "master/bank-payments",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Bank Pembayaran",
  },
});

export default BankPaymentResource;
