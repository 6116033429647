import { ACCESS_CODES } from "../../consts";
import approvals from "./approvals";
import categories from "./categories";
import location from "./locations";
import requests from "./requests";
import templates from "./templates";
import types from "./types";

const ContractResources = (permissions) => [
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? location(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? categories(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? types(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? requests(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? approvals(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? templates(permissions)
    : null,
];

export default ContractResources;
