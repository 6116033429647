import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  NumberField,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";
import { EMPLOYEE_JOIN_TYPE_ARRAY } from ".";
import { CustomDateField, StaticText } from "../../../components";
import { GENDER_ARRAY, MARITAL_ARRAY } from "../../../consts";

const ExpandPanel = (props) => {
  const record = useRecordContext(props);

  return (
    <Container>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography variant="subtitle2">Data Pribadi</Typography>
          </Box>

          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <StaticText
                  title="Nama Lengkap"
                  content={<TextField record={record} source="namakaryawan" />}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nama Panggilan"
                  content={<TextField record={record} source="kodekaryawan" />}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Alamat Email"
                  content={<TextField record={record} source="emailkaryawan" />}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor Telpon"
                  content={<TextField record={record} source="telpkaryawan" />}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Tempat Lahir"
                  content={<TextField record={record} source="tempatlahir" />}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Tanggal Lahir"
                  content={
                    <CustomDateField record={record} source="tgllahir" />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Jenis Kelamin"
                  content={
                    <SelectField source="jeniskelamin" choices={GENDER_ARRAY} />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Status Pernikahan"
                  content={
                    <SelectField source="statusnikah" choices={MARITAL_ARRAY} />
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <StaticText
                  title="Nama Sekolah/Universitas/Kursus"
                  content={<TextField record={record} source="edu_school" />}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <StaticText
                  title="Tingkat"
                  content={<TextField record={record} source="pendidikan" />}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <StaticText
                  title="Jurusan/Bidang"
                  content={<TextField record={record} source="edu_major" />}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <StaticText
                  title="Tahun Lulus"
                  content={<NumberField record={record} source="edu_year" />}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <StaticText
                  title="Nilai Akhir"
                  content={<NumberField record={record} source="edu_score" />}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <StaticText
              title="Alamat Domisili"
              content={<TextField record={record} source="alamatkaryawan" />}
            />
          </Box>

          <StaticText
            title="Alamat KTP"
            content={<TextField record={record} source="alamatktp" />}
          />
        </CardContent>
      </Card>

      <Box mt={1}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">Dokumen</Typography>
            </Box>

            <Box mb={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <StaticText
                    title="Nomor KTP"
                    content={<TextField record={record} source="nomorktp" />}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <StaticText
                    title="Nomor Kartu Keluarga"
                    content={
                      <TextField
                        record={record}
                        source="nomorkk"
                        emptyText="-"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor NPWP"
                  content={
                    <TextField
                      record={record}
                      source="npwpkaryawan"
                      emptyText="-"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor BPJS Kesehatan"
                  content={
                    <TextField
                      record={record}
                      source="bpjssehat"
                      emptyText="-"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <StaticText
                  title="Nomor BPJS Ketenagakerjaan"
                  content={
                    <TextField
                      record={record}
                      source="bpjstenaga"
                      emptyText="-"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box mt={1}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography variant="subtitle2">Kontrak</Typography>
            </Box>

            <Box mb={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Status Join"
                    content={
                      <SelectField
                        source="join_type"
                        choices={EMPLOYEE_JOIN_TYPE_ARRAY}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Unit Kerja"
                    content={<TextField record={record} source="unitkerja" />}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Jabatan"
                    content={<TextField record={record} source="jabatan" />}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Lokasi Kerja"
                    content={<TextField record={record} source="lokasikerja" />}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Tanggal Bergabung"
                    content={
                      <CustomDateField record={record} source="tglbergabung" />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Tanggal Berhenti"
                    content={
                      <CustomDateField record={record} source="tgl_berhenti" />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Awal Kontrak"
                    content={
                      <CustomDateField
                        record={record}
                        source="tglawalkontrak"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <StaticText
                    title="Akhir Kontrak"
                    content={
                      <CustomDateField
                        record={record}
                        source="tglakhirkontrak"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default ExpandPanel;
