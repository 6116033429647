import { choices, minValue, number, required } from "react-admin";
import { ACCESS_CODES } from "../../../consts";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

export const FIELD_ARRAY = [
  { id: "none", name: "Tidak Perlu" },
  { id: "optional", name: "Opsional" },
  { id: "required", name: "Mandatory" },
];

export const VALIDATION = {
  name: [required()],
  description: [],
  fieldDocument: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  fieldDate: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  fieldPeriod: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  fieldProduct: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  fieldQuantity: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  fieldAmount: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  fieldCoordinate: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  fieldAddress: [required(), choices(FIELD_ARRAY.map((e) => e.id))],
  approverMin: [number(), minValue(0)],
  approverLeader: [],
};

const TypeResource = (permissions) => ({
  name: "approval/types",
  list: [ACCESS_CODES.ADMIN].includes(permissions) ? ListView : null,
  create: [ACCESS_CODES.ADMIN].includes(permissions) ? CreateView : null,
  edit: [ACCESS_CODES.ADMIN].includes(permissions) ? EditView : null,
  options: {
    label: "Jenis",
  },
});

export default TypeResource;
