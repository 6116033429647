import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import ExportIcon from "@material-ui/icons/GetApp";
import React from "react";
import {
  ArrayField,
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Datagrid,
  DateInput,
  DeleteButton,
  EditButton,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  maxLength,
  minLength,
  number,
  required,
  useDataProvider,
  useNotify,
} from "react-admin";
import { EMPLOYEE_JOIN_TYPE_ARRAY } from ".";
import {
  ConditionalFormDataConsumer,
  CustomDateField,
} from "../../../components";
import {
  CONTRACT_ARRAY,
  DOCUMENT_STATUS_ARRAY,
  GENDER_ARRAY,
  MARITAL_ARRAY,
  TIMEZONE_ARRAY,
} from "../../../consts";
import EmployeeReferenceInput from "./EmployeeReferenceInput";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

const VALIDATION = {
  namakaryawan: [required()],
  kodekaryawan: [],
  emailkaryawan: [required()],
  telpkaryawan: [required()],
  tempatlahir: [required()],
  tgllahir: [required()],
  jeniskelamin: [required()],
  statusnikah: [required()],
  edu_school: [],
  edu_major: [],
  edu_year: [minLength(4), maxLength(4)],
  edu_score: [number()],
  pendidikan: [required()],
  alamatktp: [required()],
  alamatkaryawan: [],
  nomorkaryawan: [required()],
  new_password: [],
  nomorktp: [required()],
  nomorkk: [],
  bpjstenaga: [],
  bpjssehat: [],
  nomorrekening: [],
  namabank: [],
  npwpkaryawan: [],
  unitkerja: [required()],
  jabatan: [required()],
  lokasikerja: [required()],
  tglbergabung: [required()],
  tgl_berhenti: [],
  tglawalkontrak: [required()],
  tglakhirkontrak: [required()],
  nomorkontrak1: [required()],
  nomorkontrak2: [],
  nomorkontrak3: [],
  jumlahkontrak: [],
  statuskerja: [required()],
  join_type: [required()],
  statuskontrak: [required()],
  alasanberhenti: [],
  leader_id: [],
  leave_subscription: [],
  tgl_perpanjangan: [required()],
  notes: {
    code: [required()],
    start_date: [required()],
    end_date: [required()],
    status: [required()],
    description: [],
  },
  tgl_rencana_keluar: [],
  date_timezone: [required()],
};

const DownloadSKButton = ({ formData }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setLoading] = React.useState(false);

  const handleClick = () => {
    if (isLoading) return;

    setLoading(true);

    dataProvider
      .export("employees/employees", {
        filter: {
          ref: "download-sk",
          id: formData?.id,
        },
      })
      .then(({ data }) => {
        notify("Downloading...", "info");

        window.open(data.url);
      })
      .catch((error) => {
        notify(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box mb={2}>
      <Button
        onClick={handleClick}
        label="Unduh Soft-Copy SK"
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={16} /> : <ExportIcon />}
      </Button>
    </Box>
  );
};

const FormView = (props) => {
  const classes = useStyles();

  return (
    <TabbedForm {...props}>
      <FormTab label="DATA PRIBADI">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
              <TextInput
                source="namakaryawan"
                label="Nama Lengkap"
                validate={VALIDATION.namakaryawan}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextInput
                source="kodekaryawan"
                label="Kode"
                validate={VALIDATION.kodekaryawan}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
              <TextInput
                type="email"
                source="emailkaryawan"
                label="Alamat Email"
                validate={VALIDATION.emailkaryawan}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextInput
                source="telpkaryawan"
                label="Nomor Telpon"
                validate={VALIDATION.telpkaryawan}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
              <TextInput
                source="tempatlahir"
                label="Tempat Lahir"
                validate={VALIDATION.tempatlahir}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <DateInput
                source="tgllahir"
                label="Tanggal Lahir"
                validate={VALIDATION.tgllahir}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} lg={2}>
              <SelectInput
                source="jeniskelamin"
                label="Jenis Kelamin"
                choices={GENDER_ARRAY}
                validate={VALIDATION.jeniskelamin}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={2}>
              <SelectInput
                source="statusnikah"
                label="Status Nikah"
                choices={MARITAL_ARRAY}
                validate={VALIDATION.statusnikah}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput
                source="alamatktp"
                label="Alamat KTP"
                validate={VALIDATION.alamatktp}
                fullWidth
                multiline
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextInput
                source="alamatkaryawan"
                label="Alamat Domisili"
                validate={VALIDATION.alamatkaryawan}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>

          <TextInput
            source="edu_school"
            label="Nama Sekolah/Universitas/Institut/Kursus"
            validate={VALIDATION.edu_school}
            fullWidth
          />

          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <TextInput
                source="pendidikan"
                label="Tingkat"
                validate={VALIDATION.pendidikan}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput
                source="edu_major"
                label="Jurusan/Bidang"
                validate={VALIDATION.edu_major}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <TextInput
                type="number"
                source="edu_year"
                label="Tahun Lulus"
                validate={VALIDATION.edu_year}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <TextInput
                type="number"
                source="edu_score"
                label="Nilai Akhir"
                validate={VALIDATION.edu_score}
                fullWidth
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </FormTab>

      <FormTab label="DOKUMEN">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={4} lg={3}>
              <TextInput
                source="nomorkaryawan"
                label="Nomor Karyawan"
                validate={VALIDATION.nomorkaryawan}
                fullWidth
              />
            </Grid>

            <Grid item xs={8} lg={9}>
              <PasswordInput
                source="new_password"
                label="Password"
                validate={VALIDATION.new_password}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput
                source="nomorktp"
                label="Nomor KTP"
                validate={VALIDATION.nomorktp}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextInput
                source="nomorkk"
                label="Nomor KK"
                validate={VALIDATION.nomorkk}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput
                source="bpjstenaga"
                label="Nomor BPJS Ketenagakerjaan"
                validate={VALIDATION.bpjstenaga}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextInput
                source="bpjssehat"
                label="Nomor BPJS Kesehatan"
                validate={VALIDATION.bpjssehat}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput
                source="nomorrekening"
                label="Nomor Rekening"
                validate={VALIDATION.nomorrekening}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextInput
                source="namabank"
                label="Nama Bank"
                validate={VALIDATION.namabank}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput
                source="npwpkaryawan"
                label="Nomor NPWP"
                validate={VALIDATION.npwpkaryawan}
                fullWidth
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </FormTab>

      <FormTab label="LAINNYA">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <ReferenceInput
                source="unitkerja"
                label="Unit Kerja"
                reference="employees/units"
                validate={VALIDATION.unitkerja}
                fullWidth
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput
                source="jabatan"
                label="Jabatan"
                validate={VALIDATION.jabatan}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextInput
                source="lokasikerja"
                label="Lokasi Kerja"
                validate={VALIDATION.lokasikerja}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} lg={3}>
              <DateInput
                source="tglbergabung"
                label="Tanggal Bergabung"
                validate={VALIDATION.tglbergabung}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <DateInput
                source="tgl_berhenti"
                label="Tanggal Berhenti"
                validate={VALIDATION.tgl_berhenti}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <DateInput
                source="tglawalkontrak"
                label="Tanggal Awal Kontrak"
                validate={VALIDATION.tglawalkontrak}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <DateInput
                source="tglakhirkontrak"
                label="Tanggal Akhir Kontrak"
                validate={VALIDATION.tglakhirkontrak}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} lg={3}>
              <TextInput
                source="nomorkontrak1"
                label="Nomor Kontak 1"
                validate={VALIDATION.nomorkontrak1}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <TextInput
                source="nomorkontrak2"
                label="Nomor Kontrak 2"
                validate={VALIDATION.nomorkontrak2}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <TextInput
                source="nomorkontrak3"
                label="Nomor Kontrak 3"
                validate={VALIDATION.nomorkontrak3}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <TextInput
                source="jumlahkontrak"
                label="Jumlah Kontrak"
                validate={VALIDATION.jumlahkontrak}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} lg={2}>
              <ReferenceInput
                source="statuskerja"
                label="Status Kerja"
                reference="employees/status"
                validate={VALIDATION.statuskerja}
                fullWidth
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>

            <Grid item xs={6} lg={2}>
              <SelectInput
                source="join_type"
                label="Status Join"
                choices={EMPLOYEE_JOIN_TYPE_ARRAY}
                validate={VALIDATION.join_type}
                fullWidth
              >
                <SelectInput optionText="name" />
              </SelectInput>
            </Grid>

            <Grid item xs={6} lg={3}>
              <SelectInput
                source="statuskontrak"
                label="Status Kontrak"
                choices={CONTRACT_ARRAY}
                validate={VALIDATION.statuskontrak}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={5}>
              <TextInput
                source="alasanberhenti"
                label="Alasan Berhenti"
                validate={VALIDATION.alasanberhenti}
                fullWidth
              />
            </Grid>
          </Grid>

          <EmployeeReferenceInput
            source="leader_id"
            label="Atasan"
            validate={VALIDATION.leader_id}
            fullWidth
          />

          <TextInput
            source="leave_subscription"
            label="Email Notifikasi Cuti"
            validate={VALIDATION.leave_subscription}
            fullWidth
          />

          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <SelectInput
                source="date_timezone"
                label="Zona Waktu"
                choices={TIMEZONE_ARRAY}
                validate={VALIDATION.date_timezone}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <DateInput
                source="tgl_rencana_keluar"
                label="Tanggal Terakhir Bekerja"
                validate={VALIDATION.tgl_rencana_keluar}
                fullWidth
              />
            </Grid>
          </Grid>

          <FileInput
            source="skFile"
            label="Unggah soft-copy SK disini"
            accept="application/pdf"
          >
            <FileField source="src" title="title" />
          </FileInput>

          <ConditionalFormDataConsumer
            when={(formData) => Boolean(formData?.sk_file)}
          >
            {({ formData }) => <DownloadSKButton formData={formData} />}
          </ConditionalFormDataConsumer>

          <BooleanInput
            source="flag_perpanjangan"
            label="Akan Diperpanjang?"
            validate={VALIDATION.flag_perpanjangan}
          />

          <ConditionalFormDataConsumer
            when={(formData) => formData?.flag_perpanjangan === true}
          >
            {() => (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <DateInput
                    source="tgl_perpanjangan"
                    label="Tanggal Rencana Perpanjang"
                    validate={VALIDATION.tgl_perpanjangan}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </ConditionalFormDataConsumer>
        </React.Fragment>
      </FormTab>

      <FormTab label="CATATAN">
        <React.Fragment>
          <ArrayInput source="notes_array" label="Catatan">
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ getSource }) => (
                  <React.Fragment>
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={3}>
                        <TextInput
                          source={getSource("code")}
                          label="Nomor Kontrak"
                          validate={VALIDATION.notes.code}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6} lg={3}>
                        <DateInput
                          source={getSource("start_date")}
                          label="Tanggal Mulai"
                          validate={VALIDATION.notes.start_date}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6} lg={3}>
                        <DateInput
                          source={getSource("end_date")}
                          label="Tanggal Selesai"
                          validate={VALIDATION.notes.end_date}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6} lg={3}>
                        <SelectInput
                          source={getSource("status")}
                          label="Status Kontrak"
                          choices={CONTRACT_ARRAY}
                          validate={VALIDATION.notes.status}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <TextInput
                      source={getSource("description")}
                      label="Keterangan"
                      validate={VALIDATION.notes.description}
                      multiline
                      fullWidth
                    />
                  </React.Fragment>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </React.Fragment>
      </FormTab>

      {props.record?.id && (
        <FormTab label="JAMINAN">
          <FormDataConsumer>
            {({ formData, basePath, ...formDataProps }) => (
              <React.Fragment>
                <ArrayField
                  source="documents_array"
                  label="Jaminan"
                  basePath="/employees/documents"
                  {...formDataProps}
                >
                  <Datagrid classes={{ table: classes.table }}>
                    <ReferenceField
                      reference="documents"
                      source="idjaminan"
                      label="Kategori"
                      link={false}
                      sortable={false}
                    >
                      <TextField source="name" />
                    </ReferenceField>

                    <TextField
                      source="nomorjaminan"
                      label="Nomor"
                      sortable={false}
                    />

                    <SelectField
                      source="statusjaminan"
                      label="Status"
                      choices={DOCUMENT_STATUS_ARRAY}
                      sortable={false}
                    />

                    <CustomDateField
                      source="tgljaminanawal"
                      label="Awal"
                      sortable={false}
                    />

                    <CustomDateField
                      source="tgljaminanakhir"
                      label="Akhir"
                      sortable={false}
                    />

                    <EditButton />
                    <DeleteButton mutationMode="optimistic" undoable={false} />
                  </Datagrid>
                </ArrayField>
              </React.Fragment>
            )}
          </FormDataConsumer>
        </FormTab>
      )}
    </TabbedForm>
  );
};

export default FormView;
