import { ACCESS_CODES } from "../../consts";
import invoices from "./invoices";
import details from "./details";

const InvoiceResources = (permissions) => [
  [
    ACCESS_CODES.ADMIN,
    ACCESS_CODES.HR,
    ACCESS_CODES.FINANCE,
    ACCESS_CODES.PMO_BRO,
  ].includes(permissions)
    ? invoices(permissions)
    : null,
  [
    ACCESS_CODES.ADMIN,
    ACCESS_CODES.HR,
    ACCESS_CODES.FINANCE,
    ACCESS_CODES.PMO_BRO,
  ].includes(permissions)
    ? details(permissions)
    : null,
];

export default InvoiceResources;
