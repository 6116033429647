import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SimpleFormIterator,
  TextField,
  useDataProvider,
  useNotify,
} from "react-admin";

const ChangePermissionsDialog = ({ open, onClose, record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleSubmit = (values) => {
    const { permissions_array } = values;

    dataProvider
      .update("employees/employees", {
        id: record.id,
        data: {
          permissions_array,
          _ref: "change-permissions",
        },
      })
      .then(() => {
        notify("The permissions was changed successfully", "info");

        onClose();
      })
      .catch((error) => {
        notify(error.message, "error");
      });
  };

  if (!record?.id) {
    return <div></div>;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label={`Change Permissions #${record.id}`}
    >
      <DialogTitle>{`Change Permissions #${record.id}`}</DialogTitle>

      <FormWithRedirect
        resource="employees/employees"
        record={record}
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <>
            <GridShowLayout
              record={record}
              basePath="/employees/employees"
              resource="employees/employees"
            >
              <RaGrid container spacing={2}>
                <RaGrid item xs={4}>
                  <TextField source="nomorkaryawan" label="NIK" />
                </RaGrid>

                <RaGrid item xs={8}>
                  <TextField source="namakaryawan" label="Nama Karyawan" />
                </RaGrid>
              </RaGrid>

              <ArrayInput source="permissions_array" label="Fitur Aplikasi">
                <SimpleFormIterator>
                  <FormDataConsumer>
                    {({ getSource }) => (
                      <React.Fragment>
                        <ReferenceInput
                          source={getSource("feature_id")}
                          label="Fitur"
                          reference="configurations/features"
                          validate={[required()]}
                          fullWidth
                        >
                          <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                      </React.Fragment>
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </GridShowLayout>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={onClose} />

              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                label="Update"
                saving={saving}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

ChangePermissionsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default ChangePermissionsDialog;
