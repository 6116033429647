import * as React from "react";
import { CustomEdit } from "../../../components";
import { FileHelper } from "../../../helpers";
import FormView from "./FormView";

const TitleView = ({ record }) => <span>Ubah Data Pegawai #{record.id}</span>;

const EditView = (props) => {
  const transform = async (data) => {
    return {
      ...data,
      sk_file: data?.skFile
        ? await FileHelper.toBase64(data.skFile)
        : data?.sk_file,
    };
  };

  return (
    <CustomEdit {...props} title={<TitleView />} transform={transform}>
      <FormView />
    </CustomEdit>
  );
};

export default EditView;
