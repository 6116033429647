import { Grid } from "@material-ui/core";
import React from "react";
import {
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { MONTH_ARRAY, YEAR_ARRAY } from "../../../consts";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const VALIDATION = {
  employee_id: [required()],
  company_name: [required()],
  period_month: [required()],
  period_year: [required()],
  order: [required()],
  gaji_pokok: [required(), number()],
  uang_makan_transport_hari: [required(), number()],
  uang_makan_transport: [required(), number()],
  tunjangan_tidak_tetap: [required(), number()],
  reward: [required(), number()],
  tunjangan_bpjs_kesehatan: [required(), number()],
  tunjangan_bpjs_ketenagakerjaan: [required(), number()],
  tunjangan_dana_pensiun: [required(), number()],
  tunjangan_lembur_shift: [required(), number()],
  tunjangan_thr: [required(), number()],
  kompensasi: [required(), number()],
  bonus: [required(), number()],
  simpanan_koperasi: [required(), number()],
  pinjaman_karyawan: [required(), number()],
  sisa_pinjaman: [required(), number()],
  potongan_pajak: [required(), number()],
  potongan_pinjaman: [required(), number()],
  potongan_bpjs_kesehatan: [required(), number()],
  potongan_bpjs_ketenagakerjaan: [required(), number()],
  potongan_dana_pensiun: [required(), number()],
  potongan_koperasi: [required(), number()],
  potongan_lainnya: [required(), number()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <EmployeeReferenceInput
          source="employee_id"
          validate={VALIDATION.employee_id}
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              source="company_name"
              label="Perusahaan"
              validate={VALIDATION.company_name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <SelectInput
              source="period_month"
              label="Bulan"
              optionText="name"
              choices={MONTH_ARRAY}
              validate={VALIDATION.period_month}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <SelectInput
              source="period_year"
              label="Tahun"
              optionText="name"
              choices={YEAR_ARRAY}
              validate={VALIDATION.period_year}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={2}>
            <TextInput
              source="order"
              label="Nomor Urut"
              validate={VALIDATION.order}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <NumberInput
              source="gaji_pokok"
              label="Gaji Pokok"
              validate={VALIDATION.gaji_pokok}
              fullWidth
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NumberInput
                  source="uang_makan_transport_hari"
                  label="Hari"
                  validate={VALIDATION.uang_makan_transport_hari}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <NumberInput
                  source="uang_makan_transport"
                  label="Uang Makan / Transport"
                  validate={VALIDATION.uang_makan_transport}
                  fullWidth
                />
              </Grid>
            </Grid>

            <NumberInput
              source="tunjangan_tidak_tetap"
              label="Tunjangan Tidak Tetap"
              validate={VALIDATION.tunjangan_tidak_tetap}
              fullWidth
            />

            <NumberInput
              source="reward"
              label="Reward"
              validate={VALIDATION.reward}
              fullWidth
            />

            <NumberInput
              source="tunjangan_bpjs_kesehatan"
              label="Tunjangan BPJS Kesehatan"
              validate={VALIDATION.tunjangan_bpjs_kesehatan}
              fullWidth
            />

            <NumberInput
              source="tunjangan_bpjs_ketenagakerjaan"
              label="Tunjangan BPJS Ketenagakerjaan"
              validate={VALIDATION.tunjangan_bpjs_ketenagakerjaan}
              fullWidth
            />

            <NumberInput
              source="tunjangan_dana_pensiun"
              label="Tunjangan Dana Pensiun BPJS TK"
              validate={VALIDATION.tunjangan_dana_pensiun}
              fullWidth
            />

            <NumberInput
              source="tunjangan_lembur_shift"
              label="Tunjangan Lembur / Shift"
              validate={VALIDATION.tunjangan_lembur_shift}
              fullWidth
            />

            <NumberInput
              source="tunjangan_thr"
              label="Tunjangan Hari Raya (THR)"
              validate={VALIDATION.tunjangan_thr}
              fullWidth
            />

            <NumberInput
              source="kompensasi"
              label="Kompensasi"
              validate={VALIDATION.kompensasi}
              fullWidth
            />

            <NumberInput
              source="bonus"
              label="Bonus Penjualan / Kekurangan"
              validate={VALIDATION.bonus}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <NumberInput
              source="simpanan_koperasi"
              label="Simpanan Koperasi"
              validate={VALIDATION.simpanan_koperasi}
              fullWidth
            />

            <NumberInput
              source="pinjaman_karyawan"
              label="Pinjaman Karyawan"
              validate={VALIDATION.pinjaman_karyawan}
              fullWidth
            />

            <NumberInput
              source="sisa_pinjaman"
              label="Sisa Pinjaman"
              validate={VALIDATION.sisa_pinjaman}
              fullWidth
            />

            <NumberInput
              source="potongan_pajak"
              label="Potongan Pajak"
              validate={VALIDATION.potongan_pajak}
              fullWidth
            />

            <NumberInput
              source="potongan_pinjaman"
              label="Potongan Pinjaman"
              validate={VALIDATION.potongan_pinjaman}
              fullWidth
            />

            <NumberInput
              source="potongan_bpjs_kesehatan"
              label="Potongan BPJS Kesehatan"
              validate={VALIDATION.potongan_bpjs_kesehatan}
              fullWidth
            />

            <NumberInput
              source="potongan_bpjs_ketenagakerjaan"
              label="Potongan BPJS Ketenagakerjaan"
              validate={VALIDATION.potongan_bpjs_ketenagakerjaan}
              fullWidth
            />

            <NumberInput
              source="potongan_dana_pensiun"
              label="Potongan Dana Pensiun BPJS TK"
              validate={VALIDATION.potongan_dana_pensiun}
              fullWidth
            />

            <NumberInput
              source="potongan_koperasi"
              label="Potongan Koperasi"
              validate={VALIDATION.potongan_koperasi}
              fullWidth
            />

            <NumberInput
              source="potongan_lainnya"
              label="Potongan Lainnya"
              validate={VALIDATION.potongan_lainnya}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
