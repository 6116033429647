import { required } from "ra-core";
import { SimpleForm, TextInput } from "ra-ui-materialui";
import React from "react";

const VALIDATION = {
  name: [required()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <TextInput
          source="name"
          label="Nama Perusahaan"
          validate={VALIDATION.name}
          fullWidth
        />
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
