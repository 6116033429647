import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { Datagrid, SelectInput } from "react-admin";
import NumberFormat from "react-number-format";
import { CustomFilter, CustomList } from "../../../../components";
import { YEAR_ARRAY } from "../../../../consts";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
});

const LocationField = ({ record = {} }) => {
  const classes = useStyles();

  return (
    <Typography
      className={record["id"] === "Total" ? classes.bold : null}
      align={record["id"] === "Total" ? "right" : "left"}
      display="block"
      variant="body2"
    >
      {record["id"]}
    </Typography>
  );
};

const ValueField = ({ source, record = {} }) => {
  const classes = useStyles();

  return (
    <NumberFormat
      value={record[source]}
      displayType="text"
      thousandSeparator={true}
      decimalScale={0}
      fixedDecimalScale
      renderText={(formattedValue) => (
        <Typography
          className={record["id"] === "Total" ? classes.bold : null}
          align="right"
          display="block"
          variant="body2"
        >
          {formattedValue === "0" ? "-" : formattedValue}
        </Typography>
      )}
    />
  );
};

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <SelectInput
      source="filter_year"
      label="Tahun"
      optionText="name"
      choices={YEAR_ARRAY}
      allowEmpty={false}
      alwaysOn
    />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Rekap Karyawan Keluar"
    filters={<FilterView />}
    sort={{ field: "id", order: "ASC" }}
    filterDefaultValues={{
      filter_year: moment().year(),
    }}
    pagination={false}
    useExport
  >
    <Datagrid>
      <LocationField label="Lokasi Kerja" sortable={false} />

      <ValueField
        source="total_jan"
        label="Jan"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_feb"
        label="Feb"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_mar"
        label="Mar"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_apr"
        label="Apr"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_may"
        label="May"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_jun"
        label="Jun"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_jul"
        label="Jul"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_aug"
        label="Aug"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_sep"
        label="Sep"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_oct"
        label="Oct"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_nov"
        label="Nov"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total_dec"
        label="Dec"
        sortable={false}
        textAlign="right"
      />

      <ValueField
        source="total"
        label="Total"
        sortable={false}
        textAlign="right"
      />
    </Datagrid>
  </CustomList>
);

export default ListView;
