import { Datagrid, DeleteButton, EditButton, TextField } from "react-admin";
import { CustomList } from "../../../components";
import DownloadButton from "./DownloadButton";

const ListView = (props) => (
  <CustomList {...props} title="Buku Peraturan Perusahaan">
    <Datagrid>
      <TextField source="name" label="Judul" />

      <DownloadButton />

      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
