import { Box, Container, Grid, Link, Typography } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  SelectField,
  SelectInput,
  TextField,
} from "react-admin";
import NumberFormat from "react-number-format";
import { CustomFilter, CustomList, StaticText } from "../../../components";
import {
  NOTIFICATION_RECEIVER_TYPE_ARRAY,
  NOTIFICATION_SENDER_TYPE_ARRAY,
  NOTIFICATION_STATUS_ARRAY,
  NOTIFICATION_VISIBLE_TYPE_ARRAY,
} from "../../../consts";
import { DateHelper } from "../../../helpers";
import EmployeeReferenceField from "../../employees/employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";
import ReadHistoryDialog from "./ReadHistoryDialog";
import ReceiverLocationTable from "./ReceiverLocationTable";
import ReceiverUserTable from "./ReceiverUserTable";

const SenderField = ({ record = {} }) => {
  return record.sender_type === "system" ? (
    <SelectField
      record={record}
      source="sender_type"
      choices={NOTIFICATION_SENDER_TYPE_ARRAY}
    />
  ) : (
    <EmployeeReferenceField
      basePath="/employees/employees"
      record={record}
      source="sender_user_id"
      avatar
    />
  );
};

const ReadCountField = ({ record = {}, onClick = (record) => null }) => {
  var children = (formattedValue) => (
    <Typography align="right" display="block" variant="body2">
      {formattedValue === "0" ? "-" : formattedValue}
    </Typography>
  );

  const handleOnClick = (event) => {
    event.preventDefault();

    onClick(record);
  };

  return (
    <NumberFormat
      value={(record?.read_histories_array || []).length}
      displayType="text"
      thousandSeparator={true}
      decimalScale={0}
      fixedDecimalScale
      renderText={(formattedValue) => {
        return formattedValue !== "0" ? (
          <Link href="#" onClick={handleOnClick}>
            {children(formattedValue)}
          </Link>
        ) : (
          children(formattedValue)
        );
      }}
    />
  );
};

const FilterView = (props) => {
  return (
    <CustomFilter {...props}>
      <SelectInput
        source="filter_sender_type"
        label="Sender Type"
        choices={NOTIFICATION_SENDER_TYPE_ARRAY}
        alwaysOn
      />

      <EmployeeReferenceInput
        source="filter_sender_user"
        label="Sender User"
        alwaysOn
      />
    </CustomFilter>
  );
};

const ExpandView = ({ record = {} }) => {
  return (
    <Container>
      {record.status === "public" && (
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StaticText
                title="Visibility"
                content={
                  _.find(NOTIFICATION_VISIBLE_TYPE_ARRAY, {
                    id: record.visible_type,
                  }).name
                }
              />
            </Grid>

            <Grid item xs={6}>
              <StaticText
                title="Visible After"
                content={DateHelper.format(
                  record.visible_at,
                  "YYYY-MM-DD HH:mm:ss",
                  "DD/MM/YYYY HH:mm:ss"
                )}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <StaticText title="Message" content={record.message} />

      {record.receiver_type === "user" && (
        <Box mt={2}>
          <ReceiverUserTable record={record} />
        </Box>
      )}

      {record.receiver_type === "location" && (
        <Box mt={2}>
          <ReceiverLocationTable record={record} />
        </Box>
      )}
    </Container>
  );
};

const ListView = (props) => {
  const [currentRecord, setCurrentRecord] = React.useState(null);

  const handleOnClickReadHistory = (record) => {
    setCurrentRecord(record);
  };

  const handleOnCloseReadHistoryDialog = () => {
    setCurrentRecord(null);
  };

  return (
    <>
      <CustomList
        {...props}
        title="Notifications"
        filters={<FilterView />}
        sort={{ field: "visible_at", order: "DESC" }}
      >
        <Datagrid expand={<ExpandView />}>
          <SenderField label="Sender" sortable={false} />

          <TextField source="subject" label="Subject" />

          <SelectField
            source="receiver_type"
            label="Receiver"
            choices={NOTIFICATION_RECEIVER_TYPE_ARRAY}
          />

          <SelectField
            source="status"
            label="Status"
            choices={NOTIFICATION_STATUS_ARRAY}
          />

          <ReadCountField
            label="Read Count"
            sortable={false}
            textAlign="right"
            onClick={handleOnClickReadHistory}
          />

          <EditButton />

          <DeleteButton mutationMode="optimistic" undoable={false} />
        </Datagrid>
      </CustomList>

      <ReadHistoryDialog
        open={currentRecord !== null}
        onClose={handleOnCloseReadHistoryDialog}
        record={currentRecord}
      />
    </>
  );
};

export default ListView;
