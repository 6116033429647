import PropTypes from "prop-types";
import React from "react";
import { usePermissions } from "react-admin";

const WithPermission = ({ codes = [], children, ...props }) => {
  const { permissions } = usePermissions();

  return codes.includes(permissions) ? (
    React.Children.map(children, (child) =>
      React.isValidElement(child) ? React.cloneElement(child, props) : child
    )
  ) : (
    <></>
  );
};

WithPermission.propTypes = {
  codes: PropTypes.array,
  children: PropTypes.element.isRequired,
};

export default WithPermission;
