import { ACCESS_CODES } from "../../../consts";
import aktif from "./aktif";
import keluar from "./keluar";
import masuk from "./masuk";
import penggajian from "./penggajian";
import rekap from "./rekap";
import status from "./status";
import summaryActivityReport from "./summary-activity-report";
import WeeklyRekapReportResource from "./weekly";

const ReportEmployeeResources = (permissions) => [
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? rekap(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? WeeklyRekapReportResource(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? masuk(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? keluar(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? status(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? aktif(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? penggajian(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR, ACCESS_CODES.PMO_BRO].includes(
    permissions
  )
    ? summaryActivityReport(permissions)
    : null,
];

export default ReportEmployeeResources;
