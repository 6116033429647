import { Card } from "@material-ui/core";
import React from "react";
import { Create } from "react-admin";
import CustomActionsView from "./CustomActionsView";

const Component = ({ className, children }) => (
  <div className={className}>
    <Card>{children}</Card>
  </div>
);

export const CustomCreate = ({ children, ...props }) => {
  return (
    <Create actions={<CustomActionsView />} component={Component} {...props}>
      {children}
    </Create>
  );
};
