import { Grid } from "@material-ui/core";
import { FormDataConsumer, required } from "ra-core";
import RichTextInput from "ra-input-rich-text";
import {
  ArrayInput,
  AutocompleteInput,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import ConditionalFormDataConsumer from "../../components/ConditionalFormDataConsumer";
import { EVENT_STATUS_ARRAY, EVENT_VISIBILITY_ARRAY } from "../../consts";
import EmployeeReferenceInput from "../employees/employees/EmployeeReferenceInput";

const VALIDATION = {
  name: [required()],
  description: [],
  start_time: [required()],
  end_time: [required()],
  location: [required()],
  pic: [required()],
  picPhone: [required()],
  url: [required()],
  visibility: [required()],
  status: [required()],
  detail_locations_array: {
    name: [required()],
  },
  detail_employees_array: {
    employee_id: [required()],
  },
};

const initialValues = {
  type: "public",
  visibility: "all",
  status: "draft",
};

const FormView = (props) => {
  return (
    <SimpleForm {...props} initialValues={initialValues}>
      <React.Fragment>
        <ImageInput source="imageFile" label="Gambar Cover" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <TextInput
          source="name"
          label="Judul"
          validate={VALIDATION.name}
          fullWidth
        />

        <RichTextInput
          source="description"
          label="Keterangan"
          validate={VALIDATION.description}
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={6} lg={3}>
            <TextInput
              source="start_time"
              label="Mulai"
              placeholder="YYYY-MM-DD HH:mm:ss"
              validate={VALIDATION.start_time}
              fullWidth
            />
          </Grid>

          <Grid item xs={6} lg={3}>
            <TextInput
              source="end_time"
              label="Selesai"
              placeholder="YYYY-MM-DD HH:mm:ss"
              validate={VALIDATION.end_time}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextInput
              source="location"
              label="Lokasi"
              validate={VALIDATION.location}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextInput
              source="pic"
              label="Nama PIC"
              validate={VALIDATION.pic}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextInput
              source="picPhone"
              label="Kontak PIC"
              validate={VALIDATION.picPhone}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextInput
              source="url"
              label="Link (Opsional)"
              validate={VALIDATION.url}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <SelectInput
              source="visibility"
              label="Target"
              choices={EVENT_VISIBILITY_ARRAY}
              validate={VALIDATION.visibility}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <SelectInput
              source="status"
              label="Status"
              choices={EVENT_STATUS_ARRAY}
              validate={VALIDATION.status}
              fullWidth
            />
          </Grid>
        </Grid>

        <ConditionalFormDataConsumer
          when={(formData) => formData?.visibility === "in_list_location"}
        >
          {(FormDataProps) => (
            <ArrayInput
              source="detail_locations_array"
              label="Pilih Target Lokasi Kerja"
              {...FormDataProps}
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ getSource }) => (
                    <React.Fragment>
                      <ReferenceInput
                        source={getSource("name")}
                        label="Lokasi Kerja"
                        reference="employees/locations"
                        validate={VALIDATION.detail_locations_array.name}
                        fullWidth
                      >
                        <AutocompleteInput optionText="name" />
                      </ReferenceInput>
                    </React.Fragment>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </ConditionalFormDataConsumer>

        <ConditionalFormDataConsumer
          when={(formData) => formData?.visibility === "in_list"}
        >
          {(FormDataProps) => (
            <ArrayInput
              source="detail_employees_array"
              label="Pilih Target Karyawan"
              {...FormDataProps}
            >
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ getSource }) => (
                    <React.Fragment>
                      <EmployeeReferenceInput
                        source={getSource("employee_id")}
                        label="Employee"
                        validate={VALIDATION.detail_employees_array.employee_id}
                        fullWidth
                      />
                    </React.Fragment>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </ConditionalFormDataConsumer>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
