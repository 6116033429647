import { ACCESS_CODES } from "../../consts";
import attachments from "./attachments";
import categories from "./categories";
import supports from "./supports";

const SupportResources = (permissions) => [
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? supports(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? categories(permissions)
    : null,
  [ACCESS_CODES.ADMIN, ACCESS_CODES.HR].includes(permissions)
    ? attachments(permissions)
    : null,
];

export default SupportResources;
