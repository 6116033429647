import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import lodashFind from "lodash/find";
import { Fragment } from "react";
import { useRecordContext } from "react-admin";
import { HistoryIterator, RequestStatusField } from ".";
import { CustomDateField, StaticText } from "../../../../components";
import EmployeeReferenceField from "../../../employees/employees/EmployeeReferenceField";
import { ApprovalTypeReferenceField } from "../../Type/components";
import { STATUS_ARRAY } from "../index";

const useStyles = makeStyles((theme) => ({
  statusBox: {
    padding: theme.spacing(2),
    color: theme.palette.common.white,
  },
  spacer: {
    height: theme.spacing(2),
  },
}));

const AsideView = (props) => {
  const classes = useStyles(props);
  const record = useRecordContext(props);

  return (
    <Fragment>
      <Card
        style={{
          backgroundColor: lodashFind(STATUS_ARRAY, { id: record?.status })
            ?.color,
        }}
      >
        <div className={classes.statusBox}>
          <RequestStatusField record={record} color="inherit" />
        </div>
      </Card>

      <div className={classes.spacer} />

      <Card>
        <CardContent>
          <StaticText
            title="Pemohon"
            content={
              <EmployeeReferenceField
                record={record}
                source="employeeId"
                link={false}
              />
            }
          />

          <div className={classes.spacer} />

          <StaticText
            title="Jenis Permohonan"
            content={
              <ApprovalTypeReferenceField
                record={record}
                source="typeId"
                link={false}
              />
            }
          />

          <div className={classes.spacer} />

          <StaticText
            title="Waktu Dibuat"
            content={
              <CustomDateField
                record={record}
                source="createdAt.date"
                sourceFormat="YYYY-MM-DD HH:mm:ss"
                destFormat="DD MMMM  YYYY HH:mm:ss"
              />
            }
          />

          <div className={classes.spacer} />

          <StaticText title="Keperluan" content={record?.name || "-"} />

          <div className={classes.spacer} />

          <StaticText title="Keterangan" content={record?.description || "-"} />
        </CardContent>

        {record?.document && (
          <CardActions>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              component="a"
              href={record?.document?.url}
              title={record?.document?.name}
              target="_blank"
              fullWidth
            >
              UNDUH DOKUMEN
            </Button>
          </CardActions>
        )}
      </Card>

      <HistoryIterator data={record?.histories} />
    </Fragment>
  );
};

export default AsideView;
