import * as React from "react";
import { CustomCreate } from "../../../../components";
import FormView from "./FormView";

const CreateView = (props) => (
  <CustomCreate {...props} title="Buat Saldo Cuti">
    <FormView />
  </CustomCreate>
);

export default CreateView;
