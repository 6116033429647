import {
  AutocompleteInput,
  Datagrid,
  DeleteButton,
  EditButton,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { CustomDateField, CustomFilter, CustomList } from "../../../components";

const FilterView = (props) => (
  <CustomFilter {...props}>
    <ReferenceInput
      source="filter_jenis_surat"
      reference="master/jenis-surat"
      label="Jenis Surat"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </CustomFilter>
);

const ListView = (props) => {
  return (
    <CustomList {...props} title="Surat" filters={<FilterView />}>
      <Datagrid>
        jenisSuratName date code description
        <ReferenceField
          source="jenisSuratId"
          reference="master/jenis-surat"
          label="Jenis Surat"
          sortBy="jenisSuratName"
        >
          <TextField source="name" />
        </ReferenceField>
        <CustomDateField source="date" label="Tanggal" />
        <TextField source="code" label="Nomor Surat" />
        <TextField source="description" label="Keterangan" />
        <EditButton />
        <DeleteButton mutationMode="optimistic" undoable={false} />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
