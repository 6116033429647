import { InputAdornment, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React from "react";
import { Filter, SearchInput } from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    searchInput: {
      height: theme.spacing(6),
    },
  }),
  { name: "CustomFilterStyles" }
);

const CustomFilter = ({ query = true, children, ...props }) => {
  const classes = useStyles();

  return (
    <Filter {...props}>
      {query && (
        <SearchInput
          source="q"
          InputProps={{
            className: classes.searchInput,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
          alwaysOn
        />
      )}

      {children}
    </Filter>
  );
};

CustomFilter.propTypes = {
  query: PropTypes.bool,
};

export default CustomFilter;
