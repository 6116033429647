import { ACCESS_CODES } from "../../consts";
import features from "./features";
import users from "./users";
import versions from "./versions";

const ConfigurationResources = (permissions) => [
  [ACCESS_CODES.ADMIN].includes(permissions) ? users(permissions) : null,
  [ACCESS_CODES.ADMIN].includes(permissions) ? features(permissions) : null,
  [ACCESS_CODES.ADMIN].includes(permissions) ? versions(permissions) : null,
];

export default ConfigurationResources;
