import { Grid } from "@material-ui/core";
import { CustomShow } from "../../../components";
import { AsideView, DetailView } from "./components";

const TitleView = ({ record }) => <span>Lihat Permohonan #{record?.id}</span>;

const ShowContainer = ({ className, children }) => {
  return (
    <div className={className}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {children}
        </Grid>

        <Grid item xs={12} md={4}>
          <AsideView />
        </Grid>
      </Grid>
    </div>
  );
};

const ShowView = (props) => {
  return (
    <CustomShow {...props} title={<TitleView />} component={ShowContainer}>
      <DetailView />
    </CustomShow>
  );
};

export default ShowView;
