import PeopleIcon from "@material-ui/icons/People";
import { ACCESS_CODES } from "../../../consts";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

export const EMPLOYEE_JOIN_TYPE_ARRAY = [
  { id: "new", name: "New" },
  { id: "replacement", name: "Replacement" },
];

const EmployeeResource = (permissions) => ({
  name: "employees/employees",
  list: ListView,
  create: ![ACCESS_CODES.PMO_BRO].includes(permissions) ? CreateView : null,
  edit: ![ACCESS_CODES.PMO_BRO].includes(permissions) ? EditView : null,
  icon: PeopleIcon,
  options: {
    label: "Data Pegawai",
  },
});

export default EmployeeResource;
