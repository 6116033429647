import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import React from "react";

const CustomDashboardCard = ({ title, content, actions }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          {title}
        </Typography>

        <Typography variant="h4">{content}</Typography>
      </CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
};

export default CustomDashboardCard;
