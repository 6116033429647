import ListView from "./ListView";

const WeeklyRekapReportResource = (permissions) => ({
  name: "reports/employee/weekly-rekap",
  list: ListView,
  options: {
    label: "Rekap Karyawan (Weekly)",
  },
});

export default WeeklyRekapReportResource;
