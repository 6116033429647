import {
  Datagrid,
  SelectInput,
  TextField,
  NumberField,
  SelectField,
  useNotify,
  useDataProvider,
} from "react-admin";
import React from "react";
import moment from "moment";
import { makeStyles, Button, CircularProgress } from "@material-ui/core";
import { CustomList, CustomFilter, CustomDateField } from "../../components";
import { MONTH_ARRAY, YEAR_ARRAY } from "../../consts";
import { STATUS_ARRAY, TYPE_ARRAY } from ".";
import EmployeeReferenceInput from "../employees/employees/EmployeeReferenceInput";
import ShowView from "./ShowView";

const useStyles = makeStyles({
  icon: {
    fontSize: "20px",
  },
  label: {
    paddingLeft: "0.5em",
  },
});

const FilterView = (props) => (
  <CustomFilter {...props}>
    <SelectInput
      source="filter_type"
      label="Tipe"
      optionText="name"
      choices={TYPE_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_month"
      label="Bulan"
      optionText="name"
      choices={MONTH_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_year"
      label="Tahun"
      optionText="name"
      choices={YEAR_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_status"
      label="Status"
      optionText="name"
      choices={STATUS_ARRAY}
      alwaysOn
    />

    <EmployeeReferenceInput source="filter_employee" />
  </CustomFilter>
);

const PdfButton = ({ record, resource }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [row, setRow] = React.useState(null);

  const [isLoading, setLoading] = React.useState(false);

  const handleClick = () => {
    if (isLoading) return;

    setLoading(true);

    dataProvider
      .getOne(resource, {
        id: record?.id,
        print: 1,
      })
      .then(({ data }) => {
        notify("Exporting to pdf....", "info");
        window.open(data?.url);
      })
      .catch((error) => {
        notify(error?.message, "error");
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (record) {
      setRow(record);
    }
  }, [record, row]);

  return (
    <Button
      size="small"
      color="primary"
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading && <CircularProgress size={20} />}
      <span className={classes.label}>Print PDF</span>
    </Button>
  );
};

const ListView = (props) => {
  return (
    <CustomList
      {...props}
      title="Pembayaran"
      filters={<FilterView />}
      sort={{ field: "date", order: "DESC" }}
      filterDefaultValues={{
        filter_year: moment().year(),
      }}
      useExport
    >
      <Datagrid expand={<ShowView />}>
        <CustomDateField
          source="date"
          sourceFormat="YYYY-MM-DD"
          destFormat="DD/MM/YYYY"
          label="Tanggal Permintaan"
        />
        <TextField source="paymentName" label="Keperluan" />

        <TextField source="applicantName" label="Pemohon" />

        <SelectField source="type" choices={TYPE_ARRAY} label="Tipe" />

        <SelectField source="status" choices={STATUS_ARRAY} label="Status" />

        <NumberField
          source="amount"
          label="Nilai"
          options={{ style: "currency", currency: "IDR" }}
          sortable={false}
        />

        <PdfButton />
      </Datagrid>
    </CustomList>
  );
};

export default ListView;
