import { CustomEdit } from "../../../components";
import FormView from "./FormView";

const TitleView = ({ record }) => (
  <span>Ubah Buku Peraturan Perusahaan #{record?.id}</span>
);

const EditView = (props) => (
  <CustomEdit {...props} title={<TitleView />}>
    <FormView />
  </CustomEdit>
);

export default EditView;
