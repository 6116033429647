import { Typography } from "@material-ui/core";
import * as React from "react";
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  SelectField,
  TextField,
} from "react-admin";
import { CustomList } from "../../../components";
import { ACCESS_CODE_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../../employees/employees/EmployeeReferenceField";

const UsernameField = ({ record = {} }) => (
  <>
    <Typography variant="body1">
      {record.namakaryawan} <small>&lt;{record.emailkaryawan}&gt;</small>
    </Typography>
    <Typography variant="caption" color="textSecondary">
      {record.username}
    </Typography>
  </>
);

const ListView = (props) => (
  <CustomList {...props} title="Users" useImport useExport>
    <Datagrid>
      <UsernameField label="Karyawan" sortBy="namakaryawan" />

      <EmployeeReferenceField
        source="emp_id"
        label="Referenced Employee"
        avatar
      />

      <TextField source="unitkerja" label="Unit Kerja" />

      <SelectField
        source="kodeakses"
        label="Level"
        choices={ACCESS_CODE_ARRAY}
      />
      <BooleanField source="statuskaryawan" label="Status" />

      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
