import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import React from "react";
import {
  FormWithRedirect,
  PasswordInput,
  required,
  SaveButton,
  TextField,
  useDataProvider,
  useNotify,
} from "react-admin";

const ChangePasswordDialog = ({ open, onClose, record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleSubmit = (values) => {
    const { new_password } = values;

    dataProvider
      .update("employees/employees", {
        id: record.id,
        data: {
          new_password,
          _ref: "change-password",
        },
      })
      .then(() => {
        notify("The password was changed successfully", "info");

        onClose();
      })
      .catch((error) => {
        notify(error.message, "error");
      });
  };

  if (!record?.id) {
    return <div></div>;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-label={`Change Password #${record.id}`}
    >
      <DialogTitle>{`Change Password #${record.id}`}</DialogTitle>

      <FormWithRedirect
        resource="employees/employees"
        record={record}
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <>
            <GridShowLayout
              record={record}
              basePath="/employees/employees"
              resource="employees/employees"
            >
              <RaGrid container spacing={2}>
                <RaGrid item xs={4}>
                  <TextField source="nomorkaryawan" label="NIK" />
                </RaGrid>

                <RaGrid item xs={8}>
                  <TextField source="namakaryawan" label="Nama Karyawan" />
                </RaGrid>
              </RaGrid>

              <PasswordInput
                source="new_password"
                label="Password"
                validate={[required()]}
                helperText={`Suggested: ${record?.namakaryawan
                  ?.split(" ")[0]
                  ?.toLowerCase()}@${record?.nomorkaryawan?.split("-")[0]}`}
                fullWidth
              />
            </GridShowLayout>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={onClose} />

              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                label="Update"
                saving={saving}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

ChangePasswordDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default ChangePasswordDialog;
