import { Button } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const CustomDashboardCardButton = (props) => {
  return (
    <Button color="primary" size="small" component={RouterLink} {...props} />
  );
};

export default CustomDashboardCardButton;
