import ListView from "./ListView";

const KeluarReportResource = (permissions) => ({
  name: "reports/employee/keluar",
  list: ListView,
  options: {
    label: "Rekap Karyawan Keluar",
  },
});

export default KeluarReportResource;
