import LocationCity from "@material-ui/icons/LocationCity";
import ListView from "./ListView";
import CreateView from "./CreateView";
import EditView from "./EditView";

const SitesResource = (permissions) => ({
  name: "collections/sites",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: LocationCity,
  options: {
    label: "Sites",
  },
});

export default SitesResource;
