import ListView from "./ListView";

const AktifReportResource = (permissions) => ({
  name: "reports/employee/aktif",
  list: ListView,
  options: {
    label: "Rekap Karyawan Aktif",
  },
});

export default AktifReportResource;
