import DevicesIcon from "@material-ui/icons/Devices";
import { ACCESS_CODES } from "../../../consts";
import ListView from "./ListView";

const DeviceResource = (permissions) => ({
  name: "employees/devices",
  list: [ACCESS_CODES.ADMIN].includes(permissions) ? ListView : null,
  icon: DevicesIcon,
  options: {
    label: "Devices",
  },
});

export default DeviceResource;
