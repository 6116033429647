import { BASE_URL } from "./consts";

const authProvider = {
  login: ({ username, password }) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    const request = new Request(`${BASE_URL}/authentication/create`, {
      method: "POST",
      body: formData,
    });

    return fetch(request)
      .then(async (response) => {
        const json = await response?.json();
        const errorMessage = json?.messages?.error || response.statusText;

        if (response.status < 200 || response.status >= 300) {
          throw new Error(errorMessage);
        }

        return json;
      })
      .then((response) => {
        localStorage.setItem("X-TOKEN", response.token);
      });
  },
  logout: () => {
    const token = localStorage.getItem("X-TOKEN");

    if (!token) {
      return Promise.resolve();
    }

    const request = new Request(`${BASE_URL}/authentication/delete`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    return fetch(request)
      .then(async (response) => {
        const json = await response?.json();
        const errorMessage = json?.messages?.error || response.statusText;

        if (![200, 401].includes(response.status)) {
          throw new Error(errorMessage);
        }

        return json;
      })
      .then((response) => {
        localStorage.removeItem("X-TOKEN", response.token);
      });
  },
  checkError: (error) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem("X-TOKEN");
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem("X-TOKEN");

    const request = new Request(`${BASE_URL}/authentication`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    return fetch(request).then(async (response) => {
      const json = await response?.json();
      const errorMessage = json?.messages?.error || response.statusText;

      if (response.status < 200 || response.status >= 300) {
        throw new Error(errorMessage);
      }

      return json;
    });
  },
  getPermissions: () => {
    const token = localStorage.getItem("X-TOKEN");

    const request = new Request(`${BASE_URL}/authentication`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    return fetch(request)
      .then(async (response) => {
        const json = await response?.json();
        const errorMessage = json?.messages?.error || response.statusText;

        if (response.status < 200 || response.status >= 300) {
          throw new Error(errorMessage);
        }

        return json;
      })
      .then((response) => {
        return response.user.kodeakses;
      });
  },
  getIdentity: () => {
    const token = localStorage.getItem("X-TOKEN");

    const request = new Request(`${BASE_URL}/authentication`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    return fetch(request).then(async (response) => {
      const json = await response?.json();
      const errorMessage = json?.messages?.error || response.statusText;

      if (response.status < 200 || response.status >= 300) {
        throw new Error(errorMessage);
      }

      return json;
    });
  },
};

export default authProvider;
