import DateRangeIcon from "@material-ui/icons/DateRange";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const HistoryResource = (permissions) => ({
  name: "employees/leaves/histories",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: DateRangeIcon,
  options: {
    label: "Riwayat Cuti",
  },
});

export default HistoryResource;
