import { Grid } from "@material-ui/core";
import React from "react";
import {
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from "react-admin";
import { ConditionalFormDataConsumer } from "../../../../components";
import {
  LEAVE_DURATION_ARRAY,
  LEAVE_STATUS_ARRAY,
  LEAVE_TYPE_ARRAY,
  YEAR_ARRAY,
} from "../../../../consts";
import EmployeeReferenceInput from "../../employees/EmployeeReferenceInput";

const VALIDATION = {
  employee_id: [required()],
  plafon_year: [required()],
  leave_type: [required()],
  leave_type_other: [required()],
  leave_duration: [required()],
  start_date: [required()],
  end_date: [required()],
  description: [required()],
  phone: [required()],
  address: [required()],
  employee_backup_code: [],
  employee_backup_name: [],
  employee_backup_job_title: [],
  approved_by: [required()],
  approved_at: [required()],
};

const FormView = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="UMUM">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={8} lg={9} xl={10}>
              <EmployeeReferenceInput
                source="employee_id"
                label="Karyawan"
                validate={VALIDATION.employee_id}
                fullWidth
              />
            </Grid>

            <Grid item xs={4} lg={3} xl={2}>
              <SelectInput
                source="plafon_year"
                label="Tahun"
                choices={YEAR_ARRAY}
                validate={VALIDATION.plafon_year}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4} lg={3} xl={2}>
              <SelectInput
                source="leave_type"
                label="Tipe Cuti"
                choices={LEAVE_TYPE_ARRAY}
                validate={VALIDATION.leave_type}
                fullWidth
              />
            </Grid>

            <FormDataConsumer>
              {({ formData = {}, ...formDataProps }) =>
                formData.leave_type === 0 && (
                  <Grid item xs={8} lg={9} xl={10}>
                    <TextInput
                      source="leave_type_other"
                      label="Tipe Cuti Lainnya"
                      validate={VALIDATION.leave_type_other}
                      {...formDataProps}
                      fullWidth
                    />
                  </Grid>
                )
              }
            </FormDataConsumer>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4} lg={3} xl={2}>
              <SelectInput
                source="leave_duration"
                label="Durasi Cuti"
                choices={LEAVE_DURATION_ARRAY}
                validate={VALIDATION.leave_duration}
                fullWidth
              />
            </Grid>

            <Grid item xs={4} lg={4} xl={2}>
              <DateInput
                source="start_date"
                label="Tanggal Mulai"
                validate={VALIDATION.start_date}
                fullWidth
              />
            </Grid>

            <FormDataConsumer>
              {({ formData = {}, ...formDataProps }) =>
                formData.leave_duration === 2 && (
                  <Grid item xs={4} lg={4} xl={2}>
                    <DateInput
                      source="end_date"
                      label="Tanggal Selesai"
                      validate={VALIDATION.end_date}
                      fullWidth
                    />
                  </Grid>
                )
              }
            </FormDataConsumer>
          </Grid>

          <TextInput
            source="description"
            label="Keterangan"
            validate={VALIDATION.description}
            fullWidth
            multiline
          />
        </React.Fragment>
      </FormTab>

      <FormTab label="KONTAK">
        <React.Fragment>
          <TextInput
            source="phone"
            label="Nomor Telpon"
            validate={VALIDATION.phone}
          />

          <TextInput
            source="address"
            label="Alamat"
            validate={VALIDATION.address}
            fullWidth
            multiline
          />
        </React.Fragment>
      </FormTab>

      <FormTab label="PENGGANTI">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={5} lg={4} xl={3}>
              <TextInput
                source="employee_backup_code"
                label="NIK"
                validate={VALIDATION.employee_backup_code}
                fullWidth
              />
            </Grid>

            <Grid item xs={7} lg={8} xl={9}>
              <TextInput
                source="employee_backup_name"
                label="Nama Lengkap"
                validate={VALIDATION.employee_backup_name}
                fullWidth
              />
            </Grid>
          </Grid>

          <TextInput
            source="employee_backup_job_title"
            label="Jabatan"
            validate={VALIDATION.employee_backup_job_title}
            fullWidth
          />
        </React.Fragment>
      </FormTab>

      <FormTab label="DOKUMEN">
        <React.Fragment>
          <FileInput
            source="documentFile"
            label="Dokumen"
            accept=".pdf,.doc,.docx,.xls,.xlsx"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </React.Fragment>
      </FormTab>

      <FormTab label="PERSETUJUAN">
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <SelectInput
                source="status"
                label="Status"
                choices={LEAVE_STATUS_ARRAY}
                validate={VALIDATION.status}
                fullWidth
              />
            </Grid>

            <ConditionalFormDataConsumer
              when={(formData) => formData?.status !== "draft"}
            >
              {(FormDataProps) => (
                <React.Fragment>
                  <Grid item xs={12} lg={6}>
                    <EmployeeReferenceInput
                      source="approved_by"
                      label="Atasan"
                      validate={VALIDATION.approved_by}
                      {...FormDataProps}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <TextInput
                      source="approved_at"
                      label="Tanggal &amp; Jam"
                      validate={VALIDATION.approved_at}
                      helperText="YYYY-MM-DD HH:mm:ss"
                      {...FormDataProps}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
              )}
            </ConditionalFormDataConsumer>
          </Grid>
        </React.Fragment>
      </FormTab>
    </TabbedForm>
  );
};

export default FormView;
