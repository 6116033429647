import { Grid } from "@material-ui/core";
import React from "react";
import { required, TextInput, SimpleForm } from "react-admin";

const VALIDATION = {
  code: [required()],
  name: [required()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput
              source="code"
              label="Code"
              validate={VALIDATION.code}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <TextInput
              source="name"
              label="Feature"
              validate={VALIDATION.name}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
