import * as React from "react";
import { CustomCreate } from "../../../components";
import FormView from "./FormView";

const CreateView = (props) => (
  <CustomCreate {...props} title="Buat Form Lembur">
    <FormView />
  </CustomCreate>
);

export default CreateView;
