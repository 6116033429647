import ReceiptIcon from "@material-ui/icons/Receipt";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const PayslipResource = (permissions) => ({
  name: "employees/payslips",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: ReceiptIcon,
  options: {
    label: "Slip Gaji",
  },
});

export default PayslipResource;
