import { makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DeleteButton,
  EditButton,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin";
import {
  ConditionalField,
  CustomDateField,
  CustomFilter,
  CustomList,
} from "../../../components";
import { ACCESS_CODES } from "../../../consts";
import AvatarField from "./AvatarField";
import ChangeLeaderDialog from "./ChangeLeaderDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ChangePermissionsDialog from "./ChangePermissionsDialog";
import ExpandPanel from "./ExpandPanel";
import FormPlannedResignDialog from "./FormPlannedResignDialog";
import FormResignDialog from "./FormResignDialog";

const useNameFieldStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    avatar: {
      marginTop: -theme.spacing(0.5),
      marginBottom: -theme.spacing(0.5),
      marginRight: theme.spacing(2),
      fontSize: theme.typography.subtitle1.fontSize,
    },
  }),
  { name: "NameFieldStyles" }
);

const NameField = ({ record }) => {
  const classes = useNameFieldStyles();

  return (
    <div className={classes.root}>
      <AvatarField record={record} size={40} className={classes.avatar} />

      <div>
        <Typography variant="body1">{record.namakaryawan}</Typography>
        <Typography variant="caption" color="textSecondary">
          {record.nomorkaryawan}
        </Typography>
      </div>
    </div>
  );
};

const MoreActionButton = ({ record, onClick = (action, record) => null }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOnOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnMenuClick = (action) => {
    handleOnClose();
    onClick(action, record);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleOnOpen}
        title="Aksi Lainnya"
        aria-controls="actions-menu"
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </Button>

      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
      >
        <MenuItem onClick={() => handleOnMenuClick("change-password")}>
          Ubah Password
        </MenuItem>

        <MenuItem onClick={() => handleOnMenuClick("change-permissions")}>
          Ubah Fitur Aplikasi
        </MenuItem>

        <MenuItem onClick={() => handleOnMenuClick("change-leader")}>
          Ubah Leader
        </MenuItem>

        <ConditionalField
          record={record}
          when={(record) => record?.statuskerja !== "BERHENTI"}
        >
          <MenuItem onClick={() => handleOnMenuClick("form-planned-resign")}>
            Form Tanggal Terakhir Bekerja
          </MenuItem>
        </ConditionalField>

        <ConditionalField
          record={record}
          when={(record) => record?.statuskerja !== "BERHENTI"}
        >
          <MenuItem onClick={() => handleOnMenuClick("form-resign")}>
            Form Efektif Keluar
          </MenuItem>
        </ConditionalField>
      </Menu>
    </div>
  );
};

const FilterView = (props) => (
  <CustomFilter {...props}>
    <ReferenceInput
      source="filter_unit"
      label="Unit Kerja"
      reference="employees/units"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="filter_location"
      label="Lokasi kerja"
      reference="employees/locations"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="filter_status"
      label="Status"
      reference="employees/status"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CustomFilter>
);

const ListView = (props) => {
  const { permissions } = props;
  const [dialogState, changeDialogState] = React.useState({
    action: "",
    record: null,
  });

  const handleOnMenuClick = (action, record) => {
    changeDialogState({
      action,
      record,
    });
  };

  const handleOnDialogClose = () => {
    changeDialogState({
      action: "",
      record: null,
    });
  };

  return (
    <>
      <CustomList
        {...props}
        title="Data Pegawai"
        filters={<FilterView />}
        sort={{ field: "namakaryawan", order: "ASC" }}
        useImport={![ACCESS_CODES.PMO_BRO].includes(permissions)}
        useExport
      >
        <Datagrid expand={<ExpandPanel />}>
          <NameField label="Nama" sortBy="namakaryawan" />

          <TextField source="jabatan" label="Jabatan" />

          <TextField source="lokasikerja" label="Lokasi Kerja" />

          <CustomDateField source="tglawalkontrak" label="Awal Kontrak" />

          <CustomDateField source="tglakhirkontrak" label="Akhir Kontrak" />

          <TextField source="statuskerja" label="Status" />

          {![ACCESS_CODES.PMO_BRO].includes(permissions) && <EditButton />}

          {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
            <DeleteButton mutationMode="optimistic" undoable={false} />
          )}

          {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
            <MoreActionButton onClick={handleOnMenuClick} />
          )}
        </Datagrid>
      </CustomList>

      {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
        <ChangePasswordDialog
          open={dialogState.action === "change-password"}
          onClose={handleOnDialogClose}
          record={dialogState.record}
        />
      )}

      {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
        <ChangePermissionsDialog
          open={dialogState.action === "change-permissions"}
          onClose={handleOnDialogClose}
          record={dialogState.record}
        />
      )}

      {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
        <ChangeLeaderDialog
          open={dialogState.action === "change-leader"}
          onClose={handleOnDialogClose}
          record={dialogState.record}
        />
      )}

      {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
        <FormResignDialog
          open={dialogState.action === "form-resign"}
          onClose={handleOnDialogClose}
          record={dialogState.record}
        />
      )}

      {![ACCESS_CODES.PMO_BRO].includes(permissions) && (
        <FormPlannedResignDialog
          open={dialogState.action === "form-planned-resign"}
          onClose={handleOnDialogClose}
          record={dialogState.record}
        />
      )}
    </>
  );
};

export default ListView;
