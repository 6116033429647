import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {
  CreateButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from "react-admin";
import CustomExportButton from "./CustomExportButton";
import ImportButton from "./ImportButton";

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginLeft: theme.spacing(1),
      "&:first-child": {
        marginLeft: 0,
      },
    },
  }),
  {
    name: "CustomListActionsStyles",
  }
);

const CustomListActions = ({
  useExport = false,
  useImport = false,
  hasCreate = false,
  ...props
}) => {
  const classes = useStyles();
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {hasCreate && (
        <CreateButton className={classes.button} basePath={basePath} />
      )}

      {useImport && (
        <ImportButton className={classes.button} resource={resource} />
      )}

      {useExport && (
        <CustomExportButton
          className={classes.button}
          resource={resource}
          filterValues={filterValues}
        />
      )}
    </TopToolbar>
  );
};

CustomListActions.propTypes = {
  useImport: PropTypes.bool,
  useExport: PropTypes.bool,
  hasCreate: PropTypes.bool,
};

export default CustomListActions;
