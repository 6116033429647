import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { SelectField, NumberField } from "react-admin";
import { METHOD_ARRAY } from ".";
import { StaticText, CustomDateField } from "../../components";

const ExpandView = ({ record = {} }) => {
  return (
    <Container>
      <Card>
        <CardContent>
          <Box mb={2}>
            <Typography variant="subtitle2">Rincian</Typography>
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StaticText title="Nomo Pembayaran" content={record?.code} />
              </Grid>
              <Grid item xs={6}>
                <StaticText title="Keperluan" content={record?.paymentName} />
              </Grid>
              <Grid item xs={4}>
                <StaticText title="Pemohon" content={record?.applicantName} />
              </Grid>
              <Grid item xs={4}>
                <StaticText title="Penerima" content={record?.recipientsName} />
              </Grid>
              <Grid item xs={4}>
                <StaticText
                  title="Tanggal Permintaan"
                  content={
                    <CustomDateField
                      source="date"
                      sourceFormat="YYYY-MM-DD"
                      destFormat="DD/MM/YYYY"
                      record={record}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <StaticText
                  title="Jatuh Tempo"
                  content={
                    <CustomDateField
                      source="dueDate"
                      sourceFormat="YYYY-MM-DD"
                      destFormat="DD/MM/YYYY"
                      record={record}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <StaticText
                  title="Tanggal Pembayaran"
                  content={
                    <CustomDateField
                      source="paymentDate"
                      sourceFormat="YYYY-MM-DD"
                      destFormat="DD/MM/YYYY"
                      record={record}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <StaticText
                  title="Metode Pembayaran"
                  content={
                    <SelectField
                      source="paymentMethod"
                      choices={METHOD_ARRAY}
                    />
                  }
                />
              </Grid>
              {record?.paymentMethod === "bank" && (
                <Grid item xs={12}>
                  <StaticText
                    title="Bank/No. Rekening"
                    content={`${record?.bankName}/${record?.accountNumber}`}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <Box mb={2}>
            <StaticText title="Keterangan" content={record?.description} />
          </Box>

          <Box mb={2}>
            <StaticText
              title="Nominal"
              content={
                <NumberField
                  source="amount"
                  options={{ style: "currency", currency: "IDR" }}
                />
              }
            />
          </Box>

          <Box mt={2}></Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ExpandView;
