import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { ListButton, TopToolbar } from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginLeft: theme.spacing(1),
      "&:first-child": {
        marginLeft: 0,
      },
    },
  }),
  {
    name: "CustomActionsViewStyles",
  }
);

const CustomActionsView = ({ basePath, children, ...props }) => {
  const classes = useStyles();
  const listButton = (
    <ListButton className={classes.button} basePath={basePath} />
  );

  return (
    <TopToolbar>
      {typeof children === "function"
        ? children({
            basePath,
            listButton,
            buttonClass: classes.button,
            ...props,
          })
        : listButton}
    </TopToolbar>
  );
};

CustomActionsView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default CustomActionsView;
