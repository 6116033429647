import ReceiptIcon from "@material-ui/icons/Receipt";
import ListView from "./ListView";

export const TYPE_ARRAY = [
  { id: "telexindo", name: "Telexindo" },
  { id: "icca", name: "ICCA" },
  { id: "aruwa", name: "Aruwa" },
];

export const STATUS_ARRAY = [
  { id: "proses", name: "Proses" },
  { id: "selesai", name: "Selesai" },
  { id: "batal", name: "Batal" },
];

const InvoiceResource = (permissions) => ({
  name: "invoice/invoices",
  list: ListView,
  icon: ReceiptIcon,
  options: {
    label: "Invoice",
  },
});

export default InvoiceResource;
