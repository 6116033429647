import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { EditButton, Tab, TabbedShowLayout, useRedirect } from "react-admin";
import NumberFormat from "react-number-format";
import {
  ConditionalField,
  CustomActionsView,
  CustomExportButton,
  CustomShow,
  StaticText,
  WithPermission,
} from "../../../components";
import { ACCESS_CODES } from "../../../consts";
import { DateHelper } from "../../../helpers";
import AsideView from "./AsideView";
import DownloadButton from "./DownloadButton";

const TitleView = ({ record }) => <span>Request Kontrak #{record?.id}</span>;

const ActionsView = (props) => {
  const { hasEdit } = props;

  return (
    <CustomActionsView {...props}>
      {({ listButton, buttonClass }) => {
        return (
          <React.Fragment>
            {hasEdit && (
              <ConditionalField
                record={props.data}
                when={(rec) => ["approval1", "approval2"].includes(rec?.status)}
              >
                <EditButton className={buttonClass} />
              </ConditionalField>
            )}

            <WithPermission codes={[ACCESS_CODES.ADMIN, ACCESS_CODES.HR]}>
              <ConditionalField
                record={props.data}
                when={(rec) => Boolean(rec?.file?.url)}
              >
                <DownloadButton className={buttonClass} />
              </ConditionalField>

              <ConditionalField
                record={props.data}
                when={(rec) => Boolean(rec?.file?.url)}
              >
                <CustomExportButton
                  resource="contract/requests"
                  filterValues={{
                    _ref: "merge-with-background",
                    id: props?.data?.id,
                  }}
                  label="Unduh PDF + Background"
                  className={buttonClass}
                />
              </ConditionalField>
            </WithPermission>

            {listButton}
          </React.Fragment>
        );
      }}
    </CustomActionsView>
  );
};

const TabPanelInformasiKontrak = ({ record }) => {
  return (
    <React.Fragment>
      <Box mb="1em" mt="0.5em">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StaticText
              title="Jenis Kontrak"
              content={record?.category?.name}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <StaticText title="Status Kontrak" content={record?.type?.name} />
          </Grid>

          <Grid item xs={12} md={4}>
            <StaticText
              title="Template Kontrak"
              content={record?.template?.name}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb="1em">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StaticText title="Nomor Kontrak" content={record?.code} />
          </Grid>

          <Grid item xs={6} md={3}>
            <StaticText
              title="Awal Kontrak"
              content={DateHelper.format(
                record?.startDate,
                "YYYY-MM-DD",
                "DD/MM/YYYY"
              )}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <StaticText
              title="Akhir Kontrak"
              content={DateHelper.format(
                record?.endDate,
                "YYYY-MM-DD",
                "DD/MM/YYYY"
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb="1em">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StaticText title="Posisi Pekerjaan" content={record?.position} />
          </Grid>

          <Grid item xs={12} md={6}>
            <StaticText title="Lokasi Kerja" content={record?.companyName} />
          </Grid>
        </Grid>
      </Box>

      <Box mb="1em">
        <StaticText
          title="Alamat Lokasi Kerja"
          content={record?.companyAddress}
        />
      </Box>

      <Box mb="1em">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StaticText
              title="Petugas PMO"
              content={
                record?.pmo
                  ? `${record?.pmo?.nomorkaryawan} - ${record?.pmo?.namakaryawan}`
                  : "-"
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <StaticText
              title="Buku Peraturan Perusahaan"
              content={
                record?.companyRule?.name ? `${record?.companyRule?.name}` : "-"
              }
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

const TabPanelPihakPertama = ({ record }) => {
  return (
    <React.Fragment>
      <Box mb="1em" mt="0.5em">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <StaticText title="Nama Karyawan" content={record?.approverName} />
          </Grid>

          <Grid item xs={12} md={4}>
            <StaticText
              title="Jabatan Karyawan"
              content={record?.approverPosition}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

const TabPanelPihakKedua = ({ record }) => {
  return (
    <React.Fragment>
      <Box mb="1em" mt="0.5em">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StaticText title="NIK" content={record?.empCode} />
          </Grid>

          <Grid item xs={12} md={8}>
            <StaticText title="Nama Karyawan" content={record?.empName} />
          </Grid>
        </Grid>
      </Box>

      <Box mb="1em">
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <StaticText
              title="Tanggal Lahir Karyawan"
              content={DateHelper.format(
                record?.empBirthDate,
                "YYYY-MM-DD",
                "DD/MM/YYYY"
              )}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <StaticText
              title="Tempat Lahir Karyawan"
              content={record?.empBirthPlace}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <StaticText
              title="Nomor Telpon Karyawan"
              content={record?.empPhone}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <StaticText
              title="Nomor KTP Karyawan"
              content={record?.empKtpCode}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb="1em">
        <StaticText title="Alamat Karyawan" content={record?.empAddress} />
      </Box>
    </React.Fragment>
  );
};

const TabPanelKompensasi = ({ record }) => {
  return (
    <React.Fragment>
      <TableContainer component="div">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Komponent</TableCell>
              <TableCell align="right">Jumlah</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(record.components_array || []).map((component) => (
              <TableRow key={component.id}>
                <TableCell
                  component="td"
                  scope="row"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {component.name}
                </TableCell>

                <TableCell align="right">
                  <NumberFormat
                    value={component.amount}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp. "
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

const TabContainer = (props) => {
  const redirect = useRedirect();

  React.useEffect(() => {
    if ((props?.record?.status || "request") === "request") {
      redirect("edit", "/contract/requests", props?.record?.id);
    }
  }, [props.record, redirect]);

  return (
    <TabbedShowLayout {...props}>
      <Tab label="Informasi Kontrak">
        <TabPanelInformasiKontrak />
      </Tab>

      <Tab label="Pihak Pertama">
        <TabPanelPihakPertama />
      </Tab>

      <Tab label="Pihak Kedua">
        <TabPanelPihakKedua />
      </Tab>

      <Tab label="Kompensasi">
        <TabPanelKompensasi />
      </Tab>
    </TabbedShowLayout>
  );
};

const ShowContainer = ({ className, children }) => {
  return (
    <div className={className}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>{children}</Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <AsideView />
        </Grid>
      </Grid>
    </div>
  );
};

const ShowView = (props) => {
  return (
    <CustomShow
      {...props}
      title={<TitleView />}
      actions={<ActionsView />}
      component={ShowContainer}
    >
      <TabContainer />
    </CustomShow>
  );
};

export default ShowView;
