import CssBaseline from "@material-ui/core/CssBaseline";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import * as React from "react";
import { Admin, fetchUtils, Resource } from "react-admin";
import authProvider from "./authProvider";
import { CustomLayout } from "./components";
import { BASE_URL } from "./consts";
import { Dashboard } from "./dashboard";
import jsonServerProvider from "./provider";
import resources from "./resources";

const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
  allowMissing: true,
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("X-TOKEN");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(BASE_URL, httpClient);

const App = () => (
  <>
    <CssBaseline />
    <Admin
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={CustomLayout}
    >
      {(permissions) =>
        resources(permissions).map((resource, index) => (
          <Resource key={index} {...resource} />
        ))
      }
    </Admin>
  </>
);

export default App;
