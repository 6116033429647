import moment from "moment";
import * as React from "react";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  SelectInput,
} from "react-admin";
import { CustomDateField, CustomFilter, CustomList } from "../../../components";
import { MONTH_ARRAY, YEAR_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const FilterView = (props) => (
  <CustomFilter {...props}>
    <EmployeeReferenceInput source="filter_employee" alwaysOn />

    <SelectInput
      source="filter_month"
      label="Bulan"
      optionText="name"
      choices={MONTH_ARRAY}
      alwaysOn
    />

    <SelectInput
      source="filter_year"
      label="Tahun"
      optionText="name"
      choices={YEAR_ARRAY}
      alwaysOn
    />
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="KPI"
    filters={<FilterView />}
    sort={{ field: "namakaryawan", order: "DESC" }}
    filterDefaultValues={{
      filter_month: moment().month() + 1,
      filter_year: moment().year(),
    }}
    useImport
    useExport
  >
    <Datagrid>
      <EmployeeReferenceField source="employee_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="employee_id"
        label="Unit Kerja"
        link={false}
        sortable={false}
        optionText={(record) => record?.unitkerja}
      />

      <CustomDateField source="period" destFormat="MMMM YYYY" label="Periode" />

      <FunctionField
        render={(record) => `${record.value}%`}
        label="%"
        sortable={false}
      />

      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
