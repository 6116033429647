import { Fragment } from "react";
import {
  ArrayInput,
  FormDataConsumer,
  required,
  SimpleFormIterator,
} from "react-admin";
import EmployeeReferenceInput from "../../../employees/employees/EmployeeReferenceInput";

const VALIDATION = {
  employeeId: [required()],
};

const ApproverArrayInput = (props) => (
  <ArrayInput {...props}>
    <SimpleFormIterator>
      <FormDataConsumer>
        {({ getSource }) => (
          <Fragment>
            <EmployeeReferenceInput
              source={getSource("employeeId")}
              validate={VALIDATION.employeeId}
              fullWidth
            />
          </Fragment>
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

export default ApproverArrayInput;
