import lodashGet from "lodash/get";
import React from "react";
import { FunctionField } from "react-admin";
import { DateHelper } from "../helpers";

export const CustomDateField = ({
  source,
  sourceFormat = "YYYY-MM-DD",
  destFormat = "DD/MM/YYYY",
  ...props
}) => {
  return (
    <FunctionField
      render={(record) =>
        DateHelper.format(lodashGet(record, source), sourceFormat, destFormat)
      }
      {...props}
    />
  );
};
