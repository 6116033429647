import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";

const VALIDATION = {
  name: [required()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <TextInput
          source="name"
          label="Nama"
          validate={VALIDATION.name}
          fullWidth
        />
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
