import moment from "moment";
import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  FunctionField,
  ReferenceInput,
  TextField,
} from "react-admin";
import { CustomDateField, CustomFilter, CustomList } from "../../../components";
import ShowView from "./ShowView";

const FilterView = (props) => (
  <CustomFilter {...props} query={false}>
    <DateInput source="filter_start_date" label="Tanggal Awal" alwaysOn />

    <DateInput source="filter_end_date" label="Tanggal Akhir" alwaysOn />

    <ReferenceInput
      source="filter_location"
      label="Lokasi kerja"
      reference="employees/locations"
      filter={{ is_partnership_only: 1 }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </CustomFilter>
);

const ListView = (props) => (
  <CustomList
    {...props}
    title="Absensi Karyawan Partnership"
    filters={<FilterView />}
    sort={{ field: "datetime_in", order: "DESC" }}
    filterDefaultValues={{
      filter_start_date: moment().startOf("month").format("YYYY-MM-DD"),
      filter_end_date: moment().endOf("month").format("YYYY-MM-DD"),
    }}
    useExport
  >
    <Datagrid expand={<ShowView />}>
      <FunctionField
        sortBy="employee_id"
        label="Karyawan"
        render={(record) =>
          `${record?.employee?.nomorkaryawan} - ${record?.employee?.namakaryawan}`
        }
      />

      <TextField
        source="employee.unitkerja"
        sortBy="employee_unit"
        label="Unit Kerja"
      />

      <TextField
        source="employee.lokasikerja"
        sortBy="employee_location"
        label="Lokasi Kerja"
      />

      <CustomDateField
        source="datetime_in"
        sourceFormat="YYYY-MM-DD HH:mm:ss"
        destFormat="DD/MM/YYYY HH:mm:ss"
        label="Masuk"
      />

      <CustomDateField
        source="datetime_out"
        sourceFormat="YYYY-MM-DD HH:mm:ss"
        destFormat="DD/MM/YYYY HH:mm:ss"
        label="Keluar"
      />
    </Datagrid>
  </CustomList>
);

export default ListView;
