import SpeedIcon from "@material-ui/icons/Speed";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const OvertimeResource = (permissions) => ({
  name: "employees/overtimes",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: SpeedIcon,
  options: {
    label: "Lembur",
  },
});

export default OvertimeResource;
