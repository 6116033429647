import { Fragment } from "react";
import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  required,
} from "react-admin";
import EmployeeReferenceInput from "../../employees/employees/EmployeeReferenceInput";

const VALIDATION = {
  employee_id: [required()],
};
const EmployeeArrayInput = (props) => (
	<FormDataConsumer>
		{({formData, ...rest}) => (
			<ArrayInput {...props}>
			<SimpleFormIterator>
				<FormDataConsumer>
					{({ getSource }) => (
						<Fragment>
							<EmployeeReferenceInput
								source={getSource("employee_id")}
								validate={VALIDATION.employee_id}
								fullWidth
								filter={{ filter_location: formData.location }}
								{...rest}
							/>
						</Fragment>
					)}
				</FormDataConsumer>
			</SimpleFormIterator>
		</ArrayInput>
		)}
	</FormDataConsumer>
);

export default EmployeeArrayInput;
