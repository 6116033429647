import ListView from "./ListView";
import ShowView from "./ShowView";
import DraftsIcon from "@material-ui/icons/Drafts";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

export const STATUS_ARRAY = [
  { id: "draft", name: "Permintaan", color: "#FF9800", icon: DraftsIcon },
  {
    id: "submit",
    name: "Menunggu Persetujuan",
    color: "#2196F3",
    icon: RadioButtonUncheckedIcon,
  },
  { id: "approve", name: "Disetujui", color: "#4CAF50", icon: CheckIcon },
  { id: "reject", name: "Ditolak", color: "#F44336", icon: CloseIcon },
];

export const HISTORY_STATUS_ARRAY = [
  {
    id: "draft",
    name: "Menunggu Persetujuan",
    color: "#2196F3",
    icon: RadioButtonUncheckedIcon,
  },
  { id: "approve", name: "Disetujui", color: "#4CAF50", icon: CheckIcon },
  { id: "reject", name: "Ditolak", color: "#F44336", icon: CloseIcon },
];

const RequestResource = (permissions) => ({
  name: "approval/requests",
  list: ListView,
  show: ShowView,
  options: {
    label: "Permohonan",
  },
});

export default RequestResource;
