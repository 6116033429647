import { Box, Button, Container } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  DeleteButton,
  EditButton,
  FunctionField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  useRecordContext,
} from "react-admin";
import {
  ConditionalField,
  CustomFilter,
  CustomList,
  StaticText,
} from "../../../components";
import { OVERTIME_STATUS_ARRAY } from "../../../consts";
import EmployeeReferenceField from "../employees/EmployeeReferenceField";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const FilterView = (props) => (
  <CustomFilter {...props}>
    <EmployeeReferenceInput source="filter_employee" alwaysOn />

    <DateInput source="filter_start_date" label="Tanggal Awal" alwaysOn />

    <DateInput source="filter_end_date" label="Tanggal Akhir" alwaysOn />

    <SelectInput
      source="filter_status"
      label="Status"
      choices={OVERTIME_STATUS_ARRAY}
      alwaysOn
    />

    <ReferenceInput
      source="filter_location"
      label="Lokasi kerja"
      reference="employees/locations"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </CustomFilter>
);

const renderDateTime = (record = {}) => {
  const startedAt = moment(record.started_at);
  const endedAt = moment(record.ended_at);
  const format = "DD/MM/YYYY HH:mm:ss";

  if (startedAt.format("YYYY-MM-DD") === endedAt.format("YYYY-MM-DD")) {
    return `${startedAt.format("DD/MM/YYYY")} ${startedAt.format(
      "HH:mm:ss"
    )} - ${endedAt.format("HH:mm:ss")} (${record.duration_in_hour} jam)`;
  }

  return `${startedAt.format(format)} - ${endedAt.format(format)} (${
    record.duration_in_hour
  } jam)`;
};

const DocumentDownloadButton = (props) => {
  const record = useRecordContext(props);

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<GetAppIcon />}
      component="a"
      href={record?.document?.url}
      title={record?.document?.name}
      target="_blank"
      fullWidth
    >
      DOCUMENT
    </Button>
  );
};

const ExpandView = ({ record = {} }) => {
  return (
    <Container>
      <StaticText title="Keterangan" content={record.description} />

      {record.approver && (
        <>
          <Box mt={1}>
            <StaticText
              title="Disetujui Oleh"
              content={`${record.approver.nomorkaryawan} - ${record.approver.namakaryawan}`}
            />
          </Box>

          <Box mt={1}>
            <StaticText
              title="Waktu Disetujui"
              content={moment(record.approved_leader_at).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

const ListView = (props) => (
  <CustomList
    {...props}
    title="Lembur"
    filters={<FilterView />}
    sort={{ field: "id", order: "DESC" }}
    filterDefaultValues={{
      filter_start_date: moment().startOf("month").format("YYYY-MM-DD"),
      filter_end_date: moment().endOf("month").format("YYYY-MM-DD"),
    }}
    useExport
  >
    <Datagrid expand={<ExpandView />}>
      <TextField source="code" label="No.Urut" />

      <EmployeeReferenceField source="emp_id" label="Karyawan" avatar />

      <EmployeeReferenceField
        source="emp_id"
        label="Unit Kerja"
        link={false}
        sortBy="emp_unit"
        optionText={(record) => record?.unitkerja}
      />

      <EmployeeReferenceField
        source="emp_id"
        label="Lokasi Kerja"
        link={false}
        sortBy="emp_location"
        optionText={(record) => record?.lokasikerja}
      />

      <FunctionField
        render={renderDateTime}
        label="Waktu"
        sortBy="started_at"
      />

      <SelectField
        source="status"
        label="Status"
        choices={OVERTIME_STATUS_ARRAY}
      />

      <ConditionalField when={(record) => record?.document?.url}>
        <DocumentDownloadButton />
      </ConditionalField>

      <EditButton />
      <DeleteButton mutationMode="optimistic" undoable={false} />
    </Datagrid>
  </CustomList>
);

export default ListView;
