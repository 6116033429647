import { Grid } from "@material-ui/core";
import React from "react";
import { required, SimpleForm, TextInput } from "react-admin";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const VALIDATION = {
  employee_id: [required()],
  datetime_in: [required()],
  coordinate_in: [required()],
  location_in: [required()],
  description_in: [],
  datetime_out: [],
  coordinate_out: [],
  location_out: [],
  description_out: [],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <EmployeeReferenceInput
          source="employee_id"
          validate={VALIDATION.employee_id}
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextInput
                  source="datetime_in"
                  label="Waktu Masuk"
                  validate={VALIDATION.datetime_in}
                  fullWidth
                />
              </Grid>

              <Grid item xs={7}>
                <TextInput
                  source="coordinate_in"
                  label="Kordinat Masuk"
                  validate={VALIDATION.coordinate_in}
                  fullWidth
                />
              </Grid>
            </Grid>

            <TextInput
              source="location_in"
              label="Lokasi Masuk"
              validate={VALIDATION.location_in}
              fullWidth
            />

            <TextInput
              source="description_in"
              label="Keterangan Masuk"
              validate={VALIDATION.description_in}
              multiline
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextInput
                  source="datetime_out"
                  label="Waktu Keluar"
                  validate={VALIDATION.datetime_out}
                  fullWidth
                />
              </Grid>

              <Grid item xs={7}>
                <TextInput
                  source="coordinate_out"
                  label="Kordinat Keluar"
                  validate={VALIDATION.coordinate_out}
                  fullWidth
                />
              </Grid>
            </Grid>

            <TextInput
              source="location_out"
              label="Lokasi Keluar"
              validate={VALIDATION.location_out}
              fullWidth
            />

            <TextInput
              source="description_out"
              label="Keterangan Keluar"
              validate={VALIDATION.description_out}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
