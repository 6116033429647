import React from "react";
import { CustomEdit } from "../../../components";
import FormView from "./FormView";

const TitleView = ({ record }) => (
  <span>Ubah Status Kontrak #{record?.id}</span>
);

const EditView = (props) => {
  return (
    <CustomEdit {...props} title={<TitleView />}>
      <FormView />
    </CustomEdit>
  );
};

export default EditView;
