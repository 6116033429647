import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CommentIcon from "@material-ui/icons/Comment";
import RoomIcon from "@material-ui/icons/Room";
import PropTypes from "prop-types";
import { useGetList } from "ra-core";
import { FunctionField, SelectField, TextField } from "ra-ui-materialui";
import React from "react";
import { useRecordContext } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import { CustomDateField, StaticText } from "../../../components";
import { CONTRACT_REQUEST_STATUS_ARRAY } from "../../../consts";
import { DateHelper } from "../../../helpers";

const useStyles = makeStyles(
  {
    stepper: {
      background: "none",
      border: "none",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  { name: "AsideViewStyles" }
);

const AsideView = (props) => {
  const classes = useStyles();
  const record = useRecordContext(props);

  const { data: approvals, ids: approvalIds } = useGetList(
    "contract/approvals",
    { page: 1, perPage: 100 },
    { field: "createdAt", order: "DESC" },
    { filter_request: record && record.id }
  );

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Informasi Lainnya
          </Typography>

          <Box mt="1em">
            <StaticText
              title="Status Request"
              content={
                <SelectField
                  record={record}
                  source="status"
                  choices={CONTRACT_REQUEST_STATUS_ARRAY}
                />
              }
            />
          </Box>

          <Box mt="1em">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StaticText
                  title="Waktu Dibuat"
                  content={
                    <CustomDateField
                      record={record?.createdAt}
                      source="date"
                      sourceFormat="YYYY-MM-DD HH:mm:ss"
                      destFormat="DD/MM/YYYY HH:mm:ss"
                    />
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <StaticText
                  title="Waktu Diubah"
                  content={
                    <CustomDateField
                      record={record?.updatedAt}
                      source="date"
                      sourceFormat="YYYY-MM-DD HH:mm:ss"
                      destFormat="DD/MM/YYYY HH:mm:ss"
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>

      {approvalIds && approvalIds.length > 0 && (
        <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
          {approvalIds.map((approvalId) => {
            const approval = approvals[approvalId];

            return (
              <Step key={`approval-${approval.id}`} expanded active completed>
                <StepLabel
                  StepIconComponent={() => {
                    const Component =
                      approval.status === "approve" ? CheckIcon : CloseIcon;
                    const color =
                      approval.status === "approve" ? "primary" : "error";
                    const title =
                      approval.status === "approve" ? "Disetujui" : "Ditolak";

                    return (
                      <Tooltip title={title}>
                        <Component
                          fontSize="small"
                          color={color}
                          style={{ paddingLeft: 3 }}
                        />
                      </Tooltip>
                    );
                  }}
                >
                  {DateHelper.format(
                    approval.createdAt.date,
                    "YYYY-MM-DD HH:mm:ss",
                    "LLLL"
                  )}
                </StepLabel>
                <StepContent>
                  <Typography variant="body2">
                    <Link
                      to={`/employees/employees/${approval.createdBy}`}
                      component={RouterLink}
                    >
                      {approval?.employee?.nomorkaryawan} -{" "}
                      {approval?.employee?.namakaryawan}
                    </Link>
                  </Typography>

                  {approval?.message && (
                    <Box display="flex" mt="1em">
                      <Box mr="1em">
                        <CommentIcon fontSize="small" color="disabled" />
                      </Box>
                      <Box flexGrow={1}>
                        <TextField
                          record={approval}
                          source="message"
                          color="textSecondary"
                        />
                      </Box>
                    </Box>
                  )}

                  {approval?.latitude && approval?.longitude && (
                    <Box display="flex" mt="1em">
                      <Box mr="1em">
                        <RoomIcon fontSize="small" color="disabled" />
                      </Box>
                      <Box flexGrow={1}>
                        <FunctionField
                          record={approval}
                          render={(rec) => `${rec.latitude}, ${rec.longitude}`}
                          color="textSecondary"
                        />
                      </Box>
                    </Box>
                  )}

                  {approval?.address && (
                    <Box display="flex" mt="1em">
                      <Box mr="1em">
                        <BusinessIcon fontSize="small" color="disabled" />
                      </Box>
                      <Box flexGrow={1}>
                        <TextField
                          record={approval}
                          source="address"
                          color="textSecondary"
                        />
                      </Box>
                    </Box>
                  )}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      )}
    </div>
  );
};

AsideView.propTypes = {
  record: PropTypes.any,
  basePath: PropTypes.string,
};

export default AsideView;
