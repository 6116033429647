import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const CategoryResource = (permissions) => ({
  name: "contract/categories",
  list: ListView,
  create: CreateView,
  edit: EditView,
  options: {
    label: "Jenis Kontrak",
  },
});

export default CategoryResource;
