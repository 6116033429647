import { CustomCreate } from "../../../components";
import FormView from "./FormView";

const initialValues = {
  fieldDocument: "optional",
  fieldDate: "none",
  fieldPeriod: "none",
  fieldProduct: "none",
  fieldQuantity: "none",
  fieldAmount: "none",
  fieldCoordinate: "none",
  fieldAddress: "none",
  approverMin: 1,
  approverLeader: false,
};

const CreateView = (props) => (
  <CustomCreate {...props} title="Buat Jenis Permohonan">
    <FormView initialValues={initialValues} />
  </CustomCreate>
);

export default CreateView;
