import {
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  SelectField,
  SelectInput,
  TextField,
  useRecordContext,
} from "react-admin";
import { STATUS_ARRAY, WORK_LOCATION_ARRAY } from ".";
import {
  ConditionalField,
  CustomDateField,
  CustomFilter,
  CustomList,
  CustomPrintButton,
} from "../../../components";
import CancelButton from "./components/CancelButton";
import CreateEmployeeButton from "./components/CreateEmployeeButton";
import ExpandPanel from "./components/ExpandPanel";
import ProcessButton from "./components/ProcessButton";
import { EMPLOYEE_JOIN_TYPE_ARRAY } from "../employees";

const FilterView = (props) => (
  <CustomFilter {...props}>
    <SelectInput
      source="filter_status"
      label="Status"
      choices={STATUS_ARRAY}
      alwaysOn
    />
  </CustomFilter>
);

const ActionsColumn = (props) => {
  const record = useRecordContext(props);

  return (
    <>
      <ConditionalField
        record={record}
        when={(record) => !!record?.code && record?.status !== "cancel"}
      >
        <CustomPrintButton />
      </ConditionalField>

      <ConditionalField
        record={record}
        when={(record) => record?.status === "open"}
      >
        <ProcessButton />
      </ConditionalField>

      <ConditionalField
        record={record}
        when={(record) =>
          record?.status === "process" && !Boolean(record?.empId)
        }
      >
        <CreateEmployeeButton />
      </ConditionalField>

      <ConditionalField
        record={record}
        when={(record) => record?.status === "process"}
      >
        <CancelButton />
      </ConditionalField>

      <ConditionalField
        record={record}
        when={(record) =>
          record?.status === "open" || record?.status === "process"
        }
      >
        <EditButton />
      </ConditionalField>

      <ConditionalField
        record={record}
        when={(record) => record?.status !== "close"}
      >
        <DeleteButton mutationMode="optimistic" undoable={false} />
      </ConditionalField>
    </>
  );
};

const ListView = (props) => (
  <CustomList
    {...props}
    filters={<FilterView />}
    title="Kandidat"
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid expand={<ExpandPanel />}>
      <TextField source="fptkCode" label="Nomor FPTK" emptyText="-" />

      <TextField
        source="employee.nomorkaryawan"
        label="NIK"
        sortBy="empId"
        emptyText="-"
      />

      <TextField source="name" label="Nama Lengkap" />

      <FunctionField
        render={(record) => (
          <>
            <SelectField
              record={record}
              source="join_type"
              choices={EMPLOYEE_JOIN_TYPE_ARRAY}
              emptyText="-"
            />
          </>
        )}
        sortBy="join_type"
        label="Status Join"
      />

      <FunctionField
        render={(record) => (
          <>
            <SelectField
              record={record}
              source="workLocation"
              choices={WORK_LOCATION_ARRAY}
              emptyText="-"
            />

            <ConditionalField
              record={record}
              when={(record) => record.workLocation === "client"}
            >
              <FunctionField
                record={record}
                render={(record) => ` (${record.workLocationOther})`}
              />
            </ConditionalField>
          </>
        )}
        sortBy="workLocation"
        label="Penempatan"
      />

      <TextField source="workPosition" label="Posisi" emptyText="-" />

      <TextField source="createdBy" label="Pemohon" />

      <CustomDateField
        source="createdAt.date"
        label="Waktu Permintaan"
        sourceFormat={false}
        destFormat="DD/MM/YYYY HH:mm:ss"
      />

      <SelectField source="status" label="Status" choices={STATUS_ARRAY} />

      <ActionsColumn label="Actions" />
    </Datagrid>
  </CustomList>
);

export default ListView;
