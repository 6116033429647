import { Fragment } from "react";
import { SimpleForm, TextInput, FileInput, FileField } from "react-admin";
import { VALIDATION } from ".";

const FormView = (props) => (
  <SimpleForm {...props}>
    <Fragment>
      <TextInput
        source="name"
        label="Nama"
        validate={VALIDATION.name}
        fullWidth
      />

      <FileInput
        source="fileInput"
        label="PDF"
        accept=".pdf"
        validate={VALIDATION.fileInput}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </Fragment>
  </SimpleForm>
);

export default FormView;
