import { Grid } from "@material-ui/core";
import React from "react";
import {
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { MONTH_ARRAY, YEAR_ARRAY } from "../../../consts";
import EmployeeReferenceInput from "../employees/EmployeeReferenceInput";

const VALIDATION = {
  employee_id: [required()],
  period_month: [required()],
  period_year: [required()],
  value: [required(), number()],
};

const FormView = (props) => {
  return (
    <SimpleForm {...props}>
      <React.Fragment>
        <EmployeeReferenceInput
          source="employee_id"
          validate={VALIDATION.employee_id}
          fullWidth
        />

        <Grid container spacing={2}>
          <Grid item xs={5} lg={2}>
            <SelectInput
              source="period_month"
              label="Bulan"
              optionText="name"
              choices={MONTH_ARRAY}
              validate={VALIDATION.period_month}
              fullWidth
            />
          </Grid>

          <Grid item xs={5} lg={2}>
            <SelectInput
              source="period_year"
              label="Tahun"
              optionText="name"
              choices={YEAR_ARRAY}
              validate={VALIDATION.period_year}
              fullWidth
            />
          </Grid>

          <Grid item xs={2} lg={2}>
            <NumberInput
              source="value"
              label="%"
              validate={VALIDATION.value}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    </SimpleForm>
  );
};

export default FormView;
