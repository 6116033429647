import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const PerformanceResource = (permissions) => ({
  name: "employees/performances",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: TrendingUpIcon,
  options: {
    label: "KPI",
  },
});

export default PerformanceResource;
