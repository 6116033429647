import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

const ActivityResource = (permissions) => ({
  name: "employees/activities",
  list: ListView,
  create: CreateView,
  edit: EditView,
  icon: FormatListNumberedIcon,
  options: {
    label: "Activity Report",
  },
});

export default ActivityResource;
